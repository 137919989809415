import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { Pie, Line } from '@ant-design/plots';
import { Tag } from "../../components/Tag";
import { Square } from "../../components/Square";
import { Select } from "antd";

export const Dashboard = () => {

  const { Option } = Select;

  function handleChange(value:any) {
    return;
  }

  const data = [
    {
      type: 'presencial',
      value: 25,
    },
    {
      type: 'email',
      value: 25,
    },
    {
      type: 'ligacoes',
      value: 25,
    },
    {
      type: 'whatsapp',
      value: 25,
    },
  ];

  const config = {
    width: 200,
    heigth: 200,
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },

    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const datos =[
    {leads:0, vendas:0},
    {leads:1, vendas:7},
    {leads:2, vendas:6},
    {leads:3, vendas:2},
    {leads:4, vendas:8},
    {leads:5, vendas:9},
    {leads:6, vendas:4},
    {leads:7, vendas:6},

  ];

  const configuracao = {
    data:datos,
    title:{
      visible:true,
      text:"hello"
    },
    autoFit:true,
    xField:"leads",
    yField:"vendas",
    color:"#4CAF50",
    point:{
      visible:true,
      size:5,
      shape:'diamond',
      style:{
        fill:'#4CAF50',
        stroke:'#4CAF50',
        lineWidth: 2
      }
    }
  }

  return (
    <>
      <div className="container-happy">
        <Sidebar dashboard={true} />
        <div className="dashboard">
          <Header />
          <div className="area">
            <div className="header-area">
              <h4>Dashboard</h4>
            </div>
            <div className="body-area flex flex-row justify-between align-start">
              <div className="w-30 mr-10">
                <h3>Contratos Realizados</h3>
                <span>Total: 10.925</span>
                <div className="grafico flex flex-column">
                  <Pie {...config} />
                  <div className="tags flex flex-row flex-wrap justify-center align-center">
                    <Tag icon="presencial" text="Contato Presencial"/>
                    <Tag icon="email" text="Contato por e-mail"/>
                    <Tag icon="phone" text="Contato por ligações"/>
                    <Tag icon="whatsapp" text="Contato por whatsapp"/>
                  </div>
                </div>
              </div>
              <div className="w-70">
                <h3>KPIs</h3>
                <span>Atualizado em 12/01/2022 às 12:30:05</span>
                <div className="squares flex flex-row flex-wrap justify-between align-center mt-20">
                  <Square estilo="roxo" text="Contato presencial (quant.)" quantidade={2.785} icon="users"/>
                  <Square estilo="azul" text="Contato por telefone (quant.)" quantidade={2.785} icon="phone"/>
                  <Square estilo="verde" text="Contato por whatsapp (quant.)" quantidade={2.785} icon="whatsapp"/>
                  <Square estilo="vermelho" text="Contato por e-mail (quant.)" quantidade={2.785} icon="email"/>
                  <Square estilo="amarelo" text="Retorno para hoje" quantidade={2.785} icon="arrow-left"/>
                  <Square estilo="verde" text="Retorno em dia" quantidade={2.785} icon="arrow-left"/>
                  <Square estilo="vermelho" text="Retornos atrasados" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Leads Cadastrados" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Tempo médio de primeiro contato" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Leads sem contato" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Leads sem interação a mais de 5 dias" quantidade={2.785} icon="calendar"/>
                  <Square estilo="sem" text="Vendas realizadas" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Leads descartados" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Contatos realizados hoje" quantidade={2.785} icon="users"/>
                  <Square estilo="sem" text="Avaliação Média" quantidade={2.785} icon="start"/>
                </div>
              </div>
            </div>
            <div className="body-area flex flex-row justify-between align-start">
              <div className="w-30 mr-10">
                <h3>Contratos Realizados</h3>
                <span>Total: 10.925</span>
              </div>
              <div className="select-box w-25">
                <div className="text-over-input">
                  <small>Equipes</small>
                </div>
                <div className="input-modal mb-10">
                  <Select style={{width:'100%'}} bordered={false} defaultValue="todos" onChange={handleChange}>
                    <Option value="todos">2022</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="body-area flex flex-row justify-between align-start">
              <div className="grafic-line w-100">
                <Line {...configuracao} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
