import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button, Modal, Select, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";
import { ICrmeLeadContatoCleanDTO, ILeadContatoCleanDTO, ISendTextDTO } from "../../models/happy-code-api.model";
import { useLeadContatoCampanhaService } from "../../services/lead-contato.service";
import { useWhatsappLeadService } from "../../services/lead-whatsapp.service";
import { formatPhone } from "../../util/format";

export const WhatsappLead = () => {
  const { setIsGlobalLoading } = useGlobalContext();
  const [leadContato, setLeadContato] = useState<ILeadContatoCleanDTO[]>();
  const useLeadContato = useLeadContatoCampanhaService();
  const { leadSelecionado, unidade } = useLeadContext();
  const [whatsapp, setWhatsapp] = useState<ISendTextDTO>();
  const useWhatsappLead = useWhatsappLeadService();
  const [sessionNotFound, setSessionNotFound] = useState(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [lerQrCode, setLerQrCode] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = async () => {
  //   setIsModalOpen(true);
  //   const idUnidade = unidade?.id.toString();
  //   setIsGlobalLoading(true);
  //   setSessionNotFound(false)

  //   setTimeout(async () => {
  //     await useWhatsappLead.getQrCodeWhatsapp(idUnidade).then(async (res) => {
  //       const buffer = await res.data.arrayBuffer();
  //       const binaryString = Array.from(new Uint8Array(buffer), byte => String.fromCharCode(byte)).join("");
  //       const theImage = btoa(binaryString);
  //       setQrCode(theImage)
  //     }).catch((err) => {
  //       console.error(err)
  //     }).finally(() => setIsGlobalLoading(false));
  //   }, 3500)
  // };

  const handleOk = () => {
    setLerQrCode(2);
    setIsModalOpen(false);

  };

  const handleCancel = () => {
    setLerQrCode(2);
    setIsModalOpen(false);

  };

  // const { TextArea } = Input;

  // const getAllContatoLead = () => {
  //   setIsGlobalLoading(true);

  //   const predicate = new Predicate();
  //   predicate.addOption('lead.id', leadSelecionado?.id)
  //   predicate.addOption('ativo', true)
  //   useLeadContato.findCleanList(predicate)
  //     .then((response) => {
  //       setLeadContato(response.data);
  //     })
  //     .finally(() => setIsGlobalLoading(false))
  // }

  // const startSession = (idUnidade: string) => {
  //   setIsGlobalLoading(true);
  //   useWhatsappLead.getWhatsappSessionStart(idUnidade).then((response) => {
  //     setLerQrCode(1);
  //   }).catch((error) => {
  //     console.log(error);
  //   }).finally(() => setIsGlobalLoading(false));
  // }

  // const logoutSession = (idUnidade: string) => {
  //   setIsGlobalLoading(true);

  //   useWhatsappLead.logoutSession(idUnidade).then((response) => {
  //     setLerQrCode(0)
  //   })
  //     .catch((error) => console.log(error))
  //     .finally(() => setIsGlobalLoading(false));
  // }

  // const verifySessionIsValid = () => {
  //   setIsGlobalLoading(true);

  //   const idUnidade = unidade?.id.toString();
  //   useWhatsappLead.getWhatsappSession(idUnidade).then((response) => {
  //     if (response.data.pushName == "Sessão não iniciada!") {
  //       setLerQrCode(0);
  //     }

  //     if (response.status == 200 && response.data.pushName == null) {
  //       setLerQrCode(1);
  //     }

  //     if (response.status == 200 && response.data.pushName != "Sessão não iniciada!") {
  //       setLerQrCode(2);
  //     }

  //   })
  //     .catch((err) => console.log(err))
  //     .finally(() => setIsGlobalLoading(false));
  // }

  // const sendWhatsappMessage = () => {
  //   setIsGlobalLoading(true);

  //   useWhatsappLead.sendMessageWhatsapp(whatsapp, leadSelecionado?.id)
  //     .then((response) => {
  //       notification.success({ description: `Mensagem enviado com sucesso, destinatário: ${whatsapp.chatId}`, type: 'success', message: 'Sucesso!', placement: "bottomRight" });
  //     })
  //     .catch((error) => console.log(error))
  //     .finally(() => setIsGlobalLoading(false));
  // }

  const submitValidationFields = () => {
    if (!whatsapp?.chatId) {
      notification.warning({
        description: `Número do contato dever ser informado.`,
        type: 'warning',
        message: 'Atenção!',
        placement: "bottomRight"
      });
      return;
    }

    if (!whatsapp?.text) {
      notification.warning({
        description: `Mensagem dever ser informado.`,
        type: 'warning',
        message: 'Atenção!',
        placement: "bottomRight"
      })
    }

    // sendWhatsappMessage();
  }


  // function uploadPlugin(editor) {
  //   editor.plugins.get("FileRepository")
  //     .createUploadAdapter = (loader) => {
  //       return uploadAdapter(loader);
  //     };
  // }

  // function uploadAdapter(loader) {
  //   return {
  //     upload: () => {
  //       return new Promise((resolve) => {
  //         loader.file.then((file) => {
  //           const reader = new FileReader();
  //           reader.onloadend = () => {
  //             const base64String = reader.result?.toString().split(',')[1] || '';
  //             resolve({ default: `data:image/png;base64,${base64String}` });
  //           };
  //           reader.readAsDataURL(file);
  //         });
  //       });
  //     },
  //   };
  // }


  const handleChangeTelefone = (value: string) => {
    setWhatsapp({ ...whatsapp, chatId: `55${value}` })
  };

  useEffect(() => {
    // setWhatsapp({ ...whatsapp, session: unidade?.id.toString() })
    // getAllContatoLead();
    // verifySessionIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="flex justify-between gap-20 mb-20">
        <div className="flex align-center gap-10">
          <WhatsAppIcon />
          <h1 id="sem-margin">Nova mensagem de whatsapp</h1>
        </div>
        <div className="flex gap-10">
          {/*lerQrCode == 0 ? <Button onClick={() => startSession(unidade.id.toString())} className="button-line">
            Iniciar sessão
          </Button>
            : lerQrCode == 1 ?
              <Button onClick={() => showModal()} className="button-line">
                Ler Qr Code
              </Button>

              : lerQrCode == 2 ?
                <Button onClick={() => logoutSession(unidade.id.toString())} className="button-line">
                  Encerrar Sessão
                </Button>

                : null
          */}
          {/* <Button className="button-line" icon={<AttachFileIcon style={{ color: 'var(--azul-happy)' }} />}>
            Anexar arquivo
          </Button> */}
          <Button className="button-bronce" onClick={submitValidationFields} icon={<SendIcon style={{ color: 'var(--white)' }} />}>
            Enviar
          </Button>
        </div>
      </div>
      <div className="flex flex-row align-center justify-start gap-20">
        <p>De</p>
        <h5>Responsável</h5>
        <div className="circle-cinza">
          <PersonIcon sx={{ color: 'var(--cinza-azulado)' }} />
        </div>
        <h3>{leadContato && leadContato.find(contato => "55" + contato?.dsTelefone == whatsapp?.chatId)?.dsNome}</h3>
        <div className="flex align-center gap-10 w-50">
          <label htmlFor="">Whatsapp</label>
          <div className="box-white w-100 flex align-center" style={{ border: "1.5px solid #d9d9d9", height: 60, borderRadius: 6 }}>
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione um Telefone"
              onChange={handleChangeTelefone}
            >
              {leadContato && leadContato?.map((contato: ICrmeLeadContatoCleanDTO) => (
                <Select.Option key={contato.id} text={contato?.dsTelefone} value={contato?.dsTelefone}>{formatPhone(contato?.dsTelefone)}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {/* TextArea */}

      <div className="input-box" style={{ height: "100%", marginTop: 10 }}>
        <TextArea
          rows={5}
          onChange={(e) => setWhatsapp({ ...whatsapp, text: e.target.value })}
        />
      </div>

      <div>
        <Modal title="QR-CODE" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <img src={`data:image/jpeg;base64,${qrCode}`} alt="qrCode" />
        </Modal>
      </div>
    </>
  )
}
