import { Button } from 'antd';
import { addMinutes, format } from 'date-fns';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmeLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { useLeadTarefaService } from '../../services/lead-tarefa.service';
import { useLeadService } from '../../services/lead.service';
import { useNotificationService } from '../../services/notification.service';

interface Props {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  tarefaSelecionada?: ICrmeLeadTarefaCustomCleanDTO;
  onAbrirDatetimePicker?: () => void;
}

export const ModalAdiarTarefa = (props: Props) => {
  const { open, onClose, onCancel, onAbrirDatetimePicker, tarefaSelecionada } = props;
  const { setIsGlobalLoading } = useGlobalContext();
  const { state, refreshLeads, refreshLeadSelecionado } = useLeadContext();
  const leadService = useLeadService();
  const notification = useNotificationService();
  const tarefaService = useLeadTarefaService();

  const onAdiarEstagio = (minutos: number, flAmanha = false) => {

    let dataAtual = new Date();
    dataAtual.setUTCHours(dataAtual.getHours());
    dataAtual.setUTCMinutes(dataAtual.getMinutes());
      
    if (!flAmanha) {
      dataAtual = new Date(tarefaSelecionada.dhTarefa);
      const hrTarefaFormatada = format(new Date(tarefaSelecionada.hrTarefa), 'HH:mm',);
      dataAtual.setUTCHours(parseInt(hrTarefaFormatada?.split(':')[0]))
      dataAtual.setUTCMinutes(parseInt(hrTarefaFormatada?.split(':')[1]))
    }

    const novaData = addMinutes(dataAtual, minutos);

    setIsGlobalLoading(true);


    tarefaService.patch({
      id: tarefaSelecionada.id,
      dhTarefa: novaData,
      hrTarefa: novaData,
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          refreshLeadSelecionado();
          refreshLeads();
          onClose();
          notification({ description: 'Data / horário da tarefa atualizado com sucesso', type: 'success' });
          return;
        }

        throw new Error();
      })
      .catch((e) => {
        notification({ description: 'Não foi possível atualizar a data e hora da tarefa', type: 'error' });
      })
      .finally(() => setIsGlobalLoading(false))
  }


  if (!open) return null

  return <div className="flex flex-row justify-center align-center modal-container">
    <div className="flex flex-column justify-start align-start modal-resposta">
      <h3>Selecione qual o estágio</h3>
      <div className="flex flex-column justify-end w-100">
        <Button className="button-primary mr-5 mb-5" onClick={() => onAdiarEstagio(30)}>
          Adiar tarefa em 30 minutos
        </Button>

        <Button className="button-primary mr-5 mb-5" onClick={() => onAdiarEstagio(60)}>
          Adiar tarefa em 1 hora
        </Button>

        <Button className="button-primary mr-5 mb-5" onClick={() => onAdiarEstagio(180)}>
          Adiar tarefa em 3 horas
        </Button>

        <Button className="button-primary mr-5 mb-5" onClick={() => onAdiarEstagio(1440, true)}>
          Amanhã
        </Button>

        <Button className="button-primary mr-5 mb-5" onClick={() => onAbrirDatetimePicker()}>
          Selecione uma data
        </Button>

        <Button onClick={() => onClose()} className="button-blue mr-5">Cancelar</Button>
      </div>
    </div>

  </div>
}
