import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { Itemequipe } from "../../components/Item/equipe";
import { Pagination } from 'antd';

export const Equipes = () => {

  return (
    <>
      <div className="container-happy">
        <Sidebar equipes={true} />
        <div className="dashboard">
          <Header filtros={false} modal="equipe"/>
          <div className="area">
              <div className="header-area">
                <h4>Gerenciar Equipes</h4>
              </div>
              <div className="body-area">
                <div className="table">
                  <div className="table-header flex flex-row justify-start align-center">
                    <div className="table-item w-5">
                      <span></span>
                    </div>
                    <div className="table-item flex align-center w-20">
                      <span>Nome da Equipe</span>
                    </div>
                    <div className="table-item flex align-center w-15">
                      <span>Grupo</span>
                    </div>
                    <div className="table-item flex align-center w-15">
                      <span>Páginas</span>
                    </div>
                    <div className="table-item flex align-center w-25">
                      <span>Usuarios</span>
                    </div>
                  </div>
                  <div className="table-item">
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />
                    <Itemequipe item={['Alphaville/SP','Happy Brasil','1','11']} />

                  </div>
                  <div className="table-footer flex">
                    <Pagination defaultCurrent={1} total={50} />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
