import { useState } from "react";
import { Button, Collapse, Space, Input, Select  } from 'antd';
import { Link } from 'react-router-dom';
import { Tag } from '../../components/Tag';
import { ModalResposta } from '../../components/Modal/resposta';

interface Props {
  open:boolean;
  onClose: ()=>void;
}

export const ModalEquipeEditar = (props: Props) =>{
  const { open, onClose } = props;
  const { Panel } = Collapse;
  const { Option } = Select;

  function handleChange(value:any) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);

  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

  return(
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3>Editar equipe</h3>
          <div className="text-over-input">
            <small>Nome</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder="Alphaville/SP" bordered={false} />
          </div>
          <div className="text-over-input">
            <small>Grupo</small>
          </div>
          <div className="input-modal mb-20">
            <Select style={{width:'100%'}} bordered={false} defaultValue="happy-brasil" onChange={handleChange}>
              <Option value="happy-brasil">Happy Brasil</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
          <div className="text-over-input">
            <small>Páginas</small>
          </div>
          <div className="input-modal mb-20">
            <Select style={{width:'100%'}} bordered={false} defaultValue="1" onChange={handleChange}>
              <Option value="1">facebook.com/happycodealphaville</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
          <Space direction="vertical">
            <Collapse collapsible="header">
              <Panel header="Usuarios" key="3">
                <div className="flex flex-row justify-start alingn-center flex-wrap">
                  <Tag icon="user" text="Alexandre Dias" />
                  <Tag icon="user" text="Alexandra Lima" />
                  <Tag icon="user" text="Rebeca Souza" />
                  <Tag icon="user" text="Jose A. Junior" />
                  <Tag icon="user" text="Diego Teixeira" />
                  <Tag icon="user" text="Barbar Gomes" />
                  <Tag icon="user" text="Thaila Torres" />
                  <Tag icon="user" text="Paulo A. Lopes" />
                  <Tag icon="user" text="Tiago Fernandez" />
                </div>
              </Panel>
            </Collapse>
          </Space>
          <div className="flex flex-row justify-end w-100 mt-20">
            <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
            <Link to="/equipes">
              <Button onClick={onChangeModal} className="button-primary">Salvar Alterações</Button>
            </Link>
          </div>
        </div>
        <ModalResposta
          open={isOpen}
          onClose={onClose1}
          status="Alteração de cadastro"
          text="As alterações realizadas na equipe foram salvas com sucesso."
          link="equipes"
        />
      </div>
    </>
  )
}
