import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmeLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { TarefaLead } from '../../pages/Leads/tarefa';

interface Props {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  tarefa?: ICrmeLeadTarefaCustomCleanDTO;
  onAbrirDatetimePicker?: () => void;
}

export const ModalEditarTarefa = (props: Props) => {
  const { open, onClose, onCancel, onAbrirDatetimePicker } = props;
  const { setIsGlobalLoading } = useGlobalContext();
  const { state, refreshLeads, refreshLeadSelecionado } = useLeadContext();

  if (!open) return null

  return <div className="flex flex-row justify-center align-center modal-container">
    <div className="flex flex-column justify-start align-start modal-resposta" style={{ width: '60%' }}>
      <TarefaLead onClose={onClose} onCancel={onCancel} tarefa={props?.tarefa} edicao={true} modal={true} />
    </div>

  </div>
}
