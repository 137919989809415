import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICrmeLeadEmailCleanDTO,
	ICrmeLeadEmailCustomDTO,
	ICrmeLeadEmailDTO,
	ICrmeLeadEmailFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useEmailService = () => {
	const resourceURL = '/crme/lead-email';

	const enviarEmail = (dto: ICrmeLeadEmailCustomDTO): Promise<AxiosResponse<ICrmeLeadEmailCustomDTO>> => {
		return http.post(`${resourceURL}/enviar-email`, dto);
	}

	return {
		...baseFind<ICrmeLeadEmailCleanDTO, ICrmeLeadEmailDTO, ICrmeLeadEmailFullDTO>(resourceURL),
		...baseFindList<ICrmeLeadEmailCleanDTO, ICrmeLeadEmailDTO, ICrmeLeadEmailFullDTO>(resourceURL),
		...baseUpdate<ICrmeLeadEmailCleanDTO, ICrmeLeadEmailDTO, ICrmeLeadEmailFullDTO>(resourceURL),
		...baseCreate<ICrmeLeadEmailCleanDTO, ICrmeLeadEmailDTO, ICrmeLeadEmailFullDTO>(resourceURL),
		...baseFindById<ICrmeLeadEmailCleanDTO, ICrmeLeadEmailDTO, ICrmeLeadEmailFullDTO>(resourceURL),
		enviarEmail,
	};
};