import { AxiosResponse } from 'axios';
import http from '../../helpers/http-common';

interface Props {
    id?: number;
}

export const baseFindById = <C, D, F>(resourceURL: string) => {
    return {
        findCleanById: (body: Props): Promise<AxiosResponse<C>> => http.get(`${resourceURL}/clean/${body.id}`),
        findById: (body: Props): Promise<AxiosResponse<D>> => http.get(`${resourceURL}/${body.id}`),
        findFullById: (body: Props): Promise<AxiosResponse<F>> => http.get(`${resourceURL}/full/${body.id}`),
    }
}