import EditIcon from '@mui/icons-material/Edit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Pagination } from '@mui/material';
import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ItemTarefalista } from '../../components/ItemTarefa/lista';
import { useTarefaContext } from '../../context/tarefa.service';
import { ICrmeLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';

export const List = () => {

  const [isOpenHeader, setIsOpenHeader] = useState('d-none');
  const [isAllCheckboxSelect, setIsAllCheckboxSelect] = useState(false);
  //const { setIsGlobalLoading } = useGlobalContext();
  const { tarefaResponse, onPageChange } = useTarefaContext();

  // function handleAllCheckbox(e: any) {
  //   if (e.target.value === "checkall") {
  //     setIsAllCheckboxSelect(true)
  //   } else {
  //     setIsAllCheckboxSelect(false)
  //   }
  //   if (isOpenHeader === 'd-none') {
  //     setIsOpenHeader('header-list')
  //   } else {
  //     setIsOpenHeader('d-none')
  //   }
  // }

  function handleAction(event: any) {
    setIsAllCheckboxSelect(false)
    if (isOpenHeader === 'd-none') {
      setIsOpenHeader('header-list')
    } else {
      setIsOpenHeader('d-none')
    }
  }

  return (
    <div className="area area-list" style={{
      background: 'white',
    }}>
      <div className="body-area area-table">
        <div className={`${isOpenHeader} flex flex-row align-center justify-between`}>
          <p>{isAllCheckboxSelect === true ? '10' : '1'} item selecionado</p>
          <div className="flex flex-row justify-center align-center gap-10">
            {isAllCheckboxSelect === true ?
              <Button className="button-white">Arquivar</Button>
              :
              <></>
            }
            <Button className="button-white">Mudar Status</Button>
            <Button className="button-white" icon={<SwapHorizIcon className="mr-10" />}>
              Mover para etapa
            </Button>
            {(isOpenHeader === 'header-list' && isAllCheckboxSelect === false) ?
              <Button className="button-white" icon={<EditIcon className="mr-10" />}>
                Editar lead
              </Button>
              :
              <></>
            }
          </div>
        </div>
        <div className="table table-list flex" style={{ width: '100%' }}>
          <div style={{ backgroundColor: '#0C306C', width: '100%' }} className="table-header flex flex-row justify-start align-center">
            <div style={{ width: 30 }} className="table-item flex justify-center align-center gap-10">
              {/* <Checkbox onChange={handleAllCheckbox} value="checkall" /> */}
              {/* <NoteAddIcon style={{ color: 'var(--white)' }} /> */}
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Tarefas</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Status</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Data / Hora</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Responsável</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Lead Vinculado</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Concluído</span>
            </div>
          </div>
          <div className="table-items" style={{ width: '100%' }}>
            {tarefaResponse?.data?.content.map((tarefa: ICrmeLeadTarefaCustomCleanDTO, index: number) => {
              return (<Link to={`/lead/${tarefa.idLead}/tarefa`}
                key={tarefa.id}
                style={{ background: index % 2 === 0 ? '#edeff1' : 'white', textDecoration: 'none' }}>
                <ItemTarefalista
                  id={tarefa.id}
                  tarefa={tarefa}
                  onAction={handleAction}
                  checked={false}
                />
              </Link>
              )
            })}
          </div>
          <div className="table-footer flex justify-end">
            <Pagination
              onChange={(event, page) => onPageChange(event, page - 1)}
              page={tarefaResponse?.data ? tarefaResponse?.data.pageable.pageNumber + 1 : 0}
              count={tarefaResponse?.data ? tarefaResponse?.data.totalPages : 0} />

          </div>
        </div>
      </div>
    </div >
  )
}
