import * as Unicons from '@iconscout/react-unicons';

interface Props{
  icon:string;
  text:string
}

export const Tag = (props:Props) =>{

  const{icon, text} = props;

  if(icon==='presencial'){
    return(
      <>
        <div className={`tag-${icon} flex flex-row justify-start align-center`}>
          <Unicons.UilUsersAlt size={16} color='var(--roxo-presencial-400)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='email'){
    return(
      <>
        <div className={`tag-${icon} flex flex-row justify-start align-center`}>
          <Unicons.UilEnvelope size={16} color='var(--vermelho-atrasado)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='phone'){
    return(
      <>
        <div className={`tag-${icon} flex flex-row justify-start align-center`}>
          <Unicons.UilCalling size={16} color='var(--azul-ligacoes-500)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='whatsapp'){
    return(
      <>
        <div className={`tag-${icon} flex flex-row justify-start align-center`}>
          <Unicons.UilWhatsapp size={16} color='var(--verde-futuro)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='facebook'){
    return(
      <>
        <div className="tag flex flex-row justify-start align-center">
          <Unicons.UilFacebook size={16} color='var(--azul-happy)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='user'){
    return(
      <>
        <div className="tag flex flex-row justify-start align-center">
          <Unicons.UilUser size={16} color='var(--azul-happy)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  if(icon==='users'){
    return(
      <>
        <div className="tag flex flex-row justify-start align-center">
          <Unicons.UilUsersAlt size={16} color='var(--azul-happy)'/>
          <span>{text}</span>
        </div>
      </>
    );
  }

  return(
    <>
      <div className="tag flex flex-row justify-start align-center">
        <span>Tag</span>
      </div>
    </>
  );

}
