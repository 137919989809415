import { useState } from "react";
import { Button, Input, Select, Switch } from 'antd';
import { ModalResposta } from '../../components/Modal/resposta';

interface Props {
  open:boolean;
  onClose: ()=>void;
}

export const ModalCargoNew = (props: Props) =>{
  const { open, onClose } = props;
  const { Option } = Select;

  function handleChange(value:any) {
    return;
  }

  function onChange(checked:boolean) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);
  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

  return(
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Novo Cargo</h3>
          <div className="text-over-input">
            <small>Nome do Cargo</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder="Rosiane" bordered={false} />
          </div>
          <div className="flex flex-row">
            <div className="w-50" style={{marginRight:5}}>
              <div className="text-over-input">
                <small>Dashboard</small>
              </div>
              <div className="input-modal mb-10">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="w-50" style={{marginLeft:5}}>
              <div className="text-over-input">
                <small>Gerenciar Usuarios</small>
              </div>
              <div className="input-modal mb-10">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-50" style={{marginRight:5}}>
              <div className="text-over-input">
                <small>CRM</small>
              </div>
              <div className="input-modal mb-10">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="w-50" style={{marginLeft:5}}>
              <div className="text-over-input">
                <small>Gerenciar Equipes</small>
              </div>
              <div className="input-modal mb-10">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-50" style={{marginRight:5}}>
              <div className="text-over-input">
                <small>Gerenciar Cargos</small>
              </div>
              <div className="input-modal mb-20">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="w-50" style={{marginLeft:5}}>
              <div className="text-over-input">
                <small>Lista de contatos</small>
              </div>
              <div className="input-modal mb-20">
                <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
                  <Option value="gestor">Gestor(a) Comercial</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between align-center mb-30">
            <h4>Participa do rodízio</h4>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <div className="flex flex-row justify-between align-center mb-30">
            <h4>Importar Dados</h4>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <div className="flex flex-row justify-between align-center mb-30">
            <h4>Exportar Dados</h4>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <div className="flex flex-row justify-end w-100">
            <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
            <Button onClick={onChangeModal} className="button-primary">Salvar</Button>
          </div>
        </div>
        <ModalResposta
          open={isOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações e permissões de acesso do novo cargo foram salvas com êxito."
          link="cargos"
        />
      </div>
    </>
  )
}
