import { ICrmeLeadAnotacaoCleanDTO, ICrmeLeadAnotacaoDTO, ICrmeLeadAnotacaoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";

export const useLeadAnotacaoService = () => {

  const resourceURL = 'crme/lead-anotacao';

  return {
    ...baseCreate<ICrmeLeadAnotacaoCleanDTO, ICrmeLeadAnotacaoDTO, ICrmeLeadAnotacaoFullDTO>(resourceURL),
  };

}
