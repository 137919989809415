import http from '../helpers/http-common';
import {
	ICrmeVwLeadCleanDTO,
	ICrmeVwLeadCustomDTO,
	ICrmeVwLeadFullDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useVwLeadService = () => {
	const resourceURL = 'crme/vwlead';

	const findCustomIndex = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-custom-index`, formatHttpParams(predicate))
	}


	const findCustomIndexById = (body) => {
		return http.get(`${resourceURL}/${body.id}/custom`)
	}

	return {
		...baseCreate<ICrmeVwLeadCleanDTO, ICrmeVwLeadCustomDTO, ICrmeVwLeadFullDTO>(
			resourceURL
		),
		...baseFindById<ICrmeVwLeadCleanDTO, ICrmeVwLeadCustomDTO, ICrmeVwLeadFullDTO>(
			resourceURL
		),
		...baseFind<ICrmeVwLeadCleanDTO, ICrmeVwLeadCustomDTO, ICrmeVwLeadFullDTO>(
			resourceURL
		),
		...baseDelete(resourceURL),
		findCustomIndex,
		findCustomIndexById,
	};
};
