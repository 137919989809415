import { Button } from 'antd';
import { useMemo } from 'react';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmeLeadCustomDTO, ICrmeLeadEstagioDTO } from '../../models/happy-code-api.model';
import { useLeadService } from '../../services/lead.service';
import { useNotificationService } from '../../services/notification.service';

interface Props {
	open: boolean;
	onClose: () => void;
	onCancel?: () => void;
	leads: ICrmeLeadCustomDTO[];
}

export const ModalMudarStatus = (props: Props) => {
  const { open, onClose, onCancel, leads } = props;
  const { setIsGlobalLoading } = useGlobalContext();
  const { state, refreshLeads } = useLeadContext();
  const leadService = useLeadService();
  const notification = useNotificationService();

  const onAlterarEstagio = (estagio: ICrmeLeadEstagioDTO) => {
    const promises = leads?.map((lead) => leadService.alterarStatus(lead.id, estagio.cdLeadEstagio));
    setIsGlobalLoading(true);
    Promise.all(promises).then(() => {
      return refreshLeads();
    }).then(() => {
      notification({ description: 'Etapa alterada com sucesso', type: 'success' });
      refreshLeads();
      onClose();
    }).catch((e) => {
      notification({ description: 'Ocorreu um erro ao alterar a etapa', type: 'error' });
    }).finally(() => setIsGlobalLoading(false))
  }

  const menus = useMemo(() => {
    if (state?.boards?.length <= 0) return [];

    return (state?.boards as ICrmeLeadEstagioDTO[]).map((item) => {
			return (
				<Button
					key={item?.id}
					className="button-primary mr-5 mb-5"
					onClick={() => onAlterarEstagio(item)}
				>
					{item?.dsLeadEstagio}
				</Button>
			);
		});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, leads]);

  if (!open) return null

  return <div className="flex flex-row justify-center align-center modal-container">
    <div className="flex flex-column justify-start align-start modal-resposta">
      <h3>Selecione qual o estágio</h3>
      <div className="flex flex-column justify-end w-100">
        {menus}

        <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
      </div>
    </div>
  </div>
}
