import http from "../helpers/http-common";
import { ICrmeLeadArquivoCleanDTO, ICrmeLeadArquivoDTO, ICrmeLeadArquivoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseDelete } from "./base/base-delete.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useLeadArquivoService = () => {

  const resourceURL = '/crme/lead-arquivo';


  const download = (fileName: string, name: string) => {
		return http.get(`${resourceURL}/download/${fileName}?name=${name}`, {
			responseType: 'blob',
		});
	};

  return {
    ...baseFindList<ICrmeLeadArquivoCleanDTO, ICrmeLeadArquivoDTO, ICrmeLeadArquivoFullDTO>(resourceURL),
    ...baseFind<ICrmeLeadArquivoCleanDTO, ICrmeLeadArquivoDTO, ICrmeLeadArquivoFullDTO>(resourceURL),
    ...baseCreate<ICrmeLeadArquivoCleanDTO, ICrmeLeadArquivoDTO, ICrmeLeadArquivoFullDTO>(resourceURL),
    ...baseDelete(resourceURL),
    ...baseUpdate(resourceURL),
    download,
  };

}
