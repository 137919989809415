import { Button } from 'antd';

interface Props {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  status: string;
  text: string;
  link?: string;
}

export const ModalResposta = (props: Props) => {
  const { open, status, text, link, onClose, onCancel } = props;

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal-resposta">
          <h3>{status}</h3>
          <p>{text}</p>
          <div className="flex flex-row justify-end w-100">
            {!!link && <a href={`/${link}`}>
              <Button className="button-primary mr-5">Ok</Button>
            </a>}
            {!!onCancel && <Button onClick={onCancel} className="button-blue mr-5">Cancelar</Button>}
            {!!onClose && <Button onClick={onClose} className="button-primary mr-5">Ok</Button>}
          </div>
        </div>
      </div>
    </>
  )
}
