import {
	ICrmeLeadEstagioCleanDTO,
	ICrmeLeadEstagioDTO,
	ICrmeLeadEstagioFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEstagioService = () => {
	const resourceURL = 'crme/lead-estagio';

	return {
		...baseFindList<ICrmeLeadEstagioCleanDTO, ICrmeLeadEstagioDTO, ICrmeLeadEstagioFullDTO>(resourceURL),
	};
};
