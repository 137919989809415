import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, Checkbox, Form, Input, InputNumber, notification } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { BoxDocumentos } from '../../components/BoxDocumentos';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { IArquivoCleanDTO, ICrmeLeadJuridicoArquivoDTO, ICrmeLeadJuridicoArquivoFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useLeadJuridicoArquivoService } from '../../services/lead-juridico-arquivo.service';
import { useLeadService } from '../../services/lead.service';
import { useVwLeadService } from '../../services/vwlead.service';

export const JuridicoLead = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const { leadSelecionado, setLeadSelecionado } = useLeadContext();
	const leadJuridicoArquivoService = useLeadJuridicoArquivoService();
	const leadService = useLeadService();
	const vwLeadService = useVwLeadService();
	const [openModalAlterarLead, setOpenModalAlterarLead] = useState(false);
	const [leadDocumentos, setLeadDocumentos] = useState<ICrmeLeadJuridicoArquivoFullDTO[]>();
	const [nmArquivo, setNmArquivo] = useState<string>();
	const [nrSerasaScore, setNrSerasaScore] = useState<number>(0);
	const [form] = Form.useForm();

	useEffect(() => {
		if (leadSelecionado.id) {
			fetchArquivos();
			form.setFieldsValue({
				...leadSelecionado,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadSelecionado]);

	const onSalvarLeadClick = () => {
		const body = { ...form.getFieldsValue() };

		setIsGlobalLoading(true);
		return leadService.patch(leadSelecionado.id as number, body)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return vwLeadService.findCustomIndexById({ id: leadSelecionado.id })
						.then((response) => {
							if (response.status >= 200 && response.status <= 299) {
								setLeadSelecionado(response?.data);
								setOpenModalAlterarLead(false);
								notification.success({ description: 'Jurídico atualizado com sucesso!', type: 'success', message: 'Sucesso!' })
								return;
							}

							throw new Error();
						})
				}

				throw new Error();
			}).catch((e) => {
				notification.error({ description: 'Não foi possível alterar os dados do jurídico.', type: 'error', message: 'Erro!', })
			}).finally(() => {
				setIsGlobalLoading(false);
			});
	}


	const fetchArquivos = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('juridico.lead.id', leadSelecionado.id);

		setIsGlobalLoading(true);
		return leadJuridicoArquivoService.findFullList(predicate)
			.then((response) => {
				if (response.status === 200) {
					setLeadDocumentos(response.data);
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const formatDefaultList = (files: ICrmeLeadJuridicoArquivoDTO[]): UploadFile[] => {
		return files && files.map((file, index) => ({
			uid: index?.toString(),
			arquivoId: file?.arquivo?.id,
			id: file?.id,
			name: file?.arquivo?.codigo,
			status: 'done',
			fileName: file?.arquivo?.codigo,
			url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${file?.arquivo?.url}?name=${file?.arquivo?.codigo}`,
		}));
	};

	const getDefaultFileList = () => {
		if (leadDocumentos && leadDocumentos?.length > 0) {
			return formatDefaultList(leadDocumentos);
		}
		return [];
	};

	const onUploadContratoMatricula = (uploads: UploadFile<IArquivoCleanDTO>[]) => {
		fetchArquivos();
	};

	const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string) => {
		if (!allowedTypes) {
			return true;
		}

		if (type) {
			return allowedTypes.includes(type);
		}
	};

	const onRemove = (arquivo) => {
		// setIsGlobalLoading(true);
		// leadArquivoService.delete(arquivo).then(() => {
		//   Promise.all([getMatriculaDocumento()]).finally(() =>
		//     setIsGlobalLoading(false)
		//   );
		// }).catch(() => setIsGlobalLoading(false));
	};

	const onDownload = (juridico: ICrmeLeadJuridicoArquivoDTO) => {

		setIsGlobalLoading(true);
		leadJuridicoArquivoService
			.download(juridico?.arquivo?.url, juridico?.arquivo?.codigo)
			.then((response) => {
				saveAs(response?.data, juridico?.arquivo?.codigo);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			})

	};

	const onDelete = (juridico: ICrmeLeadJuridicoArquivoDTO) => {
		setIsGlobalLoading(true);

		juridico.ativo = false;
		leadJuridicoArquivoService.patch(juridico)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					notification.success({ description: 'Arquivo removido com sucesso!', type: 'success', message: 'Sucesso!' });
					return fetchArquivos();
				}
				throw new Error();
			})
			.catch((e) => {
				notification.error({ description: 'Não foi possível remover o arquivo!', type: 'error', message: 'Erro!', });
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	return (
		<>
			<Form
				style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
				name="basic"
				// initialValues={{ remember: true }}
				form={form}
			>
				<div className="tarefa-panel" id="dados-lead">
					<div className="flex justify-between flex-column gap-20 mb-20">
						<div className="flex align-center gap-10">
							<GavelIcon />
							<h1 id="sem-margin">Dados jurídicos</h1>
						</div>

						<div className="flex flex-row gap-10">
							<Form.Item name="id" style={{ display: 'none' }} />

							<div className="flex flex-column justify-center w-50 mb-10">
								<div className="flex flex-row gap-10 mb-10">
									<div className="flex align-center gap-10 flex">
										<div className={`tag-novo flex flex-row align-center`}>
											<Form.Item name={'flCofEnviada'} valuePropName="checked" style={{ width: '100%', marginBottom: 0 }} rules={[{ required: false, message: 'Marque o check COF enviada' }]}>
												<Checkbox
													className={`check-novo`}
													id="flCofEnviada" />
											</Form.Item>
											<div>
												<p style={{ whiteSpace: "nowrap", color: "var(--azul-happy)" }}>COF enviada</p>
											</div>
										</div>
									</div>


									<div className="flex align-center gap-10 flex">
										<div className={`tag-novo flex flex-row align-center`}>
											<Form.Item name={'flContratoAssinado'} valuePropName="checked" style={{ width: '100%', marginBottom: 0 }} rules={[{ required: false, message: 'Marque o check contrato assinado' }]}>
												<Checkbox
													className={`check-novo`}
													id="flContratoAssinado" />
											</Form.Item>
											<p style={{ whiteSpace: "nowrap", color: "var(--azul-happy)" }}>Contrato assinado</p>
										</div>
									</div>
								</div>

								<div className="flex flex-row gap-10">
									<div className="flex align-center gap-10 flex flex-fill">
										<label htmlFor="nrSerasaScore">Serasa score</label>
										<div className="box-white">
											<Form.Item name={'nrSerasaScore'} rules={[{ required: false, message: 'Insira o Serasa score' }]}>
												<InputNumber
													id="nrSerasaScore"
													min={0}
													max={1000}
													className="w-100"
													bordered={false}
													onChange={(e) => setNrSerasaScore(e)} />
											</Form.Item>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<div className="flex flex-row justify-end w-100">
						<Button onClick={() => setOpenModalAlterarLead(true)} className="button-primary">
							Salvar dados juridícos
						</Button>
					</div>
				</div>

				<ModalResposta
					open={openModalAlterarLead}
					onClose={onSalvarLeadClick}
					onCancel={() => setOpenModalAlterarLead(false)}
					status="Salvar dados jurídicos"
					text="Deseja mesmo alterar as informações do jurídico?"
					link=""
				/>
			</Form>


			<div className="tarefa-panel">
				<div className="flex gap-10">
					<AttachFileIcon />
					<h1>Adicionar arquivos</h1>
				</div>
				<div className="box-documentos flex justify-between align-center mb-20 gap-20">
					<p>Selecione ou arraste um arquivo:</p>
					<div className="box-white flex align-center">
						<Input value={nmArquivo} onChange={(e) => setNmArquivo(e.target.value)} placeholder="Informe a descrição para o arquivo" bordered={false} />
					</div>

					<div className="upload-component">

						<BoxDocumentos
							maxCount={1}
							statusDocumento={true}
							defaultFileList={getDefaultFileList()}
							title={'Contrato de matricula'}
							action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}crme/lead-juridico-arquivo/lead/${leadSelecionado.id}/upload?filename=${nmArquivo}`}
							subtitle={'Enviar o contrato assinado da matricula do aluno.'}
							setFileList={(fileList) => onUploadContratoMatricula(fileList)}
							beforeUpload={(file: UploadFile) => {
								if (!nmArquivo || nmArquivo?.length <= 0) {
									notification.warning({ description: 'Informe uma descrição para o arquivo', type: 'warning', message: 'Atenção!', });
									return false;
								}
								const isAllowedType = validateFileType(file, 'application/pdf');
								if (!isAllowedType) {
									notification.info({ description: 'É permitido somente arquivos .pdf', type: 'info', message: 'Informação!', });
									return false;
								}
								return true;
							}}
							onRemoveFile={(fileList) => onRemove(fileList)}
						/>
					</div>
				</div>
				<table className="w-100 mb-20">
					<thead>
						<tr>
							<th className="w-30 header-row pl-20">Descrição</th>
							<th className="w-30 header-row pl-20">Arquivo</th>
							<th className="w-20 header-row">Data</th>
							<th className="w-50 header-row" colSpan={2}>
								Tamanho
							</th>
						</tr>
					</thead>
					<tbody>
						{leadDocumentos && leadDocumentos.map((juridico) => {
							return (
								<tr key={juridico?.id}>
									<td className="flex align-center">
										<div className="circle-cinza">
											<PictureAsPdfIcon />
										</div>
										<p>{juridico?.arquivo?.nmArquivo || '--'}</p>
									</td>
									<td>
										<p>{juridico?.arquivo?.codigo}</p>
									</td>
									<td>
										<p>{juridico?.dataCriacao ? format(new Date(juridico?.dataCriacao), 'dd/MM/yyyy') : '--'}</p>
									</td>
									<td>
										<p>{juridico?.arquivo?.vlTamanho > 0 ? (juridico?.arquivo?.vlTamanho / 1024).toFixed(2) : 0}KB</p>
									</td>
									<td className="flex align-center justify-end gap-10">
										<Button onClick={() => onDownload(juridico)}>
											<CloudDownloadOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
										</Button>
										<Button onClick={() => onDelete(juridico)}>
											<DeleteOutlineOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
