import {
	ICrmeLeadOrigemCleanDTO,
	ICrmeLeadOrigemDTO,
	ICrmeLeadOrigemFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadOrigemService = () => {
	const resourceURL = 'crme/lead-origem';

	return {
		...baseFindList<ICrmeLeadOrigemCleanDTO, ICrmeLeadOrigemDTO, ICrmeLeadOrigemFullDTO>(resourceURL),
	};
};
