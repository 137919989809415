
import http from './../../helpers/http-common';

interface Props {
    id?: number;
}

export const baseDelete = (resourceURL: string) => {
    return {
        delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`)
    }
}