import { Rotas } from './routes';
import './assets/sass/app.scss';
import 'antd/dist/reset.css';
import { GlobalContextProvider } from './context/global.service';
import GlobalLoading from './components/GlobalLoading/index';
import { LeadContextProvider } from './context/lead.context.service';

export const App = () => {
  return (
    <div>
      <GlobalContextProvider>
        <LeadContextProvider>
          <GlobalLoading />
          <Rotas />
        </LeadContextProvider>
      </GlobalContextProvider>
    </div>

  );
}
