import {
	ICrmeLeadHistoricoTipoCleanDTO,
	ICrmeLeadHistoricoTipoDTO,
	ICrmeLeadHistoricoTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadHistoricoTipoService = () => {
	const resourceURL = 'crme/tipo-lead-historico';

	return {
		...baseFindList<ICrmeLeadHistoricoTipoCleanDTO, ICrmeLeadHistoricoTipoDTO, ICrmeLeadHistoricoTipoFullDTO>(resourceURL),
	};
};
