import { Checkbox } from "antd";
import { useState } from "react";

interface Props {
  tipo: 'atrasado' | 'hoje' | 'futuro' | 'novo' | 'tarefaConcluida';
  text?: string;
  onChange?: any;
  state?: any;
  onFiltroRetornoClick?: any;
  disabled?: boolean;
}

export const Checkboxtag = ({ tipo, text, onChange, state, onFiltroRetornoClick, disabled }: Props) => {

  const [isStyle, setIsStyle] = useState('tag-check');
  const [isCheck, setIsCheck] = useState('');

  function onChange2(e: any) {
    if (e.target.checked === true) {

      onFiltroRetornoClick(tipo, true);
      setIsStyle(`tag-${tipo}`);
      setIsCheck(`check-${tipo}`);
    } else {
      onFiltroRetornoClick(tipo, false);
      setIsStyle('tag-check');
      setIsCheck(`check-${tipo}`);
    }
  }

  if (tipo === 'atrasado') {
    return (
      <>
        <div className={`tag-${tipo} flex flex-row justify-between align-center`}>
                <Checkbox disabled={disabled} checked={state?.filtro?.atrasado} className={`check-${tipo}`} onChange={onChange || onChange2} /><p>Retorno Atrasado</p>
        </div>
      </>
    )
  }
  if (tipo === 'hoje') {
    return (
      <>
        <div className={`tag-${tipo} flex flex-row justify-between align-center`}>
                <Checkbox disabled={disabled} checked={state?.filtro?.hoje} className={`check-${tipo}`} onChange={onChange || onChange2} /><p>Retorno para Hoje</p>
        </div>
      </>
    )
  }

  if (tipo === 'futuro') {
    return (
      <>
        <div className={`tag-${tipo} flex flex-row justify-between align-center`}>
                <Checkbox disabled={disabled} checked={state?.filtro?.futuro} className={`check-${tipo}`} onChange={onChange || onChange2} /><p>Retorno Futuro</p>
        </div>
      </>
    )
  }

  if (tipo === 'novo') {
    return (
      <>
        <div className={`tag-${tipo} flex flex-row justify-between align-center`}>
                <Checkbox disabled={disabled}  checked={state?.filtro?.novo} className={`check-${tipo}`} onChange={onChange || onChange2} />
          <p>{text ? text : 'Sem Retorno'}</p>
        </div>
      </>
    )
  }

  if (tipo === 'tarefaConcluida') {
    return (
      <>
        <div className={`tag-${tipo} flex flex-row justify-between align-center`}>
          <Checkbox checked={state?.filtro?.tarefaConcluida} className={`check-${tipo}`} onChange={onChange || onChange2} />
          <p>{text ? text : 'Tarefa Concluída'}</p>
        </div>
      </>
    )
  }

  return (
    <></>
  )
}
