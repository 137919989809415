import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { Pagination } from 'antd';
import { Itemusuario } from "../../components/Item/usuario";

export const Usuarios = () => {

  return (
    <>
      <div className="container-happy">
        <Sidebar usuarios={true} />
        <div className="dashboard">
          <Header filtros={false} modal="users"/>
          <div className="area">
            <div className="header-area">
              <h4>Gerenciar Usuario</h4>
            </div>
            <div className="body-area">
              <div className="table">
                <div className="table-header flex flex-row justify-start align-center">
                  <div className="table-item w-10">
                    <span></span>
                  </div>
                  <div className="table-item flex align-center w-25">
                    <span>Nome</span>
                  </div>
                  <div className="table-item flex align-center w-15">
                    <span>Cargo</span>
                  </div>
                  <div className="table-item flex align-center w-10">
                    <span>Status</span>
                  </div>
                  <div className="table-item flex align-center w-15">
                    <span>Grupo</span>
                  </div>
                  <div className="table-item flex align-center w-10">
                    <span>Equipes</span>
                  </div>
                  <div className="table-item flex align-center w-15">
                    <span>Último acesso</span>
                  </div>
                </div>
                <div className="table-item">
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Bloqueado','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item-block"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                  <Itemusuario item={['Rosiane Camargo','Gestor/Comercial','Ativo','Happy Brasil','1','04/02/2022 10:02:50']} clase="row-item"/>
                </div>
                <div className="table-footer flex">
                  <Pagination defaultCurrent={1} total={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
