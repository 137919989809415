import { Button } from 'antd';

interface Props {
  open:boolean;
  onClose: ()=>void;
  status:string;
  text:string;
  link:string;
}

export const ModalUsuarioBloquear = (props: Props) =>{
  const { open, onClose, status, text, link } = props;

  if (!open) return null

    return(
      <>
        <div className="flex flex-row justify-center align-center modal-container">
          <div className="flex flex-column justify-start align-start modal-resposta">
            <h3>{status}</h3>
            <p>{text}</p>
            <div className="flex flex-row justify-end w-100">
              <Button onClick={onClose}  className="button-blue mr-5">Cancelar</Button>
              <a href={`/${link}`}>
                <Button className="button-primary mr-5">Bloquear</Button>
              </a>
            </div>
          </div>
        </div>
      </>
    )
}
