import { useState } from "react";

import { ModalCargoNew } from '../../components/Modal/cargonew';
import { ModalEquipeNew } from "../../components/Modal/equipenew";
import { ModalFilter } from '../../components/Modal/filter';
import { ModalLeadNew } from '../../components/Modal/leadnew';
import { ModalUsuarioNew } from "../../components/Modal/usernew";
import { Checkboxtag } from "../Checkboxtag";

import * as Unicons from '@iconscout/react-unicons';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Button, Input, Select } from "antd";
import { useLeadContext } from "../../context/lead.context.service";

interface Props {
  filtros?: boolean
  modal?: 'equipe' | 'users' | 'cargo' | 'lead'
  onFunil?: (e: any) => void
  onList?: (e: any) => void
  estilo?: string
}

export const Header = ({ filtros, modal, onFunil, onList, estilo }: Props) => {

  const { Option } = Select;

  function handleChange(value: any) {
    return;
  }

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const { onChangePesquisa,
    totalLeads,
    totalLeadsResultado,
    flPossuiFiltro,
    limparFiltros,
    state,
    onFiltroRetornoClick,
    onRemoverBuscaClick } = useLeadContext();

  const onChangeModal = () => {
    if (isOpenModal === true) {
      setIsOpenModal(false)
    } else {
      setIsOpenModal(true)
    }
  }

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const onChangeModalFilter = () => {
    if (isOpenFilter === true) {
      setIsOpenFilter(false)
    } else {
      setIsOpenFilter(true)
    }
  }

  const onCloseFilter = () => {
    setIsOpenFilter(false)
  }

  function wordButton(modal: string | undefined) {
    switch (modal) {
      case 'lead':
        return 'Novo Lead';
      case 'cargo':
        return 'Novo Cargo';
      case 'users':
        return 'Novo Usuario';
      case 'equipe':
        return 'Novo Equipe';
      default:
        return 'Botão'
    }
  }

  return (
    <>
      {!modal ?
        <>
          <div className="header-box flex justify-center" style={{ height: 150 }}>
            <div className="search-box flex flex-row justify-between align-center">
              <div className="flex flex-row justify-start align-center w-75">
                <div className="select-box w-25 mr-5">
                  <div className="text-over-input">
                    <small>Equipes</small>
                  </div>
                  <div className="input-modal mb-10">
                    <Select style={{ width: '100%' }} bordered={false} defaultValue="todos" onChange={handleChange}>
                      <Option value="todos">Todos</Option>
                      <Option value="lucy">Lucy</Option>
                      <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                  </div>
                </div>
                <div className="select-box w-25 mr-5">
                  <div className="text-over-input">
                    <small>Equipes</small>
                  </div>
                  <div className="input-modal mb-10">
                    <Select style={{ width: '100%' }} bordered={false} defaultValue="todos" onChange={handleChange}>
                      <Option value="todos">Todos</Option>
                      <Option value="lucy">Lucy</Option>
                      <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                  </div>
                </div>
                <div className="select-box w-25 mr-5">
                  <div className="text-over-input">
                    <small>Equipes</small>
                  </div>
                  <div className="input-modal mb-10">
                    <Input bordered={false} />
                    <Unicons.UilCalender size={20} color="var(cinza-azulado-500)" />
                  </div>
                </div>
                <div className="select-box w-25 mr-5">
                  <div className="text-over-input">
                    <small>Equipes</small>
                  </div>
                  <div className="input-modal mb-10">
                    <Input bordered={false} />
                    <Unicons.UilCalender size={20} color="var(cinza-azulado-500)" />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-25 justify-end">
                <Button className="button-blue">Limpar</Button>
                <Button className="button-primary ml-10">Filtar</Button>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className="header-box">
            <div className="search-box flex flex-row justify-between align-center">
              <div className="flex flex-row justify-start align-center">
                <div className="input-happy">
                  <Input bordered={false} onChange={onChangePesquisa} value={state?.filtro?.busca} />
                  {state?.filtro?.busca?.length > 0 && <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={onRemoverBuscaClick}>
                    <Unicons.UilTimesCircle size="15" color="var(--cinza-azulado)" />
                  </div>}
                  <Unicons.UilSearch size="20" color="var(--cinza-azulado-500)" />
                </div>
                <div className="total flex flex-row align-center">
                  <h6>Total de Resultados</h6>
                  <p>{totalLeadsResultado}/{totalLeads} Leads</p>
                </div>
              </div>
              <Button className="button-primary"
                onClick={onChangeModal}
              >
                {wordButton(modal)}
              </Button>
            </div>
            {filtros &&
              <>
                <div className="flex flex-row justify-content-between align-center">
                  <div className="flex flex-row justify-start align-center flex-fill">
                    <Button
                      className={estilo === 'button-bronce' ? 'button-bronce mr-20' : 'button-blue mr-20'}
                      icon={<TableChartIcon className="mr-10" />}
                      style={{ width: '200px' }}
                      onClick={onFunil}
                    >
                      Visão em Funil
                    </Button>
                    <Button
                      className={estilo === 'button-blue' ? 'button-bronce' : 'button-blue'}
                      icon={<FormatListBulletedIcon className="mr-10" />}
                      style={{ width: '200px' }}
                      onClick={onList}
                    >
                      Visão em Lista
                    </Button>
                  </div>
                  <div className={`flex flex-row justify-start align-center`}>
                    <Checkboxtag
                      tipo='novo'
                      state={state}
                      onFiltroRetornoClick={onFiltroRetornoClick}
                      disabled={state?.filtro?.atrasado || state?.filtro?.hoje || state?.filtro?.futuro}
                    />
                    <Checkboxtag
                      tipo='atrasado'
                      state={state}
                      onFiltroRetornoClick={onFiltroRetornoClick}
                      disabled={state?.filtro?.novo}
                    />
                    <Checkboxtag
                      tipo='hoje'
                      state={state}
                      onFiltroRetornoClick={onFiltroRetornoClick}
                      disabled={state?.filtro?.novo}
                    />
                    <Checkboxtag
                      tipo='futuro'
                      state={state}
                      onFiltroRetornoClick={onFiltroRetornoClick}
                      disabled={state?.filtro?.novo}
                    />
                    {!flPossuiFiltro && <div onClick={onChangeModalFilter} className="filter-box flex justify-center align-center">
                      <Unicons.UilFilter color="var(--white)" size={20} />
                    </div>}
                    {flPossuiFiltro && <div onClick={limparFiltros} className="filter-box flex justify-center align-center">
                      <Unicons.UilFilterSlash color="var(--white)" size={20} />
                    </div>}
                  </div>
                </div>

                <ModalFilter open={isOpenFilter} onClose={onCloseFilter} />
              </>
            }
          </div>
          {modal === 'lead' && <ModalLeadNew open={isOpenModal} onClose={onCloseModal} />}
          {modal === 'equipe' && <ModalEquipeNew open={isOpenModal} onClose={onCloseModal} />}
          {modal === 'users' && <ModalUsuarioNew open={isOpenModal} onClose={onCloseModal} />}
          {modal === 'cargo' && <ModalCargoNew open={isOpenModal} onClose={onCloseModal} />}
        </>
      }
    </>
  );
}
