import { Button, Input, InputNumber, Select, notification } from 'antd';
import { useEffect, useMemo, useState } from "react";
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";
import { IAreaAtuacaoCleanDTO, IEnderecoCleanDTO, IEstadoCleanDTO, IModalidadeNegocioCleanDTO, IMunicipioCleanDTO, IPaisCleanDTO } from "../../models/happy-code-api.model";
import { Predicate } from '../../models/predicate.model';
import { useAreaAtuacaoService } from '../../services/area-atuacao';
import { useEstadoService } from '../../services/estado.service';
import { useLeadService } from "../../services/lead.service";
import { useLogradouroService } from '../../services/logradouro.service';
import { useModalidadeNegocioService } from '../../services/modalidade-negocio';
import { useMunicipioService } from "../../services/municipio";
import { usePaisService } from '../../services/pais.service';
import { currencyFormatter, currencyParser } from '../../util/format';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const ModalLeadNew = (props: Props) => {
    const defaultValue = {
        dsNomeLead: null,
        cdCpfCnpj: null,
        cdRg: null,
        cdProfissao: null,
        cdDataNascimento: null,
        nmCidadeInteresse: null,
        cdEmail: null,
        cdDddCelular: null,
        cdCelular: null,
        origem: null,
        campanha: null,
        vlCapitalInvestimento: null,
        dsModelo: null,
        dsAreaAtuacao: null,
        endereco: {} as IEnderecoCleanDTO,
    }

    const { open, onClose } = props;
    const { Option } = Select;
    const { setIsGlobalLoading } = useGlobalContext();
    const { state, unidade, refresh } = useLeadContext();
    const [body, setBody] = useState(defaultValue);
    const leadService = useLeadService();
    const municipioService = useMunicipioService();
    const [municipioList, setMunicipioList] = useState<IMunicipioCleanDTO[]>()
    const [paisList, setPaisList] = useState<IPaisCleanDTO[]>();
    const [estadoList, setEstadoList] = useState<IEstadoCleanDTO[]>();
    const [modalidadeNegocioList, setModalidadeNegocioList] = useState<IModalidadeNegocioCleanDTO[]>();
    const [areaAtuacaoList, setAreaAtuacaoList] = useState<IAreaAtuacaoCleanDTO[]>();

    const paisService = usePaisService();
    const estadoService = useEstadoService();
    const logradouroService = useLogradouroService();
    const modalidadeNegocioService = useModalidadeNegocioService();
    const areaAtuacaoService = useAreaAtuacaoService();

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const emailValido = useMemo(() => {
        if (body?.cdEmail === null || body?.cdEmail === undefined) return false;

        return validateEmail(body?.cdEmail);
    }, [body?.cdEmail])

    function handleChange(value: any) {
        return;
    }

    const [isOpen, setIsOpen] = useState(false);

    const onChangeModal = () => {
        if (isOpen === true) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const onSalvarClick = () => {
        const novoBody = {
            ...body,
            origem: body.origem ? { id: body.origem } : null,
            campanha: body.campanha ? { id: body.campanha } : null,
            estagio: state?.boards?.[0]?.id ? { id: state?.boards?.[0]?.id } : null,
            unidade: unidade.id ? { id: unidade.id } : null,
            tipo: {
                id: 1,
            },
            endereco: body?.endereco?.id ? body?.endereco : null,
        }

        setIsGlobalLoading(true);

        return leadService.create(novoBody)
            .then((response) => {
                if (response?.status >= 200 && response?.status <= 299) {
                    return refresh();
                }

                throw new Error('Falha ao cadastrar o lead.');
            }).then((_) => {
                notification.success({ description: 'Lead cadastrado com sucesso!', type: 'success', message: 'Sucesso!', placement: 'bottomRight' });
                onClose();
                setBody({ ...defaultValue })
            }).finally(() => {
                setIsGlobalLoading(false)
            })

    }

    const onCepChange = (cep: string) => {
        if (cep.length !== 8) {
            return;
        }
        setIsGlobalLoading(true);
        const predicateLogradouro = new Predicate(10);
        predicateLogradouro.addOption('cep', cep);
        logradouroService.findCustom(predicateLogradouro)
            .then(({ data }) => {
                if (data && data.length > 0) {
                    const logradouro = data[0];
                    const endereco: IEnderecoCleanDTO = {} as IEnderecoCleanDTO;
                    endereco.cep = logradouro?.cep;
                    endereco.bairro = logradouro?.bairroNome;
                    endereco.cidade = logradouro?.cidadeNome;
                    endereco.logradouro = logradouro?.nomeCompleto;
                    endereco.nomeUF = logradouro?.cidadeEstadoNome;
                    endereco.paisId = logradouro.cidadeEstadoPaisId;
                    endereco.numero = body?.endereco?.numero;

                    setBody({ ...body, endereco: endereco });
                } else {
                    notification.warning({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
                }
            })
            .finally(() => setIsGlobalLoading(false));
    }

    const findPais = () => {
        const predicate = new Predicate();
        return paisService.findCleanList(predicate)
            .then(({ data }) => {
                setPaisList(data);
            })
    }

    const findEstados = () => {
        const predicateEstado = new Predicate(100);
        return estadoService.findClean(predicateEstado).then(({ data }) => {
            setEstadoList(data.content);
        })
    }

    const findMunicipio = () => {
        municipioService.findCleanList()
            .then(response => {
                setMunicipioList(response.data)
            });
    }

    const findModalidadeNegocio = () => {
        const predicate = new Predicate();
        modalidadeNegocioService.findClean(predicate)
            .then(({ data }) => {
                setModalidadeNegocioList(data.content);
            })
    }

    const findAreaAtuacao = () => {
        const predicate = new Predicate();
        areaAtuacaoService.findClean(predicate)
            .then(({ data }) => {
                setAreaAtuacaoList(data.content);
            })
    }


    const onClose1 = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        findPais();
        findEstados();
        findMunicipio();
        findAreaAtuacao();
        findModalidadeNegocio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const submitValidationFields = () => {
        if (!body?.dsNomeLead) {
            document.getElementById('dsNomeLead')?.focus();
            return notification.warning({
                description: `Verifique o campo nome do lead. É necessário informar um nome para o Lead.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        // if (!body?.cdCpfCnpj) {
        //     document.getElementById('cdCpfCnpj')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo Cpf. É necessário informar o Cpf.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.cdRg) {
        //     document.getElementById('cdRg')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo RG. É necessário informar o RG.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.cdDataNascimento) {
        //     document.getElementById('cdDataNascimento')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo data de nascimento. É necessário informar a data de nascimento.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        if (!body?.cdDddCelular) {
            document.getElementById('cdDddCelular')?.focus();
            return notification.warning({
                description: `Verifique o campo DDD. É necessário informar o DDD.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (!body?.cdCelular) {
            document.getElementById('cdCelular')?.focus();
            return notification.warning({
                description: `Verifique o campo celular. É necessário informar o celular.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (!body?.cdEmail) {
            document.getElementById('cdEmail')?.focus();
            return notification.warning({
                description: `Verifique o campo e-mail. É necessário informar o e-mail.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        // if (!body?.endereco?.cidade) {
        //     document.getElementById('cidade')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo município. É necessário informar o munícipio.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.endereco?.cidade) {
        //     document.getElementById('cidade')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo município. É necessário informar o munícipio.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.endereco?.nomeUF) {
        //     document.getElementById('nomeUF')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo Estado. É necessário informar o Estado.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.endereco?.logradouro) {
        //     document.getElementById('logradouro')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo logradouro. É necessário informar o logradouro.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }

        // if (!body?.nmCidadeInteresse) {
        //     document.getElementById('nmCidadeInteresse')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo cidade de interesse. É necessário informar a cidade de interesse.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        // if (!body?.dsModelo) {
        //     document.getElementById('dsModelo')?.focus();
        //     return notification.warning({
        //         description: `Verifique o campo modelo. É necessário informar o modelo.`,
        //         type: 'warning',
        //         message: 'Atenção!',
        //         placement: 'bottomRight'
        //     });
        // }
        if (!body?.origem) {
            document.getElementById('origem')?.focus();
            return notification.warning({
                description: `Verifique o campo da origem. É necessário informar o origem.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (body?.origem === 2 && !body?.campanha) {
            document.getElementById('campanha')?.focus();
            return notification.warning({
                description: `Verifique o campo da campanha. É necessário informar a campanha.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        onSalvarClick();
    }

    if (!open) return null

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className="flex flex-column justify-start align-start modal">
                    <Button onClick={onClose} className="button-x">x</Button>
                    <h3 >Cadastrar novo lead</h3>

                    <div className="text-over-input">
                        <label htmlFor='dsNomeLead'>Nome Completo</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input id='dsNomeLead' placeholder='Nome' bordered={false} onChange={(e) => setBody((body) => ({ ...body, dsNomeLead: e.target.value }))} />
                    </div>

                    {/* <div className="flex flex-row">
                        <div className="w-50 mr-5">
                            <div className="text-over-input">
                                <label htmlFor='cdCpfCnpj'>Cpf</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Input
                                    id='cdCpfCnpj'
                                    placeholder='Cpf'
                                    bordered={false}
                                    defaultValue={formatCpf(body?.cdCpfCnpj)}
                                    value={formatCpf(body?.cdCpfCnpj)}
                                    onChange={(e) => setBody((body) => ({ ...body, cdCpfCnpj: e.target.value }))} />
                            </div>
                        </div>
                        <div className="w-50">
                            <div className="text-over-input">
                                <label htmlFor='cdRg'>Rg</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Input
                                    id='cdRg'
                                    placeholder='Rg'
                                    bordered={false}
                                    maxLength={12}
                                    defaultValue={body?.cdRg}
                                    value={body?.cdRg}
                                    onChange={(e) => setBody((body) => ({ ...body, cdRg: e.target.value }))} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="text-over-input">
                        <label htmlFor='cdDataNascimento'>Data de nascimento</label>
                    </div>
                    <div className="input-modal mb-20">
                        <DatePicker
                            id='cdDataNascimento'
                            style={{ width: "100%" }}
                            placeholder='Data de nascimento'
                            bordered={false}
                            allowClear={true}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => setBody((body) => ({ ...body, cdDataNascimento: e }))} />
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='cdProfissao'>Profissão</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input id='cdProfissao' placeholder='Profissão' bordered={false} onChange={(e) => setBody((body) => ({ ...body, cdProfissao: e.target.value }))} />
                    </div> */}


                    <div className="text-over-input">
                        <label htmlFor='cdEmail'>Email</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input
                            id='cdEmail'
                            placeholder='Email'
                            bordered={false}
                            onChange={(e) => setBody((body) => ({ ...body, cdEmail: e.target.value }))}
                        />
                    </div>
                    {!emailValido && body?.cdEmail?.length >= 0 && <small>Digite um e-mail válido</small>}


                    <div className="flex flex-row">
                        <div className="w-25 mr-5">
                            <div className="text-over-input">
                                <label htmlFor='cdDddCelular'>DDD</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <div>
                                        <Input
                                            id='cdDddCelular'
                                            style={{ width: "100%" }}
                                            type="tel"
                                            placeholder='DDD'
                                            bordered={false}
                                            maxLength={2}
                                            onChange={(e) => setBody((body) => ({ ...body, cdDddCelular: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-75">
                            <div className="text-over-input">
                                <label htmlFor='cdCelular'>Celular</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <Input
                                        id='cdCelular'
                                        maxLength={9}
                                        placeholder='Celular'
                                        bordered={false}
                                        onChange={(e) => setBody((body) => ({ ...body, cdCelular: e.target.value }))} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="w-25 mr-5">
                            <div className="text-over-input">
                                <label htmlFor='cdDddTelefone'>DDD</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <div>
                                        <Input
                                            id='cdDddTelefone'
                                            style={{ width: "100%" }}
                                            type="tel"
                                            placeholder='DDD'
                                            bordered={false}
                                            maxLength={2}
                                            onChange={(e) => setBody((body) => ({ ...body, cdDddTelefone: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-75">
                            <div className="text-over-input">
                                <label htmlFor='cdTelefone'>Telefone</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <Input
                                        id='cdTelefone'
                                        maxLength={9}
                                        placeholder='Telefone'
                                        bordered={false}
                                        onChange={(e) => setBody((body) => ({ ...body, cdTelefone: e.target.value }))} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex flex-row" style={{ justifyContent: "center", alignItems: "center" }}>
                        <span className="line" />
                        <h4 className="ml-10 mr-10 mb-0">Endereço</h4>
                        <span className="line" />
                    </div> */}

                    {/* <div className="text-over-input">
                        <label htmlFor='cep'>CEP</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input id='cep' placeholder='Cep' bordered={false} onChange={(e) => onCepChange(e.target.value)} />
                    </div>
                    <div className="text-over-input">
                        <label htmlFor='pais'>País</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id='pais'
                            style={{ width: '100%' }}
                            placeholder="País"
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue={body?.endereco?.paisId}
                            value={body?.endereco?.paisId}
                            onChange={(e) => setBody({ ...body, endereco: { ...body.endereco, paisId: e } })}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {paisList && paisList.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.nome}>{item?.nome}</Option>
                            )}
                        </Select>
                    </div> */}

                    {/* <div className="flex flex-row">
                        <div className=" w-50 mr-5">
                            <div className="text-over-input">
                                <label htmlFor='nomeUF'>Estado</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Select
                                    id='nomeUF'
                                    style={{ width: '100%' }}
                                    placeholder="Estado"
                                    bordered={false}
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    defaultValue={body?.endereco?.nomeUF}
                                    value={body?.endereco?.nomeUF}
                                    onChange={(e) => setBody({ ...body, endereco: { ...body.endereco, nomeUF: e } })}
                                    filterOption={(input, option) =>
                                        option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                    }>
                                    {estadoList && estadoList.map?.((item) =>
                                        <Option key={item?.id} value={item?.nome} text={item?.nome}>{item?.nome}</Option>
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div className=" w-50">
                            <div className="text-over-input">
                                <label htmlFor='cidade'>Município</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Select
                                    id='cidade'
                                    style={{ width: '100%' }}
                                    placeholder="Munícipio"
                                    bordered={false}
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    defaultValue={body?.endereco?.cidade}
                                    value={body?.endereco?.cidade}
                                    onChange={(e) => setBody({ ...body, endereco: { ...body.endereco, cidade: e } })}
                                    filterOption={(input, option) =>
                                        option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                    }>
                                    {municipioList && municipioList.map?.((item) =>
                                        <Option key={item?.id} value={item?.nome} text={item?.nome}>{item?.nome}</Option>
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>


                    <div className="flex flex-row">
                        <div className=" w-75 mr-5">
                            <div className="text-over-input">
                                <label htmlFor='logradouro'>Logradouro</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Input
                                    id='logradouro'
                                    placeholder='Logradouro'
                                    bordered={false}
                                    defaultValue={body?.endereco?.logradouro}
                                    value={body?.endereco?.logradouro}
                                    onChange={(e) => setBody({ ...body, endereco: { ...body.endereco, logradouro: e.target.value } })}
                                />
                            </div>
                        </div>

                        <div className=" w-25">
                            <div className="text-over-input">
                                <label htmlFor='numero'>Número</label>
                            </div>
                            <div className="input-modal mb-20">
                                <Input
                                    id='numero'
                                    placeholder='Número'
                                    bordered={false}
                                    defaultValue={body?.endereco?.numero}
                                    value={body?.endereco?.numero}
                                    onChange={(e) => setBody({ ...body, endereco: { ...body.endereco, numero: e.target.value } })}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="flex flex-row" style={{ justifyContent: "center", alignItems: "center" }}>
                        <span className="line" />
                        <h4 className="ml-10 mr-10 mb-0" style={{ whiteSpace: "nowrap" }}>Dados da Proposta</h4>
                        <span className="line" />
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='nrCapitalInvestimento'>Capital de investimento</label>
                    </div>
                    <div className="input-modal mb-20">
                        <InputNumber
                            id='nrCapitalInvestimento'
                            className="w-100"
                            placeholder='Capital de investimento'
                            bordered={false}
                            min={0}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                            onChange={(e) => setBody((body) => ({ ...body, nrCapitalInvestimento: e }))}
                        />
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='nmCidadeInteresse'>Cidade de Interesse</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id='nmCidadeInteresse'
                            style={{ width: '100%' }}
                            placeholder="Cidade de interesse"
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="Selecione"
                            value={body?.nmCidadeInteresse}
                            onChange={(e) => setBody((body) => ({ ...body, nmCidadeInteresse: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {municipioList && municipioList?.map((item) =>
                                <Option key={item?.id} value={item?.nome} text={item?.nome}>{item?.nome}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='dsModelo'>Modelo</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id='dsModelo'
                            style={{ width: '100%' }}
                            placeholder="Modelo"
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="Selecione"
                            value={body?.dsModelo ?? "Selecione"}
                            onChange={(e) => setBody((body) => ({ ...body, dsModelo: e }))}
                            filterOption={(input, option) =>
                                option && option?.text.toLowerCase().includes(input.toLowerCase())
                            }>
                            {modalidadeNegocioList && modalidadeNegocioList?.map((item) =>
                                <Option key={item?.id} value={item?.descricao} text={item?.descricao}>{item?.descricao}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='dsAreaAtuacao'>Área de Atuação</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id='dsAreaAtuacao'
                            style={{ width: '100%' }}
                            placeholder="Área de atuação"
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="Selecione"
                            value={body?.dsAreaAtuacao ?? "Selecione"}
                            onChange={(e) => setBody((body) => ({ ...body, dsAreaAtuacao: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {areaAtuacaoList && areaAtuacaoList?.map((item) =>
                                <Option key={item?.id} value={item?.descricao} text={item?.descricao}>{item?.descricao}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='origem'>Selecione uma Origem</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id='origem'
                            style={{ width: '100%' }}
                            placeholder={"Selecione"}
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="todos"
                            value={body?.origem}
                            onChange={(e) => setBody((body) => ({ ...body, origem: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {state?.leadOrigens?.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.dsLeadOrigem}>{item?.dsLeadOrigem}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor='campanha'>Campanha</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id="campanha"
                            style={{ width: '100%' }}
                            placeholder={"Selecione"}
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="todos"
                            value={body?.campanha ?? "todos"}
                            onChange={(e) => setBody((body) => ({ ...body, campanha: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {state?.campanhas?.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.dsLeadCampanha}>{item?.dsLeadCampanha}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="flex flex-row justify-end w-100">
                        <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
                        <Button onClick={submitValidationFields} className="button-primary">Salvar</Button>
                    </div>
                </div>
                <ModalResposta
                    open={isOpen}
                    onClose={onClose1}
                    status="Sucesso!"
                    text="As informações do Lead foram salvas com sucesso."
                    link=""
                />
            </div>
        </>
    )
}
