import { useState } from "react";
import * as Unicons from '@iconscout/react-unicons';
import { ModalEquipeDetalhe } from "../Modal/equipedetalhe";
import { ModalEquipeEditar } from "../Modal/equipeeditar";
import { ModalDeletar} from '../Modal/deletar';

interface Props{
  item:string[],
}

export const Itemequipe = (props:Props) => {

  const {item} = props;

  const [isOpen, setIsOpen] = useState(false);
  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }
  const onClose = () => {
    setIsOpen(false)
  }

  const [isOpen1, setIsOpen1] = useState(false);
  const onChangeModal1 = () => {
    if(isOpen===true){
      setIsOpen1(false)
    }else{
      setIsOpen1(true)
    }
  }
  const onClose1 = () => {
    setIsOpen1(false)
  }

  const [isOpen2, setIsOpen2] = useState(false);
  const onChangeModal2 = () => {
    if(isOpen===true){
      setIsOpen2(false)
    }else{
      setIsOpen2(true)
    }
  }
  const onClose2 = () => {
    setIsOpen1(false)
  }

  return (
    <>
      <div className="row-item flex flex-row justify-strat align-center">
        <div className="item w-5 flex flex-row justify-center align-center">
          <Unicons.UilPen style={{cursor:'pointer'}} onClick={onChangeModal1} size="20" color="var(--cinza-azulado-500)" />
          <Unicons.UilTrash style={{cursor:'pointer'}} onClick={onChangeModal2} size="20" color="var(--cinza-azulado-500)" />
        </div>
        <div className="item w-20 flex align-center" onClick={onChangeModal}>
          <span>{item[0]}</span>
        </div>
        <div className="item w-15 flex align-center" onClick={onChangeModal}>
          <span>{item[1]}</span>
        </div>
        <div className="item w-15 flex align-center" onClick={onChangeModal}>
          <span>{item[2]} página (s)</span>
        </div>
        <div className="item w-25 flex align-center" onClick={onChangeModal}>
          <span>{item[3]} usuários</span>
        </div>
      </div>
      <ModalEquipeDetalhe
        open={isOpen}
        onClose={onClose}
      />
      <ModalEquipeEditar
        open={isOpen1}
        onClose={onClose1}
      />
      <ModalDeletar
          open={isOpen2}
          onClose={onClose2}
          status="Deletar equipe"
          text="Deseja mesmo remover essa equipe e seus usuários?"
          link="equipes"
        />
    </>
  )
}
