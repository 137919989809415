/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState } from "react";

//Tipando as Props do contexto
interface PropsUserContext {
  isGlobalLoading: boolean;
 
  setIsGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
   
};

//Valor default do contexto
const DEFAULT_VALUE = {
  isGlobalLoading: false,
   
  setIsGlobalLoading: () => { }, //função de inicialização
   
};

const nameStorageLoginUnidade = '@HappyCode/login/unidade';

//criando nosso contexto UserContext
const GlobalContext = createContext<PropsUserContext>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const GlobalContextProvider = ({ children }: any) => {

  const [isGlobalLoading, setIsGlobalLoading] = useState(
    DEFAULT_VALUE.isGlobalLoading
  );
  return (
    <GlobalContext.Provider
      value={{
        isGlobalLoading,
        setIsGlobalLoading,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = (): PropsUserContext => {
  const context = useContext(GlobalContext);
  if (!context)
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    );
  return context;
};
