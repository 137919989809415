
import { IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useMunicipioService = () => {
	const resourceURL = '/municipio';

	return {
		...baseFind<IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO>(resourceURL),
		...baseFindList<IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO>(resourceURL),
		...baseFindById<IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO>(resourceURL),
		...baseCreate<IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO>(resourceURL),
		...baseUpdate<IMunicipioCleanDTO, IMunicipioDTO, IMunicipioFullDTO>(resourceURL),
	};
};
