import { ICrmeLeadCampanhaCleanDTO, ICrmeLeadCampanhaDTO, ICrmeLeadCampanhaFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useCrmeLeadCampanhaService = () => {
	const resourceURL = 'crme/lead-campanha';

	return {
		...baseFindList<ICrmeLeadCampanhaCleanDTO, ICrmeLeadCampanhaDTO, ICrmeLeadCampanhaFullDTO>(resourceURL),
	};
};
