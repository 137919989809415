import { useState } from "react";
import * as Unicons from '@iconscout/react-unicons';
import { ModalCargoDetalhe } from "../Modal/cargodetalhe";
import { Switch } from 'antd';

interface Props{
  item:any[],
}

export const Itemcargo = (props:Props) => {

  const {item} = props;

  const [isOpen, setIsOpen] = useState(false);
  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }
  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className="row-item flex flex-row justify-strat align-center">
        <div style={{width:60}} className="item flex flex-row justify-center align-center">
          <Unicons.UilPen style={{cursor:'pointer'}} onClick={onChangeModal} size="20" color="var(--cinza-azulado-500)" />
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[0]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[1]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[2]} </span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[3]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[4]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[5]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <span>{item[6]}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <Switch checked={item[7]} /> <span style={{marginLeft:15}}>{(item[7]===true) ? 'Sim' : 'Não'}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <Switch checked={item[8]} /> <span style={{marginLeft:15}}>{(item[7]===true) ? 'Sim' : 'Não'}</span>
        </div>
        <div style={{width:250}} className="item flex align-center">
          <Switch checked={item[9]} /> <span style={{marginLeft:15}}>{(item[7]===true) ? 'Sim' : 'Não'}</span>
        </div>
      </div>
      <ModalCargoDetalhe
        open={isOpen}
        onClose={onClose}
      />
    </>
  )
}
