import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Button, Input } from "antd";
import { useState } from "react";
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";
import { useLeadAnotacaoService } from "../../services/lead-anotacao.service";
import { useNotificationService } from "../../services/notification.service";

export const AnotacaoLead = () => {

  const [dsAnotacao, setDsAnotacao] = useState<any>();
  const {  setIsGlobalLoading } = useGlobalContext();
  const { leadSelecionado, fetchHistoricos } = useLeadContext();
  const leadAnotacaoService = useLeadAnotacaoService();
  const notification = useNotificationService();

  const criarAnotacao = () => {
    const body = {
      dsLeadAnotacao: dsAnotacao,
      dsTipoLeadAnotacao: 'Anotação',
      lead: {id:leadSelecionado.id},
    };

    setIsGlobalLoading(true)
    leadAnotacaoService.create(body).then((response) => {
      if (response?.status >= 200 && response?.status <= 299) {
        fetchHistoricos();
        setDsAnotacao(null);
        notification({
          description: 'Anotação cadastrada com sucesso!',
          type: 'success',
          message: 'Sucesso!'
        });
        return;
      }

      notification({
        description: 'Erro ao criar anotação!',
        type: 'error',
        message: 'Erro!'
      });
    }).finally(() => {
      setIsGlobalLoading(false);
    })
  }

  return (
    <>
      <div className="flex justify-between gap-20 mb-20">
        <div className="flex align-center gap-10">
          <NoteAddIcon />
          <h1 id="sem-margin">Criar anotação</h1>
        </div>
        <Button className="button-bronce" onClick={criarAnotacao}>
          Criar anotação
        </Button>
      </div>
      <Input.TextArea
        rows={5}
        value={dsAnotacao || ''}
        placeholder="Descreva a anotação"
        onChange={(e) => setDsAnotacao(e.target.value as any)}
        maxLength={1000} />
      <p className="mt-10 text-area-p">{dsAnotacao?.length || 0}/1000</p>
    </>
  )
}
