import { AxiosResponse } from 'axios';
import {
	ILogradouroCleanDTO,
	ILogradouroCustomDTO,
	ILogradouroDTO,
	ILogradouroFullDTO,
} from '../models/happy-code-api.model';
import { formatHttpParamsList, Predicate } from '../models/predicate.model';
import http from './../helpers/http-common';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useLogradouroService = () => {
	const resourceURL = 'logradouro';

	return {
		...baseFindList<ILogradouroCleanDTO, ILogradouroDTO, ILogradouroFullDTO>(
			resourceURL
		),
		...baseFind<ILogradouroCleanDTO, ILogradouroDTO, ILogradouroFullDTO>(
			resourceURL
		),
		findCustom: (
			predicate: Predicate = new Predicate()
		): Promise<AxiosResponse<ILogradouroCustomDTO[]>> =>
			http.get(`${resourceURL}/custom`, formatHttpParamsList(predicate)),
	};
};
