import {
	IEstadoCleanDTO,
	IEstadoDTO,
	IEstadoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useEstadoService = () => {
	const resourceURL = 'estado';

	return {
		...baseFind<IEstadoCleanDTO, IEstadoDTO, IEstadoFullDTO>(resourceURL),
		...baseFindList<IEstadoCleanDTO, IEstadoDTO, IEstadoFullDTO>(resourceURL),
	};
};