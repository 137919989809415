import { useState } from "react";
import { HeaderTarefas } from "../../components/HeaderTarefas";
import { ModalLeadNew } from "../../components/Modal/leadnew";
import { Sidebar } from "../../components/Sidebar";
import { List } from "./list";

export const TarefasLista = () => {
  const [isOpenLead, setIsOpenLead] = useState(false);
  const [styleButtons, setStyleButtons] = useState('button-bronce');

  // useEffect(() => {

  // }, []);

  const onCloseLead = () => { setIsOpenLead(false) }

  function handleFunil(event: any) {
    setStyleButtons('button-bronce');
  }

  function handleList(event: any) {
    setStyleButtons('button-blue')
  }

  return (
    <>
      <div className="container-happy">
        <Sidebar tarefas={true} />
        <div className="dashboard">
          <HeaderTarefas filtros={true}
            modal="tarefa"
            onFunil={handleFunil}
            onList={handleList}
            estilo={styleButtons}
          />
          <List />
        </div>
      </div>
      <ModalLeadNew
        open={isOpenLead}
        onClose={onCloseLead}
      />
    </>
  );
}