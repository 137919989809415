import http from '../helpers/http-common';
import {
	ICrmeLeadCleanDTO,
	ICrmeLeadCustomDTO,
	ICrmeLeadDTO,
	ICrmeLeadFullDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useLeadService = () => {
	const resourceURL = 'crme/lead';

	const alterarStatus = (idLead: number, cdEstagio: string) => {
		return http.put(`${resourceURL}/${idLead}/estagio/${cdEstagio}`)
	}

	const findFullByIdCustom = (idLead: number) => {
		return http.get(`${resourceURL}/${idLead}/custom`)
	}
	
	const patch = (idLead: number, body: any, idAluno?: number) => {
		return http.patch(`${resourceURL}/${idLead}/${idAluno || ''}`, body)
	}

	const findCustomIndex = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-custom-index`, formatHttpParams(predicate))
	}


	const findCustomIndexById = (body) => {
		return http.get(`${resourceURL}/${body.id}/custom`)
	}

	const alterarStatusByNrOrdem = (idLead: number, nrOrdemEstagio: number) => {
		return http.put(`${resourceURL}/${idLead}/estagio/etapa/${nrOrdemEstagio}`)
	}

	return {
		...baseCreate<ICrmeLeadCleanDTO, ICrmeLeadDTO, ICrmeLeadFullDTO>(resourceURL),
		...baseFindById<ICrmeLeadCleanDTO, ICrmeLeadDTO, ICrmeLeadFullDTO>(
			resourceURL
		),
		...baseFind<ICrmeLeadCleanDTO, ICrmeLeadCustomDTO, ICrmeLeadFullDTO>(
			resourceURL
		),
		...baseDelete(resourceURL),
		alterarStatus,
		findFullByIdCustom,
		patch,
		findCustomIndex,
		findCustomIndexById,
		alterarStatusByNrOrdem,
	};
};
