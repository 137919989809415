import * as Unicons from '@iconscout/react-unicons';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Button, Input } from "antd";
import { format } from "date-fns";
import { useState } from "react";
import { ICrmeLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { getStatusLead } from "../../util/lead.util";
import { statusWord, styleNote } from "../Lead";

interface Props {
  id: number,
  tarefa: ICrmeLeadTarefaCustomCleanDTO,
  onAction: (e: any) => void,
  checked?: boolean,
}

export const etapaWord = (etapa: string | undefined) => {
  switch (etapa) {
    case 'LEA':
      return 'Lead';
    case 'CON':
      return 'Contatados';
    case 'AGE':
      return 'Agendados';
    case 'REU':
      return 'Reunião';
    case 'NGC':
      return 'Em Negociação';
    case 'COF':
      return 'COF Assinada';
    case 'CTF':
      return 'Contrato Fechado';
    case 'DES':
      return 'Descartado';
    default:
      return 'Contrato Fechado';
  }
}

export const etapaIndex = (etapa: string | undefined) => {
  switch (etapa) {
    case 'LEA':
      return 0;
    case 'CON':
      return 1;
    case 'AGE':
      return 2;
    case 'REU':
      return 3;
    case 'NGC':
      return 4;
    case 'COF':
      return 5;
    case 'CTF':
      return 6;
    case 'DES':
      return 7;
    default:
      return 6;
  }
}

export const ItemTarefalista = ({
  id,
  tarefa,
  checked,
  onAction,
}: Props) => {

  const [isStyle, setIsStyle] = useState('row-item');
  const [openModalNewNote, setOpenModalNewNote] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openModalTarefa, setOpenModalTarefa] = useState(false);
  const status = getStatusLead(tarefa);

  const handleOpenModalNewNote = () => {
    setOpenModalNewNote(true);
    setOpenModalTarefa(false);
    setOpenModalNote(false);
  }
  const handleCloseModalNewNote = () => setOpenModalNewNote(false);

  const handleOpenModalNote = () => {
    setOpenModalNote(true);
    setOpenModalNewNote(false);
    setOpenModalTarefa(false);
  }
  const handleCloseModalNote = () => setOpenModalNote(false);

  const handleOpenModalTarefa = () => {
    setOpenModalTarefa(true);
    setOpenModalNewNote(false);
    setOpenModalNote(false);
  }
  const handleCloseModalTarefa = () => setOpenModalTarefa(false);

  const onChangeStyle = () => {
    if (checked) {
      setIsStyle('row-item-select')
    } else {
      setIsStyle('row-item')
    }
  }

  return (
    <>

      <li key={id} style={{ width: '100%' }} className={`${checked ? 'row-item-select' : 'row-item'} flex flex-row justify-strat align-center`} >
        <div style={{ width: 30 }} className="item flex flex-row justify-center align-center gap-10">
          {/* <Checkbox
            sx={{
              color: 'var(--cinza-azulado) !important',
              '&.Mui-checked': {
                color: 'var(--azul-happy) !important',
              },
            }}
            // onChange={onChangeStyle}
            onClick={onAction}
            value={id}
            checked={checked}
          /> */}

        </div>
        <div style={{ overflow: 'hidden', width: 250 }} className="item flex align-center">
          <p style={{ overflow: 'hidden' }}>{tarefa?.dsTipoTarefa}</p>
        </div>
        <div style={{ width: 250 }} className="item flex align-center">
          <div className={`tag-${status || 'novo'} flex flex-row justify-between align-center`}>
            <CheckBoxIcon style={{ color: styleNote(status) }} />
            <span style={{ marginLeft: 10 }}>{statusWord(status)}</span>
          </div>
        </div>
        <div style={{ width: 250 }} className="item flex align-center">
          <p>{tarefa.dhTarefa ? format(new Date(tarefa.dhTarefa), 'dd/MM/yyyy') + ' ' + format(new Date(tarefa.hrTarefa), 'HH:mm') : '--'}</p>
        </div>
        <div style={{ width: 250 }} className="item flex align-center">
          {tarefa?.nmResponsavel && <div className="circle-cinza flex flex-row justify-center align-center">
            <Unicons.UilUser style={{ cursor: 'pointer' }} size="20" color="var(--cinza-azulado-500)" />
          </div>}
          <p style={{ marginLeft: 15, color: '#78909C' }}>{tarefa?.nmResponsavel || '--'}</p>
        </div>
        <div style={{ width: 250, }} className="item flex align-center">
          <p style={{ color: '#78909C' }}>{tarefa?.nmLead}</p>
        </div>
        <div style={{ width: 250, }} className="item flex align-center">
          <p style={{ color: '#78909C' }}>{tarefa?.flConcluido ? "Sim" : "Não"}</p>
        </div>
      </li>

      <Modal
        open={openModalNewNote}
        onClose={handleCloseModalNewNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalNewNote}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalNewNote} />
              <h3>Criar Anotação</h3>
              <Input.TextArea name="" id="" rows={10}>
                Descreva sua anotação
              </Input.TextArea>
              <p>0/500</p>
              <div className="flex flex-row justify-between aling-center gap-10">
                {/* <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button> */}
                <Button className="button-blue" onClick={handleCloseModalNewNote}>Salvar</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openModalNote}
        onClose={handleCloseModalNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalNote}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalNote} />
              <h3>Anotação</h3>
              <div className="flex flex-row justify-between aling-center gap-10">
                {/* <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button> */}
                <Button className="button-blue" onClick={handleOpenModalNewNote}>Criar Nova</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openModalTarefa}
        onClose={handleCloseModalTarefa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={openModalTarefa}>
          <div className="modal-note">
            <div className="container-modal">
              <ClearIcon className="button-close" onClick={handleCloseModalTarefa} />
              <h3>Próxima tarefa</h3>
              <div className="flex flex-row justify-between align-center">
                <h5>Tipo:</h5>
                <h6>Marcar Reunião Remota</h6>
              </div>
              <div className="flex flex-row justify-between align-center">
                <h5>Data:</h5>
                <h6>16/05/2023</h6>
              </div>
              <div className="flex flex-row justify-between align-center">
                <h5>Horário</h5>
                <h6>10:45:12</h6>
              </div>
              <div className="">
                <h5 id="title-assunto">Assunto:</h5>
                <h6>Apresentação de proposta para lorem ipsum dolor sit amet consec tetur sit ut viverra</h6>
              </div>
              <div className="flex flex-row justify-between aling-center gap-10">
                <Button className="button-primary" onClick={handleOpenModalNote}>Ver Anotações</Button>
                <Button className="button-blue" onClick={handleOpenModalNewNote}>Criar Nova</Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
