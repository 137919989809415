import {
    IModalidadeNegocioCleanDTO,
    IModalidadeNegocioDTO,
    IModalidadeNegocioFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useModalidadeNegocioService = () => {
    const resourceURL = 'modalidade-negocio';

    return {
        ...baseFind<IModalidadeNegocioCleanDTO, IModalidadeNegocioDTO, IModalidadeNegocioFullDTO>(resourceURL),
        ...baseFindList<IModalidadeNegocioCleanDTO, IModalidadeNegocioDTO, IModalidadeNegocioFullDTO>(resourceURL),
        ...baseFindById<IModalidadeNegocioCleanDTO, IModalidadeNegocioDTO, IModalidadeNegocioFullDTO>(resourceURL),
    };
};