interface Props {
  isOpen:boolean;
}

export const Hamburger = (props: Props) => {

  const { isOpen  } = props;

  return(
    <>
      <div className="hamburger">
        <div className="burger burger1"></div>
        <div className="burger burger2"></div>
        <div className="burger burger3"></div>
      </div>

      <style>{`
        .burger1{
          transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'}
        }

        .burger2{
          transform: ${isOpen ? 'translatex(100%)' : 'translatex(0)'};
          opacity: ${isOpen ? '0' : '1' }
        }

        .burger3{
          transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'}
        }
      `}
      </style>
    </>
  )
}
