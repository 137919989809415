import { Modal } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import { AuthEnum } from "../enum/auth.enum";
import { Auth } from "../models/auth.model";
import { useNotificationService } from "../services/notification.service";
import { getObject } from "../util/store";

const http = axios.create({
    baseURL: process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API,
});

const token = () => {
    const auth = getObject(AuthEnum.TOKEN_KEY) as Auth;
    return auth?.access_token;
}

http.interceptors.request.use(
    (request: any) => {

        // if (!request.headers['Authorization']) {
        request.headers['Authorization'] = `Bearer ${token()}`;
        // }
        if (!request.headers['Content-Type']) {
            request.headers['Content-Type'] = 'application/json';
        }
        if (!request.headers['Accept']) {
            request.headers['Accept'] = 'application/json';
        }
        return request;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    response => response,
    error => {
        if (error && error.response) {
            const { status } = error.response;

            const notification = useNotificationService();
            switch (status) {
                case 401:
                    notification({ description: 'Usuário ou senha incorreto', type: 'warning', message: 'Falha ao Logar' })
                    break;
                case 404:
                    notification({ description: error.response.data?.message, type: 'warning', message: 'Aviso' })
                    break;
                case 400:
                    if (error.response.data.error_description === 'Bad credentials') {
                        notification({ description: 'Usuário ou senha incorreto', type: 'warning', message: 'Falha ao Logar' })
                        break;
                    }

                    if (error.config.url == "cobranca/pagarme")
                        Modal.error({ content: error.response.data?.message || 'Ops... Ocorreu um erro!', title: 'Problema no pagamento' })
                    else {

                        if (error.response.data?.message === "Problema nos parâmetros informados")

                            notification({
                                description: error.response.data.details[0]
                                    || 'Ops... Ocorreu um erro!',
                                type: 'error',
                                message: 'Erro'
                            })

                        else

                            notification({
                                description: error.response.data?.message
                                    || 'Ops... Ocorreu um erro!',
                                type: 'error',
                                message: 'Erro'
                            })

                    }

                    break;

                default:

                    if (error.config.url == "cobranca/pagarme")
                        Modal.error({ content: error.response.data?.message || 'Ops... Ocorreu um erro!', title: 'Problema no pagamento' })
                    else
                        notification({ description: error.response.data?.message || 'Ops... Ocorreu um erro!', type: 'error', message: 'Erro' })

                    break;
            }
        }
        return Promise.reject(error);
    }
);


export default http;

