import { useState } from 'react';
import { WhatsappLead } from './whatsapp';
import { EmailLead } from './email';
import { TabPanel, a11yProps } from './index';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styles } from '../../assets/sass/styles';

export const ComunicacaoLead = () =>{

  const [value, setValue] = useState(0);

  const handleChange = (event:any, newValue: number) => {
    setValue(newValue);
  };

  return(
    <>
      <div className="mb-20">
        <Box sx={styles.boxTabsComunication}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx:{backgroundColor:'transparent'}}}
            sx={styles.tabs}
          >
            <Tab icon={<EmailIcon
              sx={{ fontSize:16 }} />}
              iconPosition="start"
              label="Criar e-mail"
              {...a11yProps(0)}
            />
            <Tab icon={<WhatsAppIcon
              sx={{ fontSize:16 }} />}
              iconPosition="start"
              label="Criar whatsapp"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
      </div>
      <div id="tab-comunicacao">
        <TabPanel value={value} index={0} >
          <EmailLead />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WhatsappLead />
        </TabPanel>
      </div>
    </>
  )
}
