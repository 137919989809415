import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { HeaderLead } from "../../components/Header/lead";
import { BodyLead } from "./body";
import { HistoricoLead } from "./historico";
import { ComunicacaoLead } from "./comunicacao";
import { ContatosLead } from "./contatos";
import { ModeloNegocio } from "./modelo-negocio";
import { ArquivosLead } from "./arquivos";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styles } from "../../assets/sass/styles";
import { DadosLead } from "./dados-lead";
import { TarefasLead } from "./tarefas-lead";
import { useLeadContext } from "../../context/lead.context.service";
import { JuridicoLead } from "./juridico-lead";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const DetalheLead = () => {
  const { flModoEdicaoTarefa, setFlModoEdicaoTarefa} = useLeadContext();
  const { leadId } = useParams();
  const currentPath = window.location.pathname;
  const rotaTarefa = currentPath === `/lead/${leadId}/tarefa`;

  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  
  const query = useQuery();
  const [aba, setAba] = useState(query.get('aba'))

  useEffect(() => {
    if (rotaTarefa) {
      setValue(1);
    } 
    if (aba && aba === 'nova-tarefa') {
      setFlModoEdicaoTarefa(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aba])

  return (
    <div className="container-happy">
      <Sidebar crm={true} />
      <div className="dashboard">
        <HeaderLead leadId={leadId} />
        <Box sx={styles.boxTabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { backgroundColor: 'transparent', } }}
            sx={styles.tabs}
            variant="scrollable"
            scrollButtons="auto"
            className="disable-scrollbars"
          >
            <Tab label="Histórico" {...a11yProps(0)} />
            <Tab label="Tarefas" {...a11yProps(1)} />
            <Tab label="Ativos de comunicação" {...a11yProps(2)} />
            <Tab label="Dados do Lead" {...a11yProps(3)} />
            <Tab label="Contatos" {...a11yProps(4)} />
            <Tab label="Modelo de Negócio" {...a11yProps(5)} />
            <Tab label="Jurídico" {...a11yProps(6)} />
            <Tab label="Arquivos" {...a11yProps(7)} />
          </Tabs>
        </Box>
        <Box sx={{ width: '100%', paddingX: 5, overflowY: 'scroll' }}>
          <TabPanel value={value} index={0}>
            <BodyLead leadId={leadId} flExibirNotificacao={!flModoEdicaoTarefa}>
              <HistoricoLead />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BodyLead leadId={leadId}>
              <TarefasLead />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BodyLead leadId={leadId}>
              <ComunicacaoLead />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <BodyLead leadId={leadId}>
              <DadosLead />
            </BodyLead>
          </TabPanel>
    
          <TabPanel value={value} index={4}>
            <BodyLead leadId={leadId}>
              <ContatosLead />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <BodyLead leadId={leadId}>
              <ModeloNegocio />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <BodyLead leadId={leadId}>
              <JuridicoLead />
            </BodyLead>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <BodyLead leadId={leadId}>
              <ArquivosLead />
            </BodyLead>
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}
