import {
    IAreaAtuacaoCleanDTO,
    IAreaAtuacaoDTO,
    IAreaAtuacaoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useAreaAtuacaoService = () => {
    const resourceURL = 'area-atuacao';

    return {
        ...baseFind<IAreaAtuacaoCleanDTO, IAreaAtuacaoDTO, IAreaAtuacaoFullDTO>(resourceURL),
        ...baseFindList<IAreaAtuacaoCleanDTO, IAreaAtuacaoDTO, IAreaAtuacaoFullDTO>(resourceURL),
        ...baseFindById<IAreaAtuacaoCleanDTO, IAreaAtuacaoDTO, IAreaAtuacaoFullDTO>(resourceURL),
    };
};