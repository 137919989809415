import {
	IPaisCleanDTO,
	IPaisDTO,
	IPaisFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const usePaisService = () => {
	const resourceURL = 'pais';

	return {
		...baseFindList<IPaisCleanDTO, IPaisDTO, IPaisFullDTO>(resourceURL),
	};
};
