import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import Person2Outlined from '@mui/icons-material/Person2Outlined';
import { Button, Form, Input, Select } from "antd";
import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmeLeadCustomDTO, IMunicipioCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useEstadoService } from '../../services/estado.service';
import { useLeadContatoCampanhaService } from '../../services/lead-contato.service';
import { useLeadService } from '../../services/lead.service';
import { useLogradouroService } from '../../services/logradouro.service';
import { useMunicipioService } from '../../services/municipio';
import { useNotificationService } from '../../services/notification.service';
import { useVwLeadService } from '../../services/vwlead.service';

export const DadosLead = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const { leadSelecionado, setLeadSelecionado, state } = useLeadContext();
    const leadContatoService = useLeadContatoCampanhaService();
    const leadService = useLeadService();
    const vwLeadService = useVwLeadService();
    const estadoService = useEstadoService();
    const municipioService = useMunicipioService();
    const logradouroService = useLogradouroService();
    const [openModalAlterarEndereco, setOpenModalAlterarEndereco] = useState(false);
    const [openModalAlterarDadosPessoais, setOpenModalAlterarDadosPessoais] = useState(false);
    const [dsNomeContato, setDsNomeContato] = useState<string>();
    const [dsTelefone, setDsTelefone] = useState<string>();
    const [dsEmail, setDsEmail] = useState<string>();
    const [dadosLead, setDadosLead] = useState<ICrmeLeadCustomDTO>()
    const [municipioList, setMunicipioList] = useState<IMunicipioCleanDTO[]>()
    const [form] = Form.useForm();

    const findEstadoByPais = (idPais) => {
        const predicate = new Predicate();
        predicate.addOption('pais.id', idPais);

        estadoService.findClean(predicate)
            .then((response) => {
                if (response.status === 200) {
                    setDadosLead((state) => ({ ...state, estados: response?.data?.content }));
                }
            });
    }

    const onCepChange = (cep: string) => {
        if (cep.length !== 8) {
            return;
        }
        setIsGlobalLoading(true);
        const predicateLogradouro = new Predicate(10);
        predicateLogradouro.addOption('cep', cep);
        logradouroService.findCustom(predicateLogradouro)
            .then(({ data }) => {
                if (data && data.length > 0) {
                    const logradouro = data[0];

                    setDadosLead((stateParam) => {
                        const paisSelecionado = state.paises
                            .find(item => logradouro.cidadeEstadoPaisId === item.id);

                        dadosLead.endereco.cep = logradouro.cep;
                        dadosLead.endereco.bairro = logradouro.bairroNome;
                        dadosLead.endereco.cidade = logradouro.cidadeNome;
                        dadosLead.endereco.logradouro = logradouro.nomeCompleto;
                        dadosLead.endereco.nomeUF = logradouro.cidadeEstadoNome;
                        dadosLead.endereco.paisId = paisSelecionado.id;

                        form.setFieldsValue({
                            endereco: dadosLead,
                        });

                        findEstadoByPais(paisSelecionado.id);
                        return ({ ...stateParam, });
                    });

                } else {
                    notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
                }
            })
            .finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {

        if (leadSelecionado) {
            form.setFieldsValue({
                ...leadSelecionado,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadSelecionado]);

    const adicionarContato = () => {
        if (dsNomeContato?.trim?.()?.length <= 0 ||
            removerMascara(dsTelefone)?.trim?.()?.length <= 0 ||
            dsEmail?.trim?.()?.length <= 0) {
            notification({ description: 'Preencha todos os campos', type: 'warning', message: 'Atenção' })
            return;
        }

        if (!emailValido) {
            notification({ description: 'Digite um e-mail válido', type: 'warning', message: 'Atenção' })
            return;
        }

        const body = {
            dsNome: dsNomeContato,
            dsTelefone: removerMascara(dsTelefone),
            dsEmail,

            lead: {
                id: leadSelecionado.id
            }
        }
        setIsGlobalLoading(true);

        leadContatoService.create(body).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                // fetchEndereco();
                notification({ description: 'Contato cadastrado!', type: 'success', message: 'Sucesso!' });
                setDsNomeContato(null);
                setDsTelefone(null);
                setDsEmail(null);
                setOpenModalAlterarEndereco(false);
            }
        }).finally(() => setIsGlobalLoading(false))
    }

    const limparModalFechar = () => {
        setDsNomeContato(null);
        setDsTelefone(null);
        setDsEmail(null);
        setOpenModalAlterarEndereco(false);
    }


    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const emailValido = useMemo(() => {
        if (dsEmail === null || dsEmail === undefined) return false;

        return validateEmail(dsEmail);
    }, [dsEmail])

    const removerMascara = (e = '') => {
        return e.replaceAll('_', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    }

    const setCampo = (dsCampo, valor) => {
        const field = {
            [dsCampo]: valor,
        }
        form.setFieldsValue(field);
    }

    const setCampoEndereco = (dsCampo, valor) => {
        setDadosLead((state) => {
            leadSelecionado.endereco[dsCampo] = valor;
            return { ...state, formControl: leadSelecionado };
        });
    }

    const handleChangeEndereco = (campo, value: any) => {
        setCampoEndereco(campo, {
            id: value,
        })
    }

    const handleChangePais = (e) => {
        handleChangeEndereco('pais', e);
        findEstadoByPais(e);
    }

    const handleChange = (campo, value: any) => {
        setCampo(campo, {
            id: value,
        })
    }

    const onSalvarEnderecoClick = () => {
        const body = { ...form.getFieldsValue() };

        setIsGlobalLoading(true);
        return leadService.patch(leadSelecionado.id as number, body).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return vwLeadService.findCustomIndexById({ id: leadSelecionado.id })
                    .then((response) => {
                        if (response.status >= 200 && response.status <= 299) {
                            setLeadSelecionado(response?.data);
                            setOpenModalAlterarEndereco(false);
                            notification({ description: 'Endereço atualizado com sucesso!', type: 'success' })
                            return;
                        }

                        throw new Error();
                    })
            }

            throw new Error();
        }).catch((e) => {
            notification({ description: 'Não foi possível alterar os dados de endereço.', type: 'error' })
        }).finally(() => {
            setIsGlobalLoading(false);
        });
    }


    const onSalvarDadosPessoaisClick = () => {
        const body = { ...form.getFieldsValue(), endereco: undefined };

        setIsGlobalLoading(true);
        return leadService.patch(leadSelecionado?.id as any, body).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return vwLeadService.findCustomIndexById({ id: leadSelecionado.id as any }).then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        setOpenModalAlterarDadosPessoais(false);
                        setLeadSelecionado(response?.data);
                        notification({ description: 'Dados pessoais atualizados com sucesso!', type: 'success' })
                        return;
                    }

                    throw new Error();
                })
            }

            throw new Error();
        }).catch((e) => {
            notification({ description: 'Não foi possível alterar os dados pessoais.', type: 'error' })
        }).finally(() => {
            setIsGlobalLoading(false);
        });
    }

    const findMunicipio = () => {
        const predicate = new Predicate();
        municipioService.findCleanList(predicate)
            .then(({ data }) => {
                setMunicipioList(data);
            })
    }

    useEffect(() => {
        findMunicipio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Form
                style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
                name="basic"
                // initialValues={{ remember: true }}
                form={form}
            >
                <div className="tarefa-panel" id="dados-lead">
                    <div className="flex justify-between flex-column gap-20 mb-20">
                        <div className="flex align-center gap-10">
                            <Person2Outlined />
                            <h1 id="sem-margin">Dados pessoais</h1>
                        </div>

                        <div className="flex flex-column gap-10">
                            <Form.Item name="id" style={{ display: 'none' }} />

                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="dsNomeLead">Nome</label>
                                    <div className="box-white">
                                        <Form.Item name={'dsNomeLead'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o nome' }]}>
                                            <Input id="dsNomeLead" bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cdCpfCnpj">Cpf</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdCpfCnpj'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o Cpf' }]}>
                                            <Input id="cdCpfCnpj" bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cdRg">Rg</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdRg'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o Rg' }]}>
                                            <Input id="cdRg" bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cdProfissao">Profissão</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdProfissao'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o profissão' }]}>
                                            <Input id="cdProfissao" bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 3 }}>
                                    <label htmlFor="cdEmail">E-mail</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdEmail'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o e-mail' }]}>
                                            <Input id="cdEmail" bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cdDddCelular">DDD</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdDddCelular'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o DDD' }]}>
                                            <InputMask id="cdDddCelular" mask="99" value={dadosLead?.cdDddCelular} placeholder="Celular" style={{ border: 'none' }} bordered={false} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cdCelular">Celular</label>
                                    <div className="box-white">
                                        <Form.Item name={'cdCelular'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o celular' }]}>
                                            <InputMask
                                                id="cdCelular"
                                                mask="99999-9999"
                                                style={{ border: 'none' }}
                                                value={dadosLead?.cdCelular}
                                                placeholder="Celular"
                                                bordered={false}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row justify-end w-100">
                        <Button onClick={() => setOpenModalAlterarDadosPessoais(true)} className="button-primary">
                            Salvar dados pessoais
                        </Button>
                    </div>
                </div>

                <div className="tarefa-panel" id="dados-lead">
                    <div className="flex justify-between flex-column gap-20 mb-20">
                        <div className="flex align-center gap-10">
                            <ContactMailOutlinedIcon />
                            <h1 id="sem-margin">Endereço</h1>
                        </div>

                        <div className="flex flex-column">
                            <Form.Item name="id" style={{ display: 'none' }} />
                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cep">CEP</label>
                                    <div className="box-white">
                                        <Form.Item name={['endereco', 'cep']} rules={[{ required: false, message: 'Insira o CEP' }]}>
                                            <Input id="cep" bordered={false} onChange={(e) => onCepChange(e.target.value)} className="input" maxLength={8} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="paisId">País</label>
                                    <div className="box-white">
                                        <Form.Item name={['endereco', 'paisId']} fieldKey={['endereco', 'nome']} rules={[{ required: false, message: 'Selecione um País' }]}>
                                            <Select
                                                style={{ width: '100%' }}
                                                bordered={false}
                                                onSelect={(e) => handleChangePais(e)}
                                                id="paisId"
                                                placeholder="Selecione um País"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                                }>
                                                {state.paises && state.paises.map((item, index) => (
                                                    <Select.Option key={index} value={item.id} text={item.nome}>{item.nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-column">
                            <div className="flex flex-row gap-10">
                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="nomeUF">Estado</label>
                                    <div className="box-white">
                                        <Form.Item name={['endereco', 'nomeUF']} rules={[{ required: true, message: 'Selecione um Estado' }]}>
                                            <Select
                                                id="estado"
                                                placeholder="Selecione um Estado"
                                                showSearch
                                                allowClear>
                                                {state.estados && state.estados.map((item) => (
                                                    <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <Form.Item name={['endereco', 'pais', 'id']} style={{ display: 'none' }} />

                                <div className="flex align-center gap-10 flex flex-fill">
                                    <label htmlFor="cidade">Cidade</label>
                                    <div className="box-white">
                                        <Form.Item name={['endereco', 'cidade']} rules={[{ required: true, message: 'Selecione uma Cidade' }]}>
                                            <Select
                                                id="cidade"
                                                placeholder="Selecione um Cidade"
                                                showSearch
                                                allowClear>
                                                {state.cidades && state.cidades.map((item) => (
                                                    <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 3 }}>
                                <label htmlFor="logradouro">Logradouro</label>
                                <div className="box-white">
                                    <Form.Item name={['endereco', 'logradouro']} rules={[{ required: false, message: 'Informe o Logradouro' }]}>
                                        <Input id="logradouro" className="input" bordered={false} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="flex align-center gap-10 flex flex-fill">
                                <label htmlFor="numero">Número</label>
                                <div className="box-white">
                                    <Form.Item name={['endereco', 'numero']} rules={[{ required: false, message: 'Informe o Número' }]}>
                                        <Input id="numero" type="number" className="input" bordered={false} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 3 }}>
                                <label htmlFor="complemento">Complemento</label>
                                <div className="box-white">
                                    <Form.Item name={['endereco', 'complemento']} rules={[{ required: false, message: 'Informe o Complemento' }]}>
                                        <Input id="complemento" className="input" bordered={false} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row justify-end w-100">
                        <Button onClick={() => setOpenModalAlterarEndereco(true)} className="button-primary">
                            Salvar endereço
                        </Button>
                    </div>
                </div>

                <ModalResposta
                    open={openModalAlterarEndereco}
                    onClose={onSalvarEnderecoClick}
                    onCancel={() => setOpenModalAlterarEndereco(false)}
                    status="Salvar endereço"
                    text="Deseja mesmo alterar as informações do endereço?"
                    link=""
                />

                <ModalResposta
                    open={openModalAlterarDadosPessoais}
                    onClose={onSalvarDadosPessoaisClick}
                    onCancel={() => setOpenModalAlterarDadosPessoais(false)}
                    status="Salvar dados pessoais"
                    text="Deseja mesmo alterar as informações pessoais?"
                    link=""
                />
            </Form>
        </>
    );
}
