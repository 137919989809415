import {
	ICrmeLeadTipoCleanDTO,
	ICrmeLeadTipoDTO,
	ICrmeLeadTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTipoService = () => {
	const resourceURL = 'crme/lead-tipo';

	return {
		...baseFindList<ICrmeLeadTipoCleanDTO, ICrmeLeadTipoDTO, ICrmeLeadTipoFullDTO>(resourceURL),
	};
};
