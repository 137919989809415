import http from "../helpers/http-common";
import { ICrmeLeadJuridicoArquivoCleanDTO, ICrmeLeadJuridicoArquivoDTO, ICrmeLeadJuridicoArquivoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseDelete } from "./base/base-delete.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useLeadJuridicoArquivoService = () => {

  const resourceURL = '/crme/lead-juridico-arquivo';


  const download = (fileName: string, name: string) => {
    return http.get(`${resourceURL}/download/${fileName}?name=${name}`, {
      responseType: 'blob',
    });
  };

  return {
    ...baseFindList<ICrmeLeadJuridicoArquivoCleanDTO, ICrmeLeadJuridicoArquivoDTO, ICrmeLeadJuridicoArquivoFullDTO>(resourceURL),
    ...baseFind<ICrmeLeadJuridicoArquivoCleanDTO, ICrmeLeadJuridicoArquivoDTO, ICrmeLeadJuridicoArquivoFullDTO>(resourceURL),
    ...baseCreate<ICrmeLeadJuridicoArquivoCleanDTO, ICrmeLeadJuridicoArquivoDTO, ICrmeLeadJuridicoArquivoFullDTO>(resourceURL),
    ...baseDelete(resourceURL),
    ...baseUpdate(resourceURL),
    download,
  };

}
