export enum Funcao {
  ADMINISTRADOR_CODIGO = 'ADM',
  GESTAO_ACADEMICA_CODIGO = 'GSA',
  GESTAO_FINANCEIRO_CODIGO = 'GSF',
  INSTRUTOR_CODIGO = 'IST',
  TECNOLOGIA_CODIGO = 'TEC',

  ADMINISTRADOR_DESCRICAO = 'Administrador',
  GESTAO_ACADEMICA_DESCRICAO = 'Gestão Acadêmica',
  GESTAO_FINANCEIRO_DESCRICAO = 'Gestão Financeira',
  INSTRUTOR_DESCRICAO = 'Instrutor',
  TECNOLOGIA_DESCRICAO = 'Tecnologia',
}