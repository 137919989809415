import { Button, Divider, Modal } from 'antd';
import { useState } from 'react';
import "./styles.css";

interface Props {
    title: string;
    renderHtml: string;
    remetente: string;
    type: "email" | "whatsapp";
}

const ModalRenderHTML: React.FunctionComponent<Props> = ({ title, renderHtml, remetente, type }: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            {type === "email" ?
                <>
                    <Modal
                        title={title}
                        centered
                        open={open}
                        width={800}
                        onCancel={() => setOpen(false)}
                        footer={null}
                    >
                        <div>
                            <p>Remetente: {remetente}</p>
                        </div>
                        <Divider />
                        <div className="modal-render-html">
                            <span dangerouslySetInnerHTML={{ __html: renderHtml }} />
                        </div>
                        <Divider />
                        <div className="flex justify-end">
                            <Button className="modal-button-html" onClick={() => setOpen(false)}>
                                Fechar
                            </Button>
                        </div>
                    </Modal>

                    <Button className={`modal-button-html-${type}`} onClick={() => setOpen(true)}>
                        E-mail
                    </Button>
                </>
                : null}
            {type === "whatsapp" ?
                <>
                    <Modal
                        title={title}
                        centered
                        open={open}
                        width={800}
                        onCancel={() => setOpen(false)}
                        footer={null}
                    >
                        <div>
                            <p>Remetente: {remetente}</p>
                        </div>
                        <Divider />
                        <div className="modal-render-html">
                            <span dangerouslySetInnerHTML={{ __html: renderHtml }} />
                        </div>
                        <Divider />
                        <div className="flex justify-end">
                            <Button className="modal-button-html" onClick={() => setOpen(false)}>
                                Fechar
                            </Button>
                        </div>
                    </Modal>

                    <Button className={`modal-button-html-${type}`} onClick={() => setOpen(true)}>
                        WhatsApp
                    </Button>
                </>
                : null}
        </>
    );
};

export default ModalRenderHTML;