import { useState } from "react";
import { Button, Input, Select } from 'antd';
import { ModalResposta } from '../../components/Modal/resposta';

interface Props {
  open:boolean;
  onClose: ()=>void;
}

export const ModalEquipeNew = (props: Props) =>{
  const { open, onClose } = props;
  const { Option } = Select;

  function handleChange(value:any) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);
  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

  return(
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Cadastrar Equipe</h3>
          <div className="input-modal mb-20">
            <Input placeholder='Nome' bordered={false} />
          </div>
          <div className="input-modal mb-20">
            <Input placeholder='Email' bordered={false} />
          </div>
          <small>Digite um e-mail válido</small>
          <div className="input-modal mb-20">
            <Input placeholder='Celular' bordered={false} />
          </div>
          <div className="input-modal mb-20">
            <Select style={{width:'100%'}} bordered={false} defaultValue="equipe" onChange={handleChange}>
              <Option value="equipe">Equipe</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
          <div className="input-modal mb-20">
            <Select style={{width:'100%'}} bordered={false} defaultValue="origem" onChange={handleChange}>
              <Option value="origem">Origem</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder='Campanha' bordered={false}  />
          </div>

          <div className="flex flex-row justify-end w-100">
            <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
            <Button onClick={onChangeModal} className="button-primary">Salvar</Button>
          </div>
        </div>
        <ModalResposta
          open={isOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="A nova equipe foi cadastrada com sucesso."
          link="equipes"
        />
      </div>
    </>
  )
}
