import http from '../../helpers/http-common';
import { IAxiosResponseCustom } from '../../models/http.model';

export const baseCreate = <C, D, F>(resourceURL: string) => {
    return {
        createClean: (body): Promise<IAxiosResponseCustom<C>> => http.post(`${resourceURL}/clean`, body),
        create: (body): Promise<IAxiosResponseCustom<D>> => http.post(`${resourceURL}`, body),
        createFull: (body): Promise<IAxiosResponseCustom<F>> => http.post(`${resourceURL}/full`, body),
        saveAll: (body): Promise<IAxiosResponseCustom<D[]>> => http.post(`${resourceURL}/all`, body),
    }
}