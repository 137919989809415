import { useState } from "react";
import { Button } from 'antd';
import { ModalResposta } from '../../components/Modal/resposta';

interface Props {
  open:boolean;
  onClose: ()=>void;
  status:string;
  text:string;
  link:string;
}

export const ModalDeletar = (props: Props) =>{
  const { open, status, text, link } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

    return(
      <>
        <div className="flex flex-row justify-center align-center modal-container">
          <div className="flex flex-column justify-start align-start modal-resposta">
            <h3>{status}</h3>
            <p>{text}</p>
            <div className="flex flex-row justify-end w-100">
              <a href={`/${link}`}>
                <Button className="button-blue mr-5">Cancelar</Button>
              </a>
              <Button onClick={onChangeModal} className="button-primary mr-5">Confirmar</Button>
            </div>
          </div>
          <ModalResposta
            open={isOpen}
            onClose={onClose1}
            status="Equipe removida"
            text="A equipe e os usuários dela foram removidos com êxito."
            link="equipes"
          />
        </div>
      </>
    )
}
