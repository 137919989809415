import * as Unicons from '@iconscout/react-unicons';
import { useState } from "react";
import {
  Menu,
  MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from 'react-router-dom';
import { Hamburger } from "../../components/Hamburger";
import LogoGrande from '../../assets/img/sidebar/logo-grande.svg';
import Logo from '../../assets/img/sidebar/logo.svg';
import { useLeadContext } from '../../context/lead.context.service';
import { Funcao } from '../../enum/funcao';
import { formatLoginNome } from "../../util/format";
import { Avatar } from "../Avatar";

interface Props {
  crm?: boolean;
  dashboard?: boolean;
  cargos?: boolean;
  usuarios?: boolean;
  equipes?: boolean;
  tarefas?: boolean;
}

export const Sidebar = (props: Props) => {
  const { crm, dashboard, cargos, usuarios, equipes, tarefas } = props;
  const { usuario } = useLeadContext();
  const [menuCollapse, setMenuCollapse] = useState(true)

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [menuBurger, setMenuBurger] = useState('burger-menu-none')
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
    if (hamburgerOpen === true) {
      setMenuBurger('burger-menu-none')
    } else {
      setMenuBurger('burger-menu')
    }
  }

  const funcaoDescricao = (funcao) => {
    let descricao;
    if (funcao === Funcao.ADMINISTRADOR_CODIGO) {
      descricao = Funcao.ADMINISTRADOR_DESCRICAO;
    }
    if (funcao === Funcao.GESTAO_ACADEMICA_CODIGO) {
      descricao = Funcao.GESTAO_ACADEMICA_DESCRICAO;
    }
    if (funcao === Funcao.GESTAO_FINANCEIRO_CODIGO) {
      descricao = Funcao.GESTAO_FINANCEIRO_DESCRICAO;
    }
    if (funcao === Funcao.INSTRUTOR_CODIGO) {
      descricao = Funcao.INSTRUTOR_DESCRICAO;
    }
    if (funcao === Funcao.TECNOLOGIA_CODIGO) {
      descricao = Funcao.TECNOLOGIA_DESCRICAO;
    }
    return descricao;
  }

  return (
    <>
      <div className="hamburger" onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen} />
      </div>
      <div className={menuBurger}>
        <ul>
          <Link to="/">
            <li>Dashboard</li>
          </Link>
          <Link to="/tarefas">
            <li>Tarefas</li>
          </Link>
          {/* <Link to="/cursos">
            <li>Cursos</li>
          </Link>
          <Link to="/materiais">
            <li>Materiais</li>
          </Link>
          <Link to="/mentorias">
            <li>Mentorias</li>
          </Link>
          <hr />
          <Link to="/perfil">
            <li>Perfil</li>
          </Link> */}
        </ul>
      </div>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              <p>{menuCollapse ? <img src={Logo} alt="" onClick={menuIconClick} /> : <img className="logo-grande" src={LogoGrande} alt="" onClick={menuIconClick} />}</p>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="round">
              <MenuItem active={crm} icon={<Unicons.UilFilter size="20" color={crm ? "var(--white)" : "var(--cinza-azulado)"} />}>
                CRM
                <Link to="/" />
              </MenuItem>
              {/* <MenuItem active={dashboard} icon={<Unicons.UilApps size="20" dashboard={dashboard ? "var(--white)" : "var(--cinza-azulado)"} />}>
                Dashboard
                <Link to="/dashboard" />
              </MenuItem> */}
              <MenuItem active={tarefas} icon={<Unicons.UilCalender size="20" color={tarefas ? "var(--white)" : "var(--cinza-azulado)"} />}>
                Tarefas
                <Link to="/tarefas" />
              </MenuItem>
              {/* <MenuItem active={cargos} icon={<Unicons.UilUserSquare size="20" color={cargos ? "var(--white)" : "var(--cinza-azulado)"} />}>
                Gerenciar Cargos
                <Link to="/cargos" />
              </MenuItem>
              <MenuItem active={usuarios} icon={<Unicons.UilUser size="20" color={usuarios ? "var(--white)" : "var(--cinza-azulado)"} />}>
                Gerenciar Usuarios
                <Link to="/usuarios" />
              </MenuItem>
              <MenuItem active={equipes} icon={<Unicons.UilUsersAlt size="20" color={equipes ? "var(--white)" : "var(--cinza-azulado)"} />}>
                Gerenciar Equipes
                <Link to="/equipes" />
              </MenuItem> */}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu>
              <MenuItem className="item-avatar" icon={<Avatar base64={usuario?.arquivo} />}>
                <div className="box-avatar">
                  <h6>{formatLoginNome(usuario?.login)}</h6>
                  <p>{funcaoDescricao(usuario?.funcao)}</p>
                </div>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
}

