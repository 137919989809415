import * as Unicons from '@iconscout/react-unicons';
import ClearIcon from '@mui/icons-material/Clear';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaptopIcon from '@mui/icons-material/Laptop';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SettingsIcon from '@mui/icons-material/Settings';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import { Button, Input } from 'antd';
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/global.service";
import { ICrmeLeadAnotacaoDTO, ICrmeLeadDTO, ICrmeLeadTarefaCustomCleanDTO } from "../../models/happy-code-api.model";
import useLeadScreenState from "./lead.state";
import TagStatus from '../TagStatus';
import { formatRemove3Horas } from '../../util/format';

interface Props {
    provided: any,
    id: number,
    cliente: string,
    status: string,
    campanha: string,
    dataInicio: string,
    note: boolean,
    proximaTarefa?: ICrmeLeadTarefaCustomCleanDTO,
    qualify: number,
    dataCriacao: string,
    dataAtualizacao?: string,
    dsUltimaAnotacao?: string,
    nrInteracao?: number,
    dsAreaAtuacao?: string;
    dsModelo?: string;
}

export const statusWord = (status: string | undefined) => {
    switch (status) {
        case 'novo':
            return 'Sem Retorno';
        case 'futuro':
            return 'Retorno Futuro';
        case 'atrasado':
            return 'Retorno Atrasado';
        case 'hoje':
            return 'Retorno para hoje';
        default:
            return 'Sem Retorno';
    }
}

export const styleNote = (status: string) => {
    switch (status) {
        case 'novo':
            return 'var(--azul-happy)';
        case 'futuro':
            return 'var(--verde-futuro)';
        case 'atrasado':
            return 'var(--vermelho-atrasado)';
        case 'hoje':
            return 'var(--amarelo-hoje)';
        default:
            return 'var(--azul-happy)';
    }
}

export const Lead = ({ provided,
    id,
    cliente,
    status,
    campanha,
    dataInicio,
    note,
    proximaTarefa,
    qualify,
    dataCriacao,
    dataAtualizacao,
    dsUltimaAnotacao,
    nrInteracao,
    dsModelo,
    /*dhTarefa*/ 
}: Props) => {

    const [openModalNewNote, setOpenModalNewNote] = useState(false);
    const [openModalNote, setOpenModalNote] = useState(false);
    const [openModalTarefa, setOpenModalTarefa] = useState(false);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [dsTextoAnotacao, setDsTextoAnotacao] = useState<string>();
    const [valueRating, setValueRating] = useState<number | null>(qualify);
    const today = new Date();

    // const verificarHora = () => {
    //     if (dhTarefa <= today) {
    //         return 
    //     }
    // }

    const { salvarAnotacao } = useLeadScreenState();
    const { setIsGlobalLoading } = useGlobalContext();


    const handleOpenModalNewNote = () => {
        setOpenModalNewNote(true);
        setOpenModalTarefa(false);
        setOpenModalInfo(false);
        setOpenModalNote(false);
    }
    const handleCloseModalNewNote = () => {

        const body: ICrmeLeadAnotacaoDTO = {
            lead: {
                id,
            } as ICrmeLeadDTO,
            dsLeadAnotacao: dsTextoAnotacao,
            dsTipoLeadAnotacao: 'Whatsapp',
            ativo: true,
        } as ICrmeLeadAnotacaoDTO;

        setIsGlobalLoading(true);
        salvarAnotacao(body).finally(() => {
            setOpenModalNewNote(false);
            setIsGlobalLoading(false);
        })

    }

    const handleOpenModalNote = () => {
        setOpenModalNote(true);
        setOpenModalNewNote(false);
        setOpenModalInfo(false);
        setOpenModalTarefa(false);
    }
    const handleCloseModalNote = () => setOpenModalNote(false);

    const handleOpenModalTarefa = () => {
        setOpenModalTarefa(true);
        setOpenModalNewNote(false);
        setOpenModalNote(false);
        setOpenModalInfo(false);
    }
    const handleCloseModalTarefa = () => setOpenModalTarefa(false);

    const handleOpenModalInfo = () => {
        setOpenModalInfo(true);
        setOpenModalNewNote(false);
        setOpenModalTarefa(false);
        setOpenModalNote(false);
    }
    const handleCloseModalInfo = () => setOpenModalInfo(false);

    const [icones, _] = useState(
        {
            'WhatsAppIcon': WhatsAppIcon,
            'EmailOutlinedIcon': EmailOutlinedIcon,
            'SupervisedUserCircleIcon': SupervisedUserCircleIcon,
            'LaptopIcon': LaptopIcon,
            'LocationCityIcon': LocationCityIcon,
            'SettingsIcon': SettingsIcon,
            'ScreenShareOutlinedIcon': ScreenShareOutlinedIcon,
            'NoteAltOutlined': NoteAltOutlined,
        }
    )

    const retornarIcone = useMemo(() => {
        try {
            const Icone = icones[proximaTarefa?.cdTipoTarefa];
            if (!Icone) {
                return <SentimentVeryDissatisfiedIcon style={{ color: 'var(--cinza-azulado-700)' }} />;
            }
            return <Link to={`/lead/${id}?aba=nova-tarefa`}><Icone style={{ color: 'var(--cinza-azulado-700)' }} /></Link>
        } catch (e) {
            return <SentimentVeryDissatisfiedIcon style={{ color: 'var(--cinza-azulado-700)' }} />;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [icones, proximaTarefa])


    return (
        <>
            <li className={`lead-${status}`} key={id}
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{ ...provided.draggableProps.style }}
            >
                <div {...provided.dragHandleProps}>
                    <Unicons.UilDraggabledots className="dragg" />
                </div>

                <Link className="c" to={`/lead/${id}`}
                    style={{ textDecoration: 'none', display: 'flex' }}>
                    <div>
                        <h3>{cliente}</h3>
                        <div className="status">{statusWord(status)}</div>
                        <h5>{campanha}</h5>
                        <p className="mb-10">{format(formatRemove3Horas(new Date(dataInicio)), 'dd/MM/yyyy HH:mm:ss')}</p>
                        <TagStatus status={dsModelo} />
                    </div>
                </Link>
                <div className="line-lead"></div>
                <div className="icons flex flex-row justify-between align-center w-100">
                    <Rating
                        name="simple-controlled"
                        value={valueRating}
                        disabled
                        onChange={(event, newValueRating) => {
                            setValueRating(newValueRating);
                        }}
                        sx={{
                            '& .MuiRating-iconFilled': { color: 'var(--azul-happy)' },
                            '& .MuiRating-iconHover': { color: 'var(--azul-happy)' }
                        }}
                    />
                    <div className="box-icones">
                        {dsUltimaAnotacao?.length > 0 ?
                            <>
                                <div className="dot-red"></div>
                                <StickyNote2Icon
                                    style={{ color: styleNote(status), cursor: 'pointer' }}
                                    onClick={handleOpenModalNote}
                                />
                            </>
                            :
                            <NoteAddIcon
                                style={{ color: styleNote(status), cursor: 'pointer' }}
                                onClick={handleOpenModalNewNote}
                            />
                        }
                        {/* {icone === 'whatsapp' &&
              <WhatsAppIcon style={{ color: 'var(--cinza-azulado-700)' }} />
            }
            {icone === 'supervisor' &&
              <SupervisedUserCircleOutlinedIcon style={{ color: 'var(--cinza-azulado-700)' }} />
            }
            {icone === 'share' &&
              <ScreenShareOutlinedIcon style={{ color: 'var(--cinza-azulado-700)' }} />
            }
            {icone === 'sad' &&
              <SentimentVeryDissatisfiedIcon style={{ color: 'var(--cinza-azulado-700)' }} />
            } */}

                        {/* {icone === 'supervisor' &&
              <SupervisedUserCircleOutlinedIcon style={{ color: 'var(--cinza-azulado-700)' }} />
            }

            {icone === 'sad' &&
          } */}
                        {retornarIcone}
                        <InfoOutlinedIcon
                            style={{ color: 'var(--cinza-azulado-700)', cursor: 'pointer' }}
                            onClick={handleOpenModalInfo}
                        />
                    </div>
                </div>
            </li>
            <Modal
                open={openModalNewNote}
                onClose={() => setOpenModalNewNote(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <Fade in={openModalNewNote}>
                    <div className="modal-note">
                        <div className="container-modal">
                            <ClearIcon className="button-close" onClick={() => setOpenModalNewNote(false)} />
                            <h3>Criar Anotação</h3>
                            <Input.TextArea
                                value={dsTextoAnotacao}
                                name=""
                                id=""
                                rows={10}
                                placeholder="Descreva sua anotação"
                                onChange={(e) => setDsTextoAnotacao(e.target.value as any)} />
                            <p>{dsTextoAnotacao?.length || 0}/500</p>
                            <div className="flex flex-row justify-between aling-center gap-10">
                                {/* <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button> */}
                                <Button className="button-blue" onClick={handleCloseModalNewNote}>Salvar</Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                open={openModalNote}
                onClose={handleCloseModalNote}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <Fade in={openModalNote}>
                    <div className="modal-note">
                        <div className="container-modal">
                            <ClearIcon className="button-close" onClick={handleCloseModalNote} />
                            <h3>Anotação</h3>
                            <h6>{dsUltimaAnotacao}</h6>
                            <div className="flex flex-row  justify-end align-end gap-10">
                                {/* {proximaTarefa && <Button className="button-blue" onClick={handleOpenModalTarefa}>Ver Tarefas</Button>} */}
                                <Button className="button-primary" onClick={handleOpenModalNewNote}>Criar Nova</Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                open={openModalTarefa}
                onClose={handleCloseModalTarefa}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <Fade in={openModalTarefa}>
                    <div className="modal-note">
                        <div className="container-modal">
                            <ClearIcon className="button-close" onClick={handleCloseModalTarefa} />
                            <h3>Próxima tarefa</h3>
                            <div className="flex flex-row justify-between align-center">
                                <h5>Tipo:</h5>
                                <h6>{proximaTarefa?.dsTipoTarefa}</h6>
                            </div>
                            {proximaTarefa?.dhTarefa && <div className="flex flex-row justify-between align-center">
                                <h5>Data:</h5>
                                <h6>{format(new Date(proximaTarefa?.dhTarefa), 'dd/MM/yyyy')}</h6>
                            </div>}
                            {proximaTarefa?.hrTarefa && <div className="flex flex-row justify-between align-center">
                                <h5>Horário</h5>
                                <h6>{format(new Date(proximaTarefa?.hrTarefa), 'HH:mm')}</h6>
                            </div>}
                            <div className="">
                                <h5 id="title-assunto">Assunto:</h5>
                                <h6>{proximaTarefa?.dsAssunto}</h6>
                            </div>
                            <div className="flex flex-row justify-between aling-center gap-10">
                                <Button className="button-primary" onClick={handleOpenModalNote}>Ver Anotações</Button>
                                <Button className="button-blue" onClick={handleOpenModalNewNote}>Criar Nova</Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                open={openModalInfo}
                onClose={handleCloseModalInfo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
                disableEnforceFocus
            >
                <Fade in={openModalInfo}>
                    <div className="modal-info" style={{ width: 800, minWidth: 800 }}>
                        <div className="container-modal">
                            <ClearIcon className="button-close" onClick={handleCloseModalInfo} />
                            <div className="flex flex-row mb-20">
                                <InfoIcon style={{ marginRight: 7 }} />
                                <h3>Resumo do lead</h3>
                            </div>
                            <div className="flex flex-row align-center mb-20">
                                <div style={{ width: '50%' }}>
                                    <h4>Data de criação</h4>
                                    {(dataCriacao?.length > 0) && <p>{format(new Date(dataCriacao), 'dd/MM/yyyy')} ÀS {format(new Date(dataCriacao), 'HH:mm')}</p>}
                                    <h4>Próxima tarefa</h4>
                                    <p>{proximaTarefa ? proximaTarefa?.dsAssunto?.substring?.(0, 20) + (proximaTarefa.dhTarefa ? ' - ' +
                                        format(new Date(proximaTarefa.dhTarefa), 'dd/MM/yyyy') + ' ÀS ' +
                                        format(new Date(proximaTarefa?.hrTarefa), 'HH:mm') : '--') : ''}</p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <h4>Última interação</h4>
                                    {(dataAtualizacao?.length > 0) ? <p>{format(new Date(dataAtualizacao), 'dd/MM/yyyy')} ÀS {format(new Date(dataAtualizacao), 'HH:mm')}</p> : <p>sem interações</p>}
                                    <h4>Quantidade de interações</h4>
                                    <p>{nrInteracao || 0}</p>
                                </div>
                            </div>
                            <h4>Última anotação</h4>
                            <div className="box-note">
                                <p>{dsUltimaAnotacao || 'sem anotações no momento...'}</p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
