import * as Unicons from '@iconscout/react-unicons';

interface Props{
  estilo?:string;
  text?:string;
  quantidade?:number;
  icon?:string
}

export const Square = (props:Props) =>{

  let icone;
  const{estilo, text, quantidade,icon} = props;

  if(icon==='users'){
    icone = <Unicons.UilUser color="var(--white)" size={16} />
  }

  if(icon==='phone'){
    icone = <Unicons.UilCalling color="var(--white)" size={16} />
  }

  if(icon==='whatsapp'){
    icone = <Unicons.UilWhatsapp color="var(--white)" size={16} />
  }

  if(icon==='email'){
    icone = <Unicons.UilEnvelope color="var(--white)" size={16} />
  }

  if(icon==='arrow-left'){
    icone = <Unicons.UilArrowLeft color="var(--white)" size={16} />
  }

  if(icon==='calendar'){
    icone = <Unicons.UilCalender color="var(--white)" size={16} />
  }

  if(icon==='start'){
    icone = <Unicons.UilStar color="var(--white)" size={16} />
  }

  if(estilo==='vermelho'){
    return(
      <>
        <div className={`square-${estilo} flex flex-column`}>
          <div className="flex flex-row justify-start align-center w-100">
            <div className="icon">
              {icone}
            </div>
            <span>{text}</span>
          </div>
          <div className="line"></div>
          <h5>{quantidade}</h5>
        </div>
      </>
    );
  }

  if(estilo==='amarelo'){
    return(
      <>
        <div className={`square-${estilo} flex flex-column`}>
          <div className="flex flex-row justify-start align-center w-100">
            <div className="icon">
              {icone}
            </div>
            <span>{text}</span>
          </div>
          <div className="line"></div>
          <h5>{quantidade}</h5>
        </div>
      </>
    );
  }

  if(estilo==='verde'){
    return(
      <>
        <div className={`square-${estilo} flex flex-column`}>
          <div className="flex flex-row justify-start align-center w-100">
            <div className="icon">
              {icone}
            </div>
            <span>{text}</span>
          </div>
          <div className="line"></div>
          <h5>{quantidade}</h5>
        </div>
      </>
    );
  }

  if(estilo==='azul'){
    return(
      <>
        <div className={`square-${estilo} flex flex-column`}>
          <div className="flex flex-row justify-start align-center w-100">
            <div className="icon">
              {icone}
            </div>
            <span>{text}</span>
          </div>
          <div className="line"></div>
          <h5>{quantidade}</h5>
        </div>
      </>
    );
  }

  if(estilo==='roxo'){
    return(
      <>
        <div className={`square-${estilo} flex flex-column`}>
          <div className="flex flex-row justify-start align-center w-100">
            <div className="icon">
              {icone}
            </div>
            <span>{text}</span>
          </div>
          <div className="line"></div>
          <h5>{quantidade}</h5>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="square flex flex-column">
        <div className="flex flex-row justify-start align-center w-100">
          <div className="icon">
            {icone}
          </div>
          <span>{text}</span>
        </div>
        <div className="line"></div>
        <h5>{quantidade}</h5>
      </div>
    </>
  )
}
