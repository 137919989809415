import {
	ICrmeLeadTarefaResponsavelCleanDTO, ICrmeLeadTarefaResponsavelDTO, ICrmeLeadTarefaResponsavelFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTarefaResponsavelService = () => {
	const resourceURL = 'crme/lead-tarefa-responsavel';

	return {
		...baseCreate<ICrmeLeadTarefaResponsavelCleanDTO, ICrmeLeadTarefaResponsavelDTO, ICrmeLeadTarefaResponsavelFullDTO>(resourceURL),
		...baseFindList<ICrmeLeadTarefaResponsavelCleanDTO, ICrmeLeadTarefaResponsavelDTO, ICrmeLeadTarefaResponsavelFullDTO>(resourceURL),
	};
};
