import { notification } from "antd";
import { ICrmeLeadAnotacaoDTO } from "../../models/happy-code-api.model";
import { useLeadAnotacaoService } from "../../services/lead-anotacao.service";

const useLeadScreenState = () => {

  const anotacaoService = useLeadAnotacaoService();

  const salvarAnotacao = (body: ICrmeLeadAnotacaoDTO) => {
    return anotacaoService.create(body)
      .then(() => {
        notification.success({ message: 'Anotação cadastrada com sucesso', type: 'success', placement: "bottomRight" });
      });
  }

  return {
    salvarAnotacao,
  }
}

export default useLeadScreenState;
