export const styles = {
  'tabs':{
    '& button':{
        backgroundColor:'var(--white)',
        borderTopLeftRadius:5,
        borderTopRightRadius:5,
        paddingX:4,
        textTransform:'uppercase',
        borderColor:'var(--cinza-azulado-100)',
        borderStyle:'solid',
        borderWidth:1,
        marginRight:0.2,
        color:'var(--cinza-azulado-200)',
        fontFamily:'Arial',
        minHeight:50
    },
    '& button:hover':{
      backgroundColor:'var(--azul-happy)',
      color:'var(--white)',
      borderWidth:1,
      borderColor:'var(--azul-happy)',
      borderStyle:'solid'
    },
    '& button.Mui-selected':{
      backgroundColor:'var(--azul-happy)',
      color:'var(--white)',
      borderWidth:1,
      borderColor:'var(--azul-happy)',
      borderStyle:'solid'
    },
  },
  'boxTabs':{
    width: '100%',
    backgroundColor:'var(--white)',
    paddingX:8
  },
  'boxTabsComunication':{
    width: '100%',
    backgroundColor:'var(--white)',
    paddingX:0,
    borderRadius:3
  },
  'rating':{
    '& .MuiRating-iconFilled': {color:'var(--azul-happy)'},
    '& .MuiRating-iconHover': {color:'var(--azul-happy)'}
  }
}
