import { AuthEnum } from '../enum/auth.enum';
import { Auth } from '../models/auth.model';

export const setObject = (key, value): void => {
	localStorage.setItem(key, JSON.stringify(value));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getObject = (key): any => {
	try {
		const getLocalforageData = localStorage.getItem(key);
		return getLocalforageData ? JSON.parse(getLocalforageData) : null;
	} catch (e) {
		return null;
	}
};

export const setPrimitive = (key, value): void => {
	localStorage.setItem(key, value.toString());
};

export const getPrimitive = (key): string => {
	return localStorage.getItem(key);
};

export const removeKey = (key): void => localStorage.removeItem(key);

export const clear = (): void => {
	localStorage.clear();
};

export const token = (): string => {
	const auth = getObject(AuthEnum.TOKEN_KEY) as Auth;
	return auth?.access_token;
};
