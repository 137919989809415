import * as Unicons from '@iconscout/react-unicons';
import { Button, Input, Select } from "antd";
import { useState } from "react";
import { useTarefaContext } from "../../context/tarefa.service";
import { Checkboxtag } from "../Checkboxtag";
import { ModalFilter } from '../Modal/filter';
import { ModalTarefaNew } from "../Modal/tarefanew";
import "./_header.scss";

interface Props {
    filtros?: boolean
    modal?: 'equipe' | 'users' | 'cargo' | 'lead' | 'tarefa' | 'tarefaConcluida'
    onFunil?: (e: any) => void
    onList?: (e: any) => void
    estilo?: string

}

export const HeaderTarefas = ({ filtros, modal, onFunil, onList, estilo, }: Props) => {

    const { Option } = Select;


    function handleChange(value: any) {
        return;
    }

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    const { onChangePesquisa, totalTarefas,
        totalTarefasResultado, state,
        onFiltroRetornoClick,
        filtroResponsavelTarefa,
        setFiltroResponsavelTarefa,
    } = useTarefaContext();

    const onChangeModal = () => {
        if (isOpenModal === true) {
            setIsOpenModal(false)
        } else {
            setIsOpenModal(true)
        }
    }

    const onCloseModal = () => {
        setIsOpenModal(false)
    }

    const onChangeModalFilter = () => {
        if (isOpenFilter === true) {
            setIsOpenFilter(false)
        } else {
            setIsOpenFilter(true)
        }
    }

    const onCloseFilter = () => {
        setIsOpenFilter(false)
    }

    function wordButton(modal: string | undefined) {
        switch (modal) {
            case 'lead':
                return 'Novo Lead';
            case 'tarefa':
                return 'Criar Tarefa';
            case 'cargo':
                return 'Novo Cargo';
            case 'users':
                return 'Novo Usuario';
            case 'equipe':
                return 'Novo Equipe';
            default:
                return 'Botão'
        }
    }

    return (
        <>
            {!modal ?
                <>
                    <div className="header-box flex justify-center" style={{ height: 150 }}>
                        <div className="search-box flex flex-row justify-between align-center">
                            <div className="flex flex-row justify-start align-center w-75">
                                <div className="select-box w-25 mr-5">
                                    <div className="text-over-input">
                                        <small>Equipes</small>
                                    </div>
                                    <div className="input-modal mb-10">
                                        <Select style={{ width: '100%' }} bordered={false} defaultValue="todos" onChange={handleChange}>
                                            <Option value="todos">Todos</Option>
                                            <Option value="lucy">Lucy</Option>
                                            <Option value="Yiminghe">yiminghe</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="select-box w-25 mr-5">
                                    <div className="text-over-input">
                                        <small>Equipes</small>
                                    </div>
                                    <div className="input-modal mb-10">
                                        <Select style={{ width: '100%' }} bordered={false} defaultValue="todos" onChange={handleChange}>
                                            <Option value="todos">Todos</Option>
                                            <Option value="lucy">Lucy</Option>
                                            <Option value="Yiminghe">yiminghe</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="select-box w-25 mr-5">
                                    <div className="text-over-input">
                                        <small>Equipes</small>
                                    </div>
                                    <div className="input-modal mb-10">
                                        <Input bordered={false} />
                                        <Unicons.UilCalender size={20} color="var(cinza-azulado-500)" />
                                    </div>
                                </div>
                                <div className="select-box w-25 mr-5">
                                    <div className="text-over-input">
                                        <small>Equipes</small>
                                    </div>
                                    <div className="input-modal mb-10">
                                        <Input bordered={false} />
                                        <Unicons.UilCalender size={20} color="var(cinza-azulado-500)" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row w-25 justify-end">
                                <Button className="button-blue">Limpar</Button>
                                <Button className="button-primary ml-10">Filtar</Button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="header-box">
                        <div className="search-box flex flex-row justify-between align-center">
                            <div className="flex flex-row justify-start align-center">
                                <div className="input-happy">
                                    <Input bordered={false} onChange={onChangePesquisa} placeholder="Buscar leads" />
                                    <Unicons.UilSearch size="20" color="var(--cinza-azulado-500)" />
                                </div>
                                <div className="total flex flex-row align-center">
                                    <h6>Total de Resultados</h6>
                                    <p>{totalTarefasResultado}/{totalTarefas} Tarefas</p>
                                </div>
                            </div>
                            {/* <Button className="button-primary"
                onClick={onChangeModal}
              >
                Criar tarefa
              </Button> */}
                        </div>
                        {filtros &&
                            <>
                                <div className="flex flex-row justify-content-between align-center">
                                    <div className="input-happy flex flex-row justify-start align-center"
                                        style={{ minWidth: 'unset', flex: 1 }}
                                    >
                                        <Select
                                            dropdownStyle={{ zIndex: 2000 }}
                                            style={{ width: '100%', }}
                                            bordered={false}
                                            defaultValue="todas"
                                            onChange={(e) => setFiltroResponsavelTarefa(e)}
                                            value={filtroResponsavelTarefa}
                                        >
                                            <Select.Option value="todas">Todas as tarefas</Select.Option>
                                            <Select.Option value="minhas">Minhas tarefas</Select.Option>
                                        </Select>
                                    </div>

                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}></div>
                                    <div className={`flex flex-row justify-start align-center`}>
                                        <Checkboxtag
                                            tipo='atrasado'
                                            state={state}
                                            onFiltroRetornoClick={onFiltroRetornoClick}
                                        />
                                        <Checkboxtag
                                            tipo='hoje'
                                            state={state}
                                            onFiltroRetornoClick={onFiltroRetornoClick}
                                        />
                                        <Checkboxtag
                                            tipo='futuro'
                                            state={state}
                                            onFiltroRetornoClick={onFiltroRetornoClick}
                                        />
                                        <Checkboxtag
                                            tipo='tarefaConcluida'
                                            state={state}
                                            onFiltroRetornoClick={onFiltroRetornoClick}
                                        />
                                        {/* <div onClick={onChangeModalFilter} className="filter-box flex justify-center align-center">
                      <Unicons.UilFilter color="var(--white)" size={20} />
                    </div> */}
                                    </div>
                                </div>

                                <ModalFilter open={isOpenFilter} onClose={onCloseFilter} />
                            </>
                        }
                    </div>

                    <ModalTarefaNew open={isOpenModal} onClose={onCloseModal} />
                </>
            }
        </>
    );
}
