/* eslint-disable @typescript-eslint/no-empty-function */
import { AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AuthEnum } from '../enum/auth.enum';
import { Operators } from '../enum/operators.enum';
import { Auth } from '../models/auth.model';
import { ICrmeLeadCustomDTO, ICrmeLeadEstagioDTO, ICrmeLeadHistoricoDTO, IUnidadeDTO, IUserAuthDTO } from '../models/happy-code-api.model';
import { Predicate } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import { useAreaAtuacaoService } from '../services/area-atuacao';
import { useCursoService } from '../services/curso.service';
import { useEstadoService } from '../services/estado.service';
import { useCrmeLeadCampanhaService } from '../services/lead-campanha.service';
import { useLeadEstagioService } from '../services/lead-estagio.service';
import { useLeadHistoricoTipoService } from '../services/lead-historico-tipo.service';
import { useLeadHistoricoService } from '../services/lead-historico.service';
import { useLeadOrigemService } from '../services/lead-origem.service';
import { useLeadTarefaTipoService } from '../services/lead-tarefa-tipo.service';
import { useLeadTarefaService } from '../services/lead-tarefa.service';
import { useLeadTipoService } from '../services/lead-tipo.service';
import { useMunicipioService } from '../services/municipio';
import { useNotificationService } from '../services/notification.service';
import { usePaisService } from '../services/pais.service';
import { useUnidadeService } from '../services/unidade.service';
import { useUsuarioService } from '../services/usuario.service';
import { useVwLeadService } from '../services/vwlead.service';
import { getObject, setObject } from '../util/store';
import { useGlobalContext } from './global.service';
import { PerfilEnum } from '../enum/perfil.enum';

//Tipando as Props do contexto
interface PropsUserContext {
	usuario: IUserAuthDTO;
	unidade: IUnidadeDTO;
	token: Auth;
	setToken: React.Dispatch<React.SetStateAction<Auth>>;
	setUsuario: React.Dispatch<React.SetStateAction<IUserAuthDTO>>;
	setUnidade: React.Dispatch<React.SetStateAction<IUnidadeDTO>>;
	state: any;
	setState: any;
	onFiltroRetornoClick: any;
	onChangePesquisa: any;
	refreshLeads: any;
	refresh: any;
	totalLeads: number;
	totalLeadsResultado: number;
	leadSelecionado: ICrmeLeadCustomDTO;
	setLeadSelecionado;
	historicosAgrupados: any[];
	fetchHistoricos: any;
	funilView;
	setFunilView;
	leadResponse;
	onPageChange;
	flPossuiFiltro;
	limparFiltros;
	onRemoverBuscaClick;
	setFlModoEdicaoTarefa;
	flModoEdicaoTarefa;
	refreshLeadSelecionado;
	refreshLeadsEstagioEspecifico;
	onHistoricoPageChange;
	historicoResponse: AxiosResponse<PageableResponse<ICrmeLeadHistoricoDTO>>;
}

//Valor default do contexto
const DEFAULT_VALUE = {
	isGlobalLoading: false,
	setState: () => { },
	usuario: {
		id: null,
		usuario: '',
		login: '',
		perfil: '',
		funcao: '',
		active: true,
		arquivoUrl: '',
		arquivo: null,
		enabled: true,
		password: '',
		username: '',
		credentialsNonExpired: true,
		authorities: null,
		accountNonExpired: true,
		accountNonLocked: true,
		ativo: true,
		acessoB2c: false,
		acessoB2b: false,
		acessoComercial: false
	},
	unidade: { id: null } as IUnidadeDTO,
	token: {
		access_token: null,
	},
	logout: () => { }, //função de inicialização
	setToken: () => { }, //função de inicialização
	setUsuario: () => { }, //função de inicialização
	setUnidade: () => { }, //função de inicialização
	setIsGlobalLoading: () => { }, //função de inicialização
	setUsuarioForm: () => { }, //função de inicialização
	// setPopLoading: () => { }, //função de inicialização
	state: null,
	onFiltroRetornoClick: () => { },
	onChangePesquisa: () => { },
	totalLeads: 0,
	totalLeadsResultado: 0,
	refreshLeads: () => { },
	refresh: () => { },
	leadSelecionado: null,
	setLeadSelecionado: () => { },
	fetchHistoricos: () => { },
	historicosAgrupados: null,
	funilView: true,
	setFunilView: () => { },
	onPageChange: () => { },
	leadResponse: null,
	flPossuiFiltro: null,
	limparFiltros: () => { },
	onRemoverBuscaClick: () => { },
	setFlModoEdicaoTarefa: () => { },
	flModoEdicaoTarefa: false,
	refreshLeadSelecionado: () => { },
	refreshLeadsEstagioEspecifico: () => { },
	historicoResponse: null,
	onHistoricoPageChange: () => { },
};

const nameStorageLoginUnidade = '@HappyCode/login/unidade';

//criando nosso contexto UserContext
const LeadContext = createContext<PropsUserContext>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const LeadContextProvider = ({ children }: any) => {
	const leadCampanhaService = useCrmeLeadCampanhaService();
	const areaAtuacaoService = useAreaAtuacaoService();
	const leadEstagioService = useLeadEstagioService();
	const paisService = usePaisService();
	const municipioService = useMunicipioService();
	const estadoService = useEstadoService();
	const leadTipoService = useLeadTipoService();
	const leadOrigemService = useLeadOrigemService();
	const leadTarefaService = useLeadTarefaService();
	const leadTarefaTipoService = useLeadTarefaTipoService();
	const unidadeService = useUnidadeService();
	const cursoService = useCursoService();
	const vwLeadService = useVwLeadService();
	const usuarioService = useUsuarioService();
	const leadHistoricoTipoService = useLeadHistoricoTipoService();
	const notification = useNotificationService();
	const leadHistoricoService = useLeadHistoricoService();

	const [funilView, setFunilView] = useState(true);
	const [flModoEdicaoTarefa, setFlModoEdicaoTarefa] = useState(false);

	const { setIsGlobalLoading } = useGlobalContext();
	const [usuario, setUsuarioGlobal] = useState(DEFAULT_VALUE.usuario);
	const [unidade, setUnidadeGlobal] = useState(DEFAULT_VALUE.unidade);
	const [token, setTokenGlobal] = useState(DEFAULT_VALUE.token);
	const [unidadeUsuarioLogado, setUnidadeUsuarioLogado] = useState(DEFAULT_VALUE.unidade);

	const [leadSelecionado, setLeadSelecionado] = useState<ICrmeLeadCustomDTO>();

	//const [historicos, setHistoricos] = useState<ICrmLeadHistoricoDTO[]>();
	const [historicosAgrupados, setHistoricosAgrupados] = useState<any[]>();
	const [leadResponse, setLeadResponse] = useState<any>();
	const [historicoResponse, setHistoricoResponse] = useState<AxiosResponse<PageableResponse<ICrmeLeadHistoricoDTO>>>();
	const [page, setPage] = useState<any>();
	const [pageHistorico, setPageHistorico] = useState<any>();

	const windowUrl = window.location.search;

	const getToken = () => {
		return getObject(AuthEnum.TOKEN_KEY);
	};

	const setToken = (token) => {
		setObject(AuthEnum.TOKEN_KEY, token);
		setTokenGlobal(token);
	};

	const setUsuario = (usuario) => {
		setObject(AuthEnum.USER_KEY, usuario);
		setUsuarioGlobal(usuario);
	};

	const setUnidade = (unidade) => {
		setObject(nameStorageLoginUnidade, unidade);
		setUnidadeGlobal(unidade);
		setUnidadeUsuarioLogado(unidade);
	};

	useMemo(() => {
		const params = new URLSearchParams(windowUrl);

		const tokenSalvo = getToken();
		const tokenUrl = params.get('t');
		const unidadeUrl = params.get('u');

		if ((!tokenUrl && !tokenSalvo?.access_token) || tokenUrl?.length <= 0) {
			notification({ description: 'Token inválido', type: 'error' });
			return;
		}

		setToken({
			access_token: tokenUrl || tokenSalvo?.access_token,
		});

		if (unidadeUrl) {
			try {
				const idUnidade = parseInt(unidadeUrl);
				unidadeService.findById({ id: idUnidade } as any).then((response) => {
					setUnidade(response.data);
				});
			} catch (e) {
				return;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window]);

	const [state, setState] = useState({
		campanhas: [],
		areasAtuacoes: [],
		leadStatus: [],
		leadEstagios: [],
		paises: [],
		estados: [],
		cidades: [],
		leadOrigens: [],
		leadHistoricoTipos: [],
		tarefaTipos: [],
		tarefas: [],
		leadTipos: [],
		unidades: [],
		cursos: [],
		boards: [],
		boardsFiltrados: [],
		boardsTemp: [],
		leadId: null,
		flRefresh: false,
		qtLeadsPorPaginas: 10,
		filtro: {
			flFiltrar: false,
			flResetarBoard: false,
			flFiltrarHistoricos: false,
			futuro: false,
			hoje: false,
			atrasado: false,
			novo: false,
			busca: '',
			tipoDataParaFiltrar: null,
			leadOrigemParaFiltrar: null,
			leadDataInicioParaFiltrar: null,
			leadDataFimParaFiltrar: null,
			avaliacaoParaFiltrar: null,
			campanha: false,
			municipio: false,
			areaAtuacao: false,
		},
	});

	const filtroDefault = {
		flFiltrar: false,
		flFiltrarHistoricos: false,
		futuro: false,
		hoje: false,
		atrasado: false,
		novo: false,
		busca: '',
		tipoDataParaFiltrar: null,
		leadOrigemParaFiltrar: null,
		leadDataInicioParaFiltrar: null,
		leadDataFimParaFiltrar: null,
		avaliacaoParaFiltrar: null,
		campanha: false,
		municipio: false,
		areaAtuacao: false,
	};

	useEffect(() => {
		if (state?.filtro?.busca?.length >= 3 || state?.filtro?.busca?.length === 0) {
			const typingTimeout = setTimeout(() => {
				refreshLeads();
			}, 800);
			return () => clearTimeout(typingTimeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.filtro?.busca]);

	useEffect(() => {
		if (state?.filtro?.flFiltrar) {
			refreshLeads();
		}
		if (state?.filtro?.flFiltrarHistoricos && leadSelecionado?.id) {
			fetchHistoricos();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.filtro]);

	useEffect(() => {
		if (page !== null && page !== undefined) {
			refreshLeads();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (pageHistorico !== null && pageHistorico !== undefined) {
			fetchHistoricos();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageHistorico]);

	const totalLeadsResultado = useMemo(() => {
		if (!funilView) {
			return leadResponse?.data?.['numberOfElements'] || 0;
		}
		if (state?.boards) {
			return state?.boards?.map((item: any) => item?.leads?.length).reduce((a, b) => a + b, 0);
		}
		return 0;
	}, [state?.boards, funilView, leadResponse]);

	const totalLeads = useMemo(() => {
		if (!funilView) {
			return leadResponse?.data?.['totalElements'] || 0;
		}

		if (state?.boards) {
			return state?.boards?.map((item: any) => item?.totalElements || 0).reduce((a, b) => a + b, 0);
		}
		return 0;
	}, [state?.boards, funilView, leadResponse]);

	const onChangePesquisa = (e: any) => {
		setState((state) => {
			state.filtro.busca = e.target.value;
			return { ...state, filtro: { ...state.filtro, flResetarBoard: true } };
		});
	};

	const onPageChange = (event, page) => {
		event.preventDefault();
		setPage(page);
	};

	const onHistoricoPageChange = (event, page) => {
		event.preventDefault();
		setPageHistorico(page);
	};

	useEffect(() => {
		if (funilView !== null && funilView !== undefined && (funilView === false || funilView === true)) {
			refreshLeads();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funilView]);

	const aplicarFiltros = async (estagio?, incrementPage = false) => {
		const predicate = new Predicate();
		predicate.sort = 'asc';
		predicate.size = state.qtLeadsPorPaginas;

		if (incrementPage && !estagio?.['last']) {
			predicate.page = estagio?.['number'] + 1;
			// predicate.size = (estagio?.['number'] + 1) * state.qtLeadsPorPaginas;
		}

		page !== null && page !== undefined && predicate.setPage(page);

		estagio && predicate.addOption('estagioCdLeadEstagio', estagio?.cdLeadEstagio, Operators.EQUAL);
		// predicate.addOption('unidade.id', unidadeUsuarioLogado.id, Operators.EQUAL);
		state?.filtro?.busca?.length >= 3 && predicate.addOption('dsNomeLead', state?.filtro?.busca, Operators.CONTAINS);

		predicate.addSort('dataCriacao');

		const dataPredicate = (day?: number, timeLimit?: boolean, timeStart?: boolean) => {
			const dataModificada = new Date();
			dataModificada.setDate(dataModificada.getDate() + day);

			if (timeStart) {
				dataModificada.setHours(0);
				dataModificada.setMinutes(1);
				const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");
				return dataFormatada;
			}
			if (timeLimit) {
				dataModificada.setHours(23);
				dataModificada.setMinutes(59);
				const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");
				return dataFormatada;
			}
			const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");

			return dataFormatada;
		}

		const filtro = state?.filtro;

		if (filtro?.atrasado && filtro?.hoje && filtro?.futuro) {
			predicate.addMultiOption('flPossuiTarefa', true, Operators.EQUAL);
		} else if (filtro?.atrasado && filtro?.hoje) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null, true), Operators.LESS_THAN);
		} else if (filtro?.atrasado && filtro?.futuro) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null), Operators.LESS_THAN);
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(+1, null, true), Operators.GREATER_THAN);
		} else if (filtro?.hoje && filtro?.futuro) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null), Operators.GREATER_THAN_OR_EQUAL);
		} else if (filtro?.atrasado) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null), Operators.LESS_THAN);
		} else if (filtro?.hoje) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null), Operators.GREATER_THAN_OR_EQUAL);
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(null, true), Operators.LESS_THAN_OR_EQUAL, true);
		} else if (filtro?.futuro) {
			predicate.addMultiOption('proximaTarefa.dhTarefa', dataPredicate(+1), Operators.GREATER_THAN);
		}

		filtro?.novo && predicate.addMultiOption('flPossuiTarefa', false, Operators.EQUAL);
		filtro?.campanha && predicate.addMultiOption('campanha.id', filtro?.campanha);
		filtro?.municipio && predicate.addMultiOption('endereco.cidade', filtro?.municipio);
		filtro?.areaAtuacao && predicate.addMultiOption('dsAreaAtuacao', filtro?.areaAtuacao);
		filtro?.leadOrigemParaFiltrar?.id && predicate.addOption('origem.id', filtro?.leadOrigemParaFiltrar?.id, Operators.EQUAL);
		filtro?.avaliacaoParaFiltrar !== null && filtro?.avaliacaoParaFiltrar !== undefined && predicate.addMultiOption('nrAvaliacaoLead', filtro?.avaliacaoParaFiltrar, Operators.EQUAL);

		const addFiltroData = () => {
			let atributoParaFiltrar;
			const dataInicio = filtro?.leadDataInicioParaFiltrar;
			const dataFim = filtro?.leadDataFimParaFiltrar;

			const dataFinal = parse(dataFim, 'dd/MM/yyyy', new Date());
			dataFinal.setHours(23);
			dataFinal.setMinutes(59);
			const dataFormatada = format(dataFinal, "yyyy-MM-dd'T'HH:mm");
			const dataInicioFormatada = format(parse(dataInicio, 'dd/MM/yyyy', new Date()), "yyyy-MM-dd'T'HH:mm");

			if (filtro?.tipoDataParaFiltrar === 'data_retorno') {
				atributoParaFiltrar = 'proximaTarefa.dhTarefa';
			}
			if (filtro?.tipoDataParaFiltrar === 'data_criacao') {
				atributoParaFiltrar = 'dataCriacao';
			}

			predicate.addMultiOption(atributoParaFiltrar, dataInicioFormatada, Operators.GREATER_THAN_OR_EQUAL);
			predicate.addMultiOption(atributoParaFiltrar, dataFormatada, Operators.LESS_THAN_OR_EQUAL, true);
		};

		if (filtro?.tipoDataParaFiltrar?.length > 0 && !!filtro?.leadDataInicioParaFiltrar && !!filtro?.leadDataFimParaFiltrar) {
			addFiltroData();
		}

		const leadResponse = await vwLeadService.findCustomIndex(predicate);

		if (!estagio || !funilView) {
			return leadResponse;
		}

		estagio['totalElements'] = 0;
		if (leadResponse.status >= 200 && leadResponse.status <= 299) {
			estagio.leads = state?.filtro?.flResetarBoard ? [...leadResponse.data.content] : (estagio.leads || []).concat([...leadResponse.data.content]);
			estagio['totalElements'] = leadResponse.data.totalElements;
			estagio['totalPages'] = leadResponse.data.totalPages;
			estagio['numberOfElements'] = leadResponse.data.numberOfElements;
			estagio['number'] = leadResponse.data.number;
			estagio['size'] = leadResponse.data.size;
			estagio['last'] = leadResponse.data.last;
		}
		return estagio;
	};

	const refreshLeads = async (estagiosTemp: ICrmeLeadEstagioDTO[] = null, flExibirLoading = true) => {
		flExibirLoading && setIsGlobalLoading(true);
		let estagios = estagiosTemp || state?.boards;

		if (funilView) {
			estagios = await Promise.all(
				estagios.map(async (estagio: ICrmeLeadEstagioDTO) => {
					return await aplicarFiltros(estagio);
				})
			);
			setState((state) => ({
				...state,
				boards: [...estagios],
				boardsTemp: [...estagios],
				flRefresh: true,
				filtro: { ...state?.filtro, flFiltrar: false },
			}));
		} else {
			const leadResponse = await aplicarFiltros();
			setLeadResponse(leadResponse);
		}

		flExibirLoading && setIsGlobalLoading(false);

		return Promise.resolve();
	};

	const refreshLeadsEstagioEspecifico = async (estagiosTemp: ICrmeLeadEstagioDTO) => {
		if (funilView) {
			setIsGlobalLoading(true);
			const estagio = await aplicarFiltros(estagiosTemp, true);
			setState((state) => {
				const novosBoards = state?.boards?.map?.((board) => {
					if (estagiosTemp.cdLeadEstagio === board?.cdLeadEstagio) {
						board = { ...estagio };
					}
					return board;
				});
				return {
					...state,
					boards: novosBoards,
					boardsTemp: novosBoards,
					flRefresh: true,
					filtro: { ...state?.filtro, flFiltrar: false, flResetarBoard: false },
				};
			});
			setIsGlobalLoading(false);
		}
	};

	const onFiltroRetornoClick = (tipo, valor) => {
		setPage(null);
		setState((state) => ({
			...state,
			filtro: {
				...state.filtro,
				[tipo]: valor,
				flFiltrar: true,
				flResetarBoard: true,
			},
		}));
	};

	const refresh = async () => {
		setIsGlobalLoading(true);

		const predicate = new Predicate();
		predicate.addSort('nrLeadEstagioOrdem');

		const estagios: ICrmeLeadEstagioDTO[] = await leadEstagioService.findList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, leadEstagios: response.data }));
				return response.data;
			}
			return null;
		});

		if (!estagios) {
			// || !unidadeUsuarioLogado?.id) {
			setIsGlobalLoading(false);
			return;
		}

		await refreshLeads(estagios, true).finally(() => {
			setIsGlobalLoading(false);
		});
	};

	useEffect(() => {
		if (state?.qtLeadsPorPaginas && token) {
			refresh();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.qtLeadsPorPaginas, token]);

	const aplicarFiltroHistorico = (predicate: Predicate) => {
		// state?.filtroHistorico && state?.filtroHistorico?.[] &&
		//   predicate.addOption('leadHistoricoTipo.cdTipoLeadHistorico', state?.filtro?.leadHistoricoTipo);
	};
	const fetchHistoricos = (novosPredicate?: Array<any>) => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.sort = 'asc';
		predicate.addOption('lead.id', leadSelecionado!.id);
		predicate.addSort('dataCriacao');
		predicate.setPage(pageHistorico);

		novosPredicate?.forEach((pred) => {
			predicate.addMultiOption(pred.key, pred.value);
		});

		setState((state) => ({
			...state,
			filtro: { ...state?.filtro, flFiltrarHistoricos: false },
		}));

		aplicarFiltroHistorico(predicate);

		leadHistoricoService
			.find(predicate)
			.then((response) => {
				if (response.status === 200) {
					setHistoricoResponse(response);
					const data = response.data?.content;
					//setHistoricos(response.data?.content.reverse());

					const groups = data.reduce((groups, game) => {
						const date = format(new Date(game.dataCriacao), 'yyyy-MM');
						if (!groups[date]) {
							groups[date] = [];
						}
						groups[date].push(game);
						return groups;
					}, {});

					const groupArrays = Object.keys(groups).map((date) => {
						return {
							data: date,
							leads: (groups[date] as []).sort((a: ICrmeLeadHistoricoDTO, b: ICrmeLeadHistoricoDTO) => {
								if (a.dataCriacao < b.dataCriacao) {
									return 1;
								}

								if (a.dataCriacao > b.dataCriacao) return -1;

								return 0;
							}),
						};
					});

					setHistoricosAgrupados(groupArrays);
					return;
				}
				throw new Error();
			})
			.catch((e) => {
				notification({
					description: 'Falha ao buscar o histórico',
					type: 'error',
				});
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	useEffect(() => {
		if (!unidadeUsuarioLogado || !token) return;

		setIsGlobalLoading(true);

		leadCampanhaService.findList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, campanhas: response.data }));
			}
		});

		areaAtuacaoService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, areasAtuacoes: response.data }));
			}
		});


		leadHistoricoTipoService.findList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, leadHistoricoTipos: response.data }));
			}
		});

		// leadStatusService.findList().then((response) => {
		//   if (response.status === 200) {
		//     setState((state) => ({ ...state, leadStatus: response.data }));
		//   }
		// });

		// leadEstagioService.findList().then((response) => {
		//   if (response.status === 200) {
		//     setState((state) => ({ ...state, leadEstagios: response.data }));
		//   }
		// });

		paisService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, paises: response.data }));
			}
		});

		estadoService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, estados: response.data }));
			}
		});

		municipioService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, cidades: response.data }));
			}
		});

		leadTipoService.findList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, leadTipos: response?.data }));
			}
		});

		leadOrigemService.findList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, leadOrigens: response?.data }));
			}
		});

		unidadeService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, unidades: response?.data }));
			}
		});

		cursoService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, cursos: response?.data }));
			}
		});

		leadTarefaTipoService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, tarefaTipos: response?.data }));
			}
		});

		leadTarefaService.findCleanList().then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, tarefas: response?.data }));
			}
		});

		usuarioService.getPrincipal().then((response) => {
			setUsuario(response.data)
		})

		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addMultiOption('perfil.descricao', PerfilEnum.SEDE)
		usuarioService.findCleanList(predicate).then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, usuarios: response?.data }));
			}
		});

		refresh();

		// const intervalId = setInterval(() => {
		//   refresh()
		// }, 10000);

		// return () => clearInterval(intervalId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeUsuarioLogado, token]);

	useEffect(() => {
		const usuario = getObject(AuthEnum.USER_KEY);
		if (usuario) {
			setUsuarioGlobal(usuario);
		}

		const unidade = getObject(nameStorageLoginUnidade);
		if (unidade) {
			setUnidadeGlobal(unidade);
		}

		// setToken()
		// if (token) {
		// setTokenGlobal(token);
		// }
	}, []);

	useEffect(() => {
		if (!leadSelecionado) return;
		setHistoricosAgrupados(null);
		fetchHistoricos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadSelecionado?.id]);

	const limparFiltros = () => {
		setState((state) => ({
			...state,
			filtro: {
				...filtroDefault,
				busca: state?.filtro?.busca,
				flFiltrar: true,
				flResetarBoard: false,
			},
		}));
	};

	const onRemoverBuscaClick = () => {
		setState((state) => ({
			...state,
			filtro: {
				...state?.filtro,
				busca: '',
				flFiltrar: true,
				flResetarBoard: true,
			},
		}));
	};

	const flPossuiFiltro = useMemo(() => {
		const filtro = state?.filtro;
		return (
			filtro?.flFiltrar ||
			filtro?.futuro ||
			filtro?.hoje ||
			filtro?.atrasado ||
			filtro?.novo ||
			filtro?.tipoDataParaFiltrar ||
			filtro?.leadOrigemParaFiltrar ||
			filtro?.leadDataInicioParaFiltrar ||
			filtro?.leadDataFimParaFiltrar ||
			filtro?.avaliacaoParaFiltrar ||
			filtro?.campanha ||
			filtro?.municipio ||
			filtro?.areaAtuacao
		);
	}, [state]);

	const refreshLeadSelecionado = () => {
		if (!leadSelecionado?.id) return;

		setIsGlobalLoading(true);
		vwLeadService
			.findCustomIndexById({ id: leadSelecionado.id })
			.then((response) => {
				setLeadSelecionado({ ...response?.data });
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return (
		<LeadContext.Provider
			value={{
				usuario,
				unidade,
				token,
				setToken,
				setUsuario,
				setUnidade,
				state,
				setState,
				onFiltroRetornoClick,
				onChangePesquisa,
				totalLeads,
				totalLeadsResultado,
				refreshLeads,
				refresh,
				leadSelecionado,
				setLeadSelecionado,
				historicosAgrupados,
				fetchHistoricos,
				funilView,
				setFunilView,
				leadResponse,
				onPageChange,
				flPossuiFiltro,
				limparFiltros,
				onRemoverBuscaClick,
				setFlModoEdicaoTarefa,
				flModoEdicaoTarefa,
				refreshLeadSelecionado,
				refreshLeadsEstagioEspecifico,
				historicoResponse,
				onHistoricoPageChange,
			}}
		>
			{children}
		</LeadContext.Provider>
	);
};

export const useLeadContext = (): PropsUserContext => {
	const context = useContext(LeadContext);
	if (!context) throw new Error('useLeadContext must be used within a LeadContextProvider');
	return context;
};
