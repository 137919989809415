import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { Itemcargo } from "../../components/Item/cargo";
import { Pagination } from 'antd';

export const Cargos = () => {

  return (
    <>
      <div className="container-happy">
        <Sidebar cargos={true} />
        <div className="dashboard">
          <Header filtros={false} modal="cargo"/>
          <div className="area">
            <div className="header-area">
              <h4>Gerenciar cargos</h4>
            </div>
            <div className="body-area">
              <div className="table flex">
                <div className="table-header flex flex-row justify-start align-center">
                  <div style={{width:60}} className="table-item">
                    <span></span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Cargo</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Dashboard</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>CRM</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Gerenciar Cargos</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Gerenciar Usuarios</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Gerenciar Equipes</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Lista de Contatos</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Participa do Rodízio</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Importar Dados</span>
                  </div>
                  <div style={{width:250}} className="table-item flex align-center">
                    <span>Exportar Dados</span>
                  </div>
                </div>
                <div className="table-items">
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                  <Itemcargo item={[
                    'Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    'Acesso de Administrador',
                    true,
                    true,
                    false
                  ]} />
                </div>
                <div className="table-footer flex flex-end">
                  <Pagination defaultCurrent={1} total={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
