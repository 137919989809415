import { useState } from "react";
import { Button, Collapse, Space  } from 'antd';
import { Tag } from '../../components/Tag';
import { ModalUsuarioEditar } from '../../components/Modal/usereditar';


interface Props {
  open:boolean;
  onClose: ()=>void;
}

export const ModalUsuarioDetalhe = (props: Props) =>{
  const { open, onClose } = props;
  const { Panel } = Collapse;

  const [isOpen, setIsOpen] = useState(false);

  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }


  if (!open) return null

  return(
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Detalhes do Usuario</h3>
          <Space direction="vertical">
            <Collapse collapsible="header" defaultActiveKey={['1']}>
              <Panel header="Dados pessoais" key="1">
                <div className='flex flex-row mb-10'>
                  <div className="w-50">
                    <h6>Nome</h6>
                    <h4>Rosiane</h4>
                  </div>
                  <div className="w-50">
                    <h6>Sobrenome</h6>
                    <h4>Camargo</h4>
                  </div>
                </div>
                <div className='flex flex-row mb-10'>
                  <div className="w-50">
                    <h6>Status</h6>
                    <h4>Ativo</h4>
                  </div>
                  <div className="w-50">
                    <h6>E-mail</h6>
                    <h4>rosiane@happyeduca.com</h4>
                  </div>
                </div>
                <div className='flex flex-row'>
                  <div className="w-50">
                    <h6>Cargo</h6>
                    <h4>Gestor Comercial</h4>
                  </div>
                  <div className="w-50">
                    <h6>Grupo</h6>
                    <h4>Happy Brasil</h4>
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Collapse collapsible="header">
              <Panel header="Equipes" key="2">
                <div className="flex flex-row flex-wrap">
                  <Tag icon="users" text="Goiânia/GO" />
                  <Tag icon="users" text="Goianésia/GO" />
                  <Tag icon="users" text="Caldas Novas/GO" />
                </div>
              </Panel>
            </Collapse>
            <Collapse collapsible="header">
              <Panel header="Rodizio" key="3">
                Todas equipes
              </Panel>
            </Collapse>
          </Space>
          <div className="flex flex-row justify-end w-100 mt-20">
            <Button onClick={onChangeModal}  className="button-blue mr-5">Editar</Button>
            <Button onClick={onClose}  className="button-primary">Oka</Button>
          </div>
        </div>
      </div>
      <ModalUsuarioEditar
        open={isOpen}
        onClose={onClose1}
      />
    </>
  )
}
