import {
	addDays,
	differenceInDays,
	format,
	isAfter,
	isBefore,
	isEqual,
	parseISO,
} from 'date-fns';
import moment from 'moment';

export const formatMoney = (value: number) =>
	value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

export const formatPercent = (value: number) => {
	return `${(value * 100).toFixed(1)}%`;
};

export const formattedDate = (date) =>
	date && format(parseISO(date), 'dd/MM/yyyy');

export const toMoment = (date) => date && moment(date);

export const isDateBetween = (dateIni, dateEnd) => {
	const today = new Date();
	dateIni = parseISO(dateIni).setHours(0, 0, 0, 0);
	dateEnd = parseISO(dateEnd).setHours(23, 59, 59, 0);
	return (
		isEqual(today, dateEnd) ||
		isEqual(today, dateIni) ||
		(isAfter(today, dateIni) && isBefore(today, dateEnd))
	);
};

export const isAfterDate = (date) => {
	const today = new Date();
	return isAfter(today, parseISO(date));
};

export const isBeforeDate = (date) => {
	const today = new Date();
	return isBefore(today, parseISO(date));
};

export const daysBetween = (date) =>
	isAfterDate(date) ? differenceInDays(new Date(), parseISO(date)) : 0;

export const daysBetweenBefore = (date) =>
	isBeforeDate(date) ? differenceInDays(parseISO(date), new Date()) : 0;

export const formatMomentLocalDateTime = (date) =>
	date && date.format('YYYY-MM-DDT00:00:00.000');

export const formatMomentLocalDate = (date) =>
	date && date.format('YYYY-MM-DD');

export const nowDateTime = () => {
	return format(new Date(), "yyyy-MM-dd'T'00:00:00.000");
};

export const removeSecondsFromHour = (hour: string) =>
	hour.split(':').slice(0, -1).join(':');

export const nowDate = () => {
	return format(new Date(), 'yyyy-MM-dd');
};

export const nowDateAddDays = (qtd) => {
	return addDays(new Date(), qtd);
};

export const getMonthName = (monthNumber: number): string => {
	return moment.months(monthNumber - 1);
};

export const currencyFormatter = (value) => {
	return new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: 'BRL',
	}).format(value);
};

export const formatValue = (valor: number): string => {
	const value = parseInt(valor?.toString())
	const suffixes = ['', 'K', 'M', 'B', 'T'];
	const suffixNum = Math.floor(('' + value).length / 3);
	const divisao = 1000;
	let shortValue = parseFloat((suffixNum !== 0 ? (value / divisao) : value).toPrecision(2));
	if (shortValue % 1 !== 0) {
		shortValue = Number(shortValue.toFixed(1));
	}
	return shortValue + suffixes[suffixNum];
};

export const currencyParser = (val) => {
	try {
		// for when the input gets clears
		if (typeof val === 'string' && !val.length) {
			val = '0.0';
		}

		// detecting and parsing between comma and dot
		const group = new Intl.NumberFormat('pt-br').format(1111).replace(/1/g, '');
		const decimal = new Intl.NumberFormat('pt-br')
			.format(1.1)
			.replace(/1/g, '');
		let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
		reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
		//  => 1232.21 €

		// removing everything except the digits and dot
		reversedVal = reversedVal.replace(/[^0-9.]/g, '');
		//  => 1232.21

		// appending digits properly
		const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
		const needsDigitsAppended = digitsAfterDecimalCount > 2;

		if (needsDigitsAppended) {
			reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
		}

		return Number.isNaN(reversedVal) ? 0 : reversedVal;
	} catch (error) {
		console.error(error);
	}
};

export const removeAcentos = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const formatCpf = (value: any) => {

	return value?.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1')
}

export const formatRG = (value: string): string => {
	const onlyDigits = value?.replace(/\D/g, ''); // Remove tudo que não é número
	const maxLength = 9; // Define o número máximo de dígitos para o RG

	const formattedRG = onlyDigits?.slice(0, maxLength).replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'); // Aplica a formatação desejada (por exemplo: 99.999.999-9)

	return formattedRG;
};

export const formatPhone = (value: any) => {
	return value?.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{4})\d+?$/, '$1')
}

export function validateEmail(email) {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
}

export const formatLoginNome = (nome: string) => {
	// Encontra o índice do caractere "@" na string
	const indiceArroba = nome?.indexOf('@');

	// Se o caractere "@" for encontrado na string, remove tudo o que vem depois dele
	if (indiceArroba !== -1) {
		nome = nome?.substring(0, indiceArroba);
	}

	// Substitui os pontos por espaços
	nome = nome?.replace(/\./g, ' ');

	// Divide a string em palavras separadas por espaços
	const palavras = nome?.split(' ');

	// Transforma a primeira letra de cada palavra em maiúscula
	const nomeFormatado = palavras?.map((palavra) => {
		return palavra.charAt(0).toUpperCase() + palavra.slice(1);
	}).join(' ');

	return nomeFormatado;
};

export const formatRemove3Horas = (data: Date): Date => {
	const novaData = new Date(data);
	novaData.setHours(data.getHours() - 3);
	return novaData;
}

//   const distance = formatDistance(
//     firstDate,
//     secondDate
//   );

//   // 24 horas

//   const relative = formatRelative(
//     firstDate,
//     secondDate
//   );

// Ontem às 16h
