import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button, Input, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { BoxDocumentos } from '../../components/BoxDocumentos';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { IArquivoCleanDTO, ICrmeLeadArquivoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useLeadArquivoService } from '../../services/lead-arquivo.service';
import { useNotificationService } from '../../services/notification.service';

export const ArquivosLead = () => {
	//const [tipoDocumentoList, setTipoDocumentoList] = useState<ITipoDocumentoCleanDTO[]>();
	const [leadDocumentos, setLeadDocumentos] = useState<ICrmeLeadArquivoDTO[]>();
	const [nmArquivo, setNmArquivo] = useState<string>();
	//const [vlTamanho, setVlTamanho] = useState<number>();
	const [predicateLeadDocumento] = useState<Predicate>(new Predicate());

	const leadArquivoService = useLeadArquivoService();
	const { setIsGlobalLoading } = useGlobalContext();
	const { leadSelecionado } = useLeadContext();
	const notification = useNotificationService();

	useEffect(() => {
		if (leadSelecionado.id) {
			fetchArquivos();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadSelecionado]);

	const fetchArquivos = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('lead.id', leadSelecionado.id);

		setIsGlobalLoading(true);
		return leadArquivoService
			.findList(predicate)
			.then((response) => {
				if (response.status === 200) {
					setLeadDocumentos(response.data);
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const props: UploadProps = {
		name: 'file',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status === 'done') {
				notification({ descrition: '' });
			} else if (info.file.status === 'error') {
				notification({});
			}
		},
	};

	const formatDefaultList = (files: ICrmeLeadArquivoDTO[]): UploadFile[] => {
		return files.map((file, index) => ({
			uid: index?.toString(),
			arquivoId: file?.arquivo?.id,
			id: file?.id,
			name: file?.arquivo?.codigo,
			status: 'done',
			fileName: file?.arquivo?.codigo,
			url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${file?.arquivo?.url}?name=${file?.arquivo?.codigo}`,
		}));
	};

	const getDefaultFileList = () => {
		if (leadDocumentos && leadDocumentos.length > 0) {
			return formatDefaultList(leadDocumentos);
		}
		return [];
	};

	const onUploadContratoMatricula = (uploads: UploadFile<IArquivoCleanDTO>[]) => {
		// setIsGlobalLoading(true);

		// const arquivo: ILeadArquivoDTO = uploads.map(({ response }) => ({
		//   lead: {
		//     id: leadSelecionado.id,
		//   },
		//   file: { id: response.id, codigo: response.codigo, url: response.url, ativo: true },
		//   id: null,
		//   ativo: true,
		//   nmArquivo: nmArquivo,
		//   vlTamanho: vlTamanho,
		// }))[0];

		// const formData = new FormData();
		// formData.append('file', formaData);

		// leadArquivoService.create(arquivo).then((response) => {
		//   setNmArquivo(null);
		//   setLeadDocumentos(null);
		//   setVlTamanho(null);
		//   return fetchArquivos();
		// }).finally(() => setIsGlobalLoading(false));
		fetchArquivos();
	};

	const getMatriculaDocumento = () => {
		predicateLeadDocumento.addOption('lead.id', leadSelecionado?.id);
		predicateLeadDocumento.addSort('dhInsert');
		return leadArquivoService.findList(predicateLeadDocumento)
			.then((response) => {
				setLeadDocumentos(response.data);
			});
	};

	const onRemoveContratoMatricula = (arquivo) => {
		// setIsGlobalLoading(true);
		// leadArquivoService.delete(arquivo).then(() => {
		//   Promise.all([getMatriculaDocumento()]).finally(() =>
		//     setIsGlobalLoading(false)
		//   );
		// }).catch(() => setIsGlobalLoading(false));
	};

	const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string) => {
		if (!allowedTypes) {
			return true;
		}

		if (type) {
			return allowedTypes.includes(type);
		}
	};

	const onDownload = (arquivo: ICrmeLeadArquivoDTO) => {
		// arquivoService.download(arquivo?.arquivo?., name).then((response) => {
		//   var data = new Blob([response.data], { type: 'text/csv' });
		//   var csvURL = window.URL.createObjectURL(data);
		//   var tempLink = document.createElement('a');
		//   tempLink.href = csvURL;
		//   tempLink.setAttribute('download', url);
		//   tempLink.click();
		// }).finally(() => {
		//   setIsGlobalLoading(false);
		// })

		setIsGlobalLoading(true);

		leadArquivoService
			.download(arquivo.arquivo?.url, arquivo?.arquivo?.codigo)
			.then((response) => {
				saveAs(response?.data, arquivo?.arquivo?.codigo);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const onDelete = (arquivo: ICrmeLeadArquivoDTO) => {
		setIsGlobalLoading(true);
		arquivo.ativo = false;
		leadArquivoService
			.patch(arquivo)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					notification({ description: 'Arquivo removido com sucesso!', type: 'success' });
					return fetchArquivos();
				}
				throw new Error();
			})
			.catch((e) => {
				notification({ description: 'Não foi possível remover o arquivo!', type: 'error' });
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	return (
		<div className="tarefa-panel">
			<div className="flex gap-10">
				<AttachFileIcon />
				<h1>Adicionar arquivos</h1>
			</div>
			<div className="box-documentos flex justify-between align-center mb-20 gap-20">
				<p>Selecione ou arraste um arquivo:</p>
				<div className="box-white flex align-center">
					<Input value={nmArquivo} onChange={(e) => setNmArquivo(e.target.value)} placeholder="Informe a descrição para o arquivo" bordered={false} />
				</div>

				<div className="upload-component">
					{/* <Upload key={'upload-box-test'}  headers={{ "Authorization": `Bearer ${token()}` }} action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} >
            <Button className="button-bronce" icon={<SearchIcon style={{ color: 'var(--white)' }} />}>Selecionar arquivo</Button>
          </Upload> */}

					<BoxDocumentos
						maxCount={1}
						statusDocumento={true}
						defaultFileList={getDefaultFileList()}
						title={'Contrato de matricula'}
						action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}crme/lead-arquivo/lead/${leadSelecionado.id}/upload?filename=${nmArquivo}`}
						subtitle={'Enviar o contrato assinado da matricula do aluno.'}
						setFileList={(fileList) => onUploadContratoMatricula(fileList)}
						beforeUpload={(file: UploadFile) => {
							if (!nmArquivo || nmArquivo?.length <= 0) {
								notification({ description: 'Informe uma descrição para o arquivo', type: 'error' });
								return false;
							}

							const isAllowedType = validateFileType(file, 'application/pdf');
							if (!isAllowedType) {
								notification({ description: 'É permitido somente arquivos .pdf', type: 'error' });
								return false;
							}

							//setVlTamanho(file.size);

							return true;
						}}
						onRemoveFile={(fileList) => onRemoveContratoMatricula(fileList)}
					/>
				</div>
			</div>
			<table className="w-100 mb-20">
				<thead>
					<tr>
						<th className="w-30 header-row pl-20">Descrição</th>
						<th className="w-30 header-row pl-20">Arquivo</th>
						<th className="w-20 header-row">Data</th>
						<th className="w-50 header-row" colSpan={2}>
							Tamanho
						</th>
					</tr>
				</thead>
				<tbody>
					{leadDocumentos?.map((arquivo) => {
						return (
							<tr key={arquivo.id}>
								<td className="flex align-center">
									<div className="circle-cinza">
										<PictureAsPdfIcon />
									</div>
									<p>{arquivo.arquivo?.nmArquivo || '--'}</p>
								</td>
								<td>
									<p>{arquivo.arquivo?.codigo}</p>
								</td>
								<td>
									<p>{format(new Date(arquivo.dataCriacao), 'dd/MM/yyyy')}</p>
								</td>
								<td>
									<p>{arquivo?.arquivo?.vlTamanho > 0 ? (arquivo.arquivo.vlTamanho / 1024).toFixed(2) : 0}KB</p>
								</td>
								<td className="flex align-center justify-end gap-10">
									<Button onClick={() => onDownload(arquivo)}>
										<CloudDownloadOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
									</Button>
									<Button onClick={() => onDelete(arquivo)}>
										<DeleteOutlineOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
