import { useState } from "react";
import { Button, Input, Select, Checkbox } from 'antd';
import { ModalResposta } from '../../components/Modal/resposta';

interface Props {
  open:boolean;
  onClose: ()=>void;
}

export const ModalUsuarioNew = (props: Props) =>{
  const { open, onClose } = props;
  const { Option } = Select;

  function handleChange(value:any) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);
  const onChangeModal = () => {
    if(isOpen===true){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

  return(
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Novo Usuario</h3>
          <div className="text-over-input">
            <small>Nome</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder="Rosiane" bordered={false} />
          </div>
          <div className="text-over-input">
            <small>Sobrenome</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder="Camargo" bordered={false} />
          </div>
          <div className="text-over-input">
            <small>Email</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder="camargo@happycode.com" bordered={false} />
          </div>
          <div className="text-over-input">
            <small>cargo</small>
          </div>
          <div className="input-modal mb-20">
            <Select style={{width:'100%'}} bordered={false} defaultValue="gestor" onChange={handleChange}>
              <Option value="gestor">Gestor(a) Comercial</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
          <div className="flex flex-column mb-20">
            <h4 className="mb-10">Equipes</h4>
            <div className="checkboxes flex flex-wrap">
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
              <div className="checkbox flex flex-row w-50">
                <Checkbox />
                <p>Goiânia/GO</p>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-end w-100">
            <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
            <Button onClick={onChangeModal} className="button-primary">Salvar</Button>
          </div>
        </div>
        <ModalResposta
          open={isOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do novo usuário foram cadastradas com sucesso."
          link="usuarios"
        />
      </div>
    </>
  )
}
