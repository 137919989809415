import "./styles.css";

interface Props {
    status?: string;
    limite?: number;
}

const TagStatus: React.FunctionComponent<Props> = ({ status }: Props) => {
    return (
        <>
            {status ?
                <div className="box-tag-status">
                    <p> {status}</p>
                </div >
                : null}
        </>
    );
}

export default TagStatus;