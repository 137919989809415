import RotateLoader from "react-spinners/RotateLoader";
import { useGlobalContext } from "../../context/global.service";

const GlobalLoading = (): any => {
    const { isGlobalLoading } = useGlobalContext();
    // let loadingLocal = 0;

    // http.interceptors.request.use(function (config) {
    //     // spinning start to show
    //     loadingLocal = loadingLocal + 1;
    //     setLoading(loadingLocal);
    //     return config
    // }, function (error) {
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);
    //     return Promise.reject(error);
    // });

    // http.interceptors.response.use(function (response) {
    //     // spinning hide
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);

    //     return response;
    // }, function (error) {
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);
    //     return Promise.reject(error);
    // });

    return isGlobalLoading ? (
        <div className="global-spinner-overlay" id="spinner">
            <RotateLoader color={'#7fd38f'} loading={isGlobalLoading} size={15} />
        </div>
    ) : null
}

export default GlobalLoading