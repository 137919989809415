import http from '../helpers/http-common';
import {
	ICrmeLeadTarefaCleanDTO,
	ICrmeLeadTarefaDTO,
	ICrmeLeadTarefaFullDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadTarefaService = () => {
	const resourceURL = 'crme/lead-tarefa';

	const patchFullCustom = (body) => {
		return http.patch(`${resourceURL}/custom/${body.id}`, body);
	}

	const findCustom = (predicate = new Predicate()) => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	}


	const findCustomList = (predicate = new Predicate()) => {
		return http.get(`${resourceURL}/custom/list`, formatHttpParams(predicate));
	}


	return {
		...baseCreate<ICrmeLeadTarefaCleanDTO, ICrmeLeadTarefaDTO, ICrmeLeadTarefaFullDTO>(resourceURL),
		...baseFindList<ICrmeLeadTarefaCleanDTO, ICrmeLeadTarefaDTO, ICrmeLeadTarefaFullDTO>(resourceURL),
		...baseUpdate<ICrmeLeadTarefaCleanDTO, ICrmeLeadTarefaDTO, ICrmeLeadTarefaFullDTO>(resourceURL),
		patchFullCustom,
		findCustom,
		findCustomList,
	};
};
