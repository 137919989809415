/* eslint-disable @typescript-eslint/no-empty-function */
import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthEnum } from "../enum/auth.enum";
import { Operators } from "../enum/operators.enum";
import { ICrmeLeadTarefaCustomCleanDTO } from "../models/happy-code-api.model";
import { Predicate } from "../models/predicate.model";
import { PageableResponse } from "../models/response.model";
import { useLeadTarefaService } from "../services/lead-tarefa.service";
import { useNotificationService } from "../services/notification.service";
import { useUnidadeService } from "../services/unidade.service";
import { getObject, setObject } from "../util/store";
import { useGlobalContext } from "./global.service";

//Tipando as Props do contexto
interface PropsUserContext {
  token: null,
  unidade: null,
  usuario: null,
  tarefaResponse: AxiosResponse<PageableResponse<ICrmeLeadTarefaCustomCleanDTO>>,
  onPageChange: (event, page) => void,
  totalTarefas: number,
  totalTarefasResultado: number,
  onChangePesquisa: (e) => void,
  onFiltroRetornoClick,
  state,
  filtroResponsavelTarefa,
  setFiltroResponsavelTarefa,
};

//Valor default do contexto
const DEFAULT_VALUE = {
  token: null,
  unidade: null,
  usuario: null,
  tarefaResponse: null,
  onPageChange: null,
  totalTarefas: 0,
  totalTarefasResultado: 0,
  onChangePesquisa: (e) => { },
  onFiltroRetornoClick: (tipo, valor) => { },
  state: null,
  filtroResponsavelTarefa: null,
  setFiltroResponsavelTarefa: null,
};

const nameStorageLoginUnidade = '@HappyCode/login/unidade';

//criando nosso contexto UserContext
const TarefaContext = createContext<PropsUserContext>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const TarefaContextProvider = ({ children }: any) => {

  const leadTarefaService = useLeadTarefaService();
  const unidadeService = useUnidadeService();

  const notification = useNotificationService();

  const [unidadeUsuarioLogado, setUnidadeUsuarioLogado] = useState(DEFAULT_VALUE.unidade);
  const [unidade, setUnidadeGlobal] = useState(DEFAULT_VALUE.unidade);
  const [usuario, setUsuarioGlobal] = useState(DEFAULT_VALUE.usuario);
  const [tarefaResponse, setTarefaResponse] = useState<AxiosResponse<PageableResponse<ICrmeLeadTarefaCustomCleanDTO>>>();
  const { setIsGlobalLoading } = useGlobalContext();
  const [page, setPage] = useState<any>();
  const [predicate] = useState(new Predicate());
  const [filtroResponsavelTarefa, setFiltroResponsavelTarefa] = useState<string>();

  const windowUrl = window.location.search;

  const getToken = () => {
    return getObject(AuthEnum.TOKEN_KEY);
  };

  const [token, setTokenGlobal] = useState<any>(getToken());

  useEffect(() => {
    if (!filtroResponsavelTarefa || !token?.access_token) return;

    refreshTarefas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroResponsavelTarefa, token]);

  const setToken = (token) => {
    setObject(AuthEnum.TOKEN_KEY, token);
    setTokenGlobal(token);
  };

  const setUsuario = (usuario) => {
    setObject(AuthEnum.USER_KEY, usuario);
    setUsuarioGlobal(usuario);
  };
  const setUnidade = (unidade) => {
    setObject(nameStorageLoginUnidade, unidade);
    setUnidadeGlobal(unidade);
    setUnidadeUsuarioLogado(unidade);
  };

  useMemo(() => {
    const params = new URLSearchParams(windowUrl);

    const tokenSalvo = getToken();
    const tokenUrl = params.get('t');
    const unidadeUrl = params.get('u');

    if ((!tokenUrl && !tokenSalvo?.access_token) || tokenUrl?.length <= 0) {
      notification({ description: 'Token inválido', type: 'error' })
      return;
    }

    setToken({
      access_token: tokenUrl || tokenSalvo?.access_token,
    });

    if (unidadeUrl) {
      try {
        const idUnidade = parseInt(unidadeUrl);
        unidadeService.findById({ id: idUnidade } as any).then((response) => {
          setUnidade(response.data);
        })
      } catch (e) {
        return;
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window])

  const [state, setState] = useState({
    flRefresh: false,
    qtLeadsPorPaginas: 10,
    filtro: {
      flFiltrar: false,
      flFiltrarHistoricos: false,
      futuro: false,
      hoje: false,
      atrasado: false,
      tarefaConcluida: false,
      busca: '',
      tipoDataParaFiltrar: null,
      leadOrigemParaFiltrar: null,
      leadDataInicioParaFiltrar: null,
      leadDataFimParaFiltrar: null,
      avaliacaoParaFiltrar: null,
    },
  });

  const filtroDefault = {
    flFiltrar: false,
    flFiltrarHistoricos: false,
    futuro: false,
    hoje: false,
    atrasado: false,
    tarefaConcluida: false,
    busca: '',
    tipoDataParaFiltrar: null,
    leadOrigemParaFiltrar: null,
    leadDataInicioParaFiltrar: null,
    leadDataFimParaFiltrar: null,
    avaliacaoParaFiltrar: null,

  }


  useEffect(() => {
    if (state?.filtro?.busca?.length >= 3 || state?.filtro?.busca?.length === 0) {
      const typingTimeout = setTimeout(() => {
        refreshTarefas();
      }, 800);
      return () => clearTimeout(typingTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.filtro?.busca]);

  useEffect(() => {
    if (state?.filtro?.flFiltrar) {
      refreshTarefas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.filtro])

  useEffect(() => {
    if (page !== null && page !== undefined) {
      refreshTarefas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])


  const refreshTarefas = () => {
    if (!token?.access_token) return;
    setIsGlobalLoading(true);
    predicate.removeAllOption();
    // predicate.addOption('flConcluido', false);

    // predicate.addOption('ativo', true);
    // predicate.size = 10;
    // predicate.setPage(page);


    aplicarFiltros();
    leadTarefaService.findCustom(predicate).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        setTarefaResponse(response);
      }
    }).finally(() => setIsGlobalLoading(false))
  }

  useEffect(() => {
    if (!token?.access_token) return;
    predicate.removeAllOption();
    predicate.addOption('ativo', true);
    predicate.size = 10;
    refreshTarefas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const onPageChange = (event, page) => {
    event.preventDefault();
    setPage(page);
  }


  const totalTarefasResultado = useMemo(() => {
    if (tarefaResponse) {
      return tarefaResponse?.data?.numberOfElements;
    }
    return 0;
  }, [tarefaResponse])

  const totalTarefas = useMemo(() => {
    if (tarefaResponse) {
      return tarefaResponse?.data?.totalElements;
    }
    return 0;
  }, [tarefaResponse])


  const onChangePesquisa = (e: any) => {
    setState((state) => {
      state.filtro.busca = e.target.value;
      return { ...state };
    })
  }

  const aplicarFiltros = async () => {
    predicate.sort = 'asc';
    predicate.size = 10;

    (state?.filtro?.busca?.length > 0) && predicate.addOption('lead.dsNomeLead', state?.filtro?.busca, Operators.CONTAINS);


    page !== null && page !== undefined && predicate.setPage(page);

    unidadeUsuarioLogado && predicate.addOption('lead.unidade.id', unidadeUsuarioLogado.id, Operators.EQUAL);
    state?.filtro?.busca?.length >= 3 && predicate.addOption('dsNomeLead', state?.filtro?.busca, Operators.CONTAINS);

    filtroResponsavelTarefa && filtroResponsavelTarefa === 'todas' && predicate.removeOption('responsaveis.responsavel.id')
    filtroResponsavelTarefa && filtroResponsavelTarefa === 'minhas' && predicate.addOption('responsaveis.responsavel.id', usuario.id)
    predicate.addSort('dataCriacao');

    const dataPredicate = (day?: number, timeLimit?: boolean, timeStart?: boolean) => {
      const dataModificada = new Date();
      dataModificada.setDate(dataModificada.getDate() + day);

      if (timeStart) {
        dataModificada.setHours(0);
        dataModificada.setMinutes(1);
        const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");
        return dataFormatada;
      }
      if (timeLimit) {
        dataModificada.setHours(23);
        dataModificada.setMinutes(59);
        const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");
        return dataFormatada;
      }
      const dataFormatada = format(dataModificada, "yyyy-MM-dd'T'HH:mm");

      return dataFormatada;
    }

    const filtro = state?.filtro;

    if (!filtro?.tarefaConcluida) {
      predicate.addMultiOption('flConcluido', false);
    } else {
      predicate.addMultiOption('flConcluido', true);
    }

    if (filtro?.atrasado && filtro?.hoje && filtro?.futuro) {
      predicate.addMultiOption('flPossuiTarefa', true, Operators.EQUAL);
    } else if (filtro?.atrasado && filtro?.hoje) {
      predicate.addMultiOption('dhTarefa', dataPredicate(null, true), Operators.LESS_THAN);
    } else if (filtro?.atrasado && filtro?.futuro) {
      predicate.addMultiOption('dhTarefa', dataPredicate(null), Operators.LESS_THAN);
      predicate.addMultiOption('dhTarefa', dataPredicate(+1, null, true), Operators.GREATER_THAN);
    } else if (filtro?.hoje && filtro?.futuro) {
      predicate.addMultiOption('dhTarefa', dataPredicate(null), Operators.GREATER_THAN_OR_EQUAL);
    } else if (filtro?.atrasado) {
      predicate.addMultiOption('dhTarefa', dataPredicate(null), Operators.LESS_THAN);
    } else if (filtro?.hoje) {
      predicate.addMultiOption('dhTarefa', dataPredicate(null), Operators.GREATER_THAN_OR_EQUAL);
      predicate.addMultiOption('dhTarefa', dataPredicate(null, true), Operators.LESS_THAN_OR_EQUAL, true);
    } else if (filtro?.futuro) {
      predicate.addMultiOption('dhTarefa', dataPredicate(+1), Operators.GREATER_THAN);
    }

    filtro?.leadOrigemParaFiltrar?.id && predicate.addOption('origem.id', filtro?.leadOrigemParaFiltrar?.id, Operators.EQUAL)
    filtro?.avaliacaoParaFiltrar !== null && filtro?.avaliacaoParaFiltrar !== undefined
      && predicate.addMultiOption('nrAvaliacaoLead', filtro?.avaliacaoParaFiltrar, Operators.EQUAL);

    const addFiltroData = () => {
      let atributoParaFiltrar;
      const dataInicio = filtro?.leadDataInicioParaFiltrar;
      const dataFim = filtro?.leadDataFimParaFiltrar;


      if (filtro?.tipoDataParaFiltrar === 'data_retorno') {
        atributoParaFiltrar = 'proximaTarefa.dhTarefa';
      }
      if (filtro?.tipoDataParaFiltrar === 'data_criacao') {
        atributoParaFiltrar = 'dataCriacao';
      }

      predicate.addMultiOption(atributoParaFiltrar, dataInicio, Operators.GREATER_THAN_OR_EQUAL);
      predicate.addMultiOption(atributoParaFiltrar, dataFim, Operators.LESS_THAN_OR_EQUAL, true);
    }

    filtro?.tipoDataParaFiltrar?.length > 0 &&
      !!filtro?.leadDataInicioParaFiltrar &&
      !!filtro?.leadDataFimParaFiltrar && addFiltroData();

    // const leadResponse = await leadTarefaService.findCustom(predicate);

  }

  const onFiltroRetornoClick = (tipo, valor) => {
    setPage(null);
    setState((state) => ({ ...state, filtro: { ...state.filtro, [tipo]: valor, flFiltrar: true } }));
  }

  useEffect(() => {
    if (!unidadeUsuarioLogado || !token?.access_token) return;

    setIsGlobalLoading(true);

    leadTarefaService.findCustom(predicate).then((response) => {
      if (response.status === 200) {
        setState((state) => ({ ...state, campanhas: response.data }));
      }
    });
    refreshTarefas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unidadeUsuarioLogado, token]);


  useEffect(() => {
    const usuario = getObject(AuthEnum.USER_KEY);
    if (usuario) {
      setUsuarioGlobal(usuario);
    }

    const unidade = getObject(nameStorageLoginUnidade);
    if (unidade) {
      setUnidadeGlobal(unidade);
    }
  }, []);


  const limparFiltros = () => {
    setState((state) => ({ ...state, filtro: { ...filtroDefault, busca: state?.filtro?.busca, flFiltrar: true }, }));
  }

  const onRemoverBuscaClick = () => {
    setState((state) => ({ ...state, filtro: { ...state?.filtro, busca: '', flFiltrar: true }, }));
  }

  const flPossuiFiltro = useMemo(() => {
    const filtro = state?.filtro;
    return filtro?.flFiltrar ||
      filtro?.futuro ||
      filtro?.hoje ||
      filtro?.atrasado ||
      filtro?.tarefaConcluida ||
      filtro?.tipoDataParaFiltrar ||
      filtro?.leadOrigemParaFiltrar ||
      filtro?.leadDataInicioParaFiltrar ||
      filtro?.leadDataFimParaFiltrar ||
      filtro?.avaliacaoParaFiltrar;
  }, [state])


  return (
    <TarefaContext.Provider
      value={{
        unidade,
        usuario,
        token,
        onPageChange,
        tarefaResponse,
        totalTarefas,
        totalTarefasResultado,
        onChangePesquisa,
        onFiltroRetornoClick,
        state,
        filtroResponsavelTarefa,
        setFiltroResponsavelTarefa,
      }}>
      {children}
    </TarefaContext.Provider>
  );
};

export const useTarefaContext = (): PropsUserContext => {
  const context = useContext(TarefaContext);
  if (!context)
    throw new Error(
      "useTarefaContext must be used within a GlobalContextProvider"
    );
  return context;
};
