import { useMemo, useState } from "react";
import { Button, Input, Select } from 'antd';
import { ModalResposta } from './resposta';
import { useGlobalContext } from "../../context/global.service";
import { useLeadService } from "../../services/lead.service";
import { useNotificationService } from "../../services/notification.service";
import { useLeadContext } from "../../context/lead.context.service";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalTarefaNew = (props: Props) => {
  const defaultValue = {
    dsNomeLead: null,
    cdEmail: null,
    cdDddCelular: null,
    cdCelular: null,
    origem: null,
    campanha: null,
  }

  const { open, onClose } = props;
  const { Option } = Select;
  const { setIsGlobalLoading } = useGlobalContext();
  const { state, unidade, refreshLeads } = useLeadContext();
  const [body, setBody] = useState(defaultValue);

  const leadService = useLeadService();
  const notification = useNotificationService();

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const emailValido = useMemo(() => {
    if (body?.cdEmail === null || body?.cdEmail === undefined) return false;

    return validateEmail(body?.cdEmail);
  }, [body?.cdEmail])

  function handleChange(value: any) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);

  const onChangeModal = () => {
    if (isOpen === true) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const onSalvarClick = () => {
    const novoBody = {
      ...body,
      origem: {
        id: body.origem,
      },
      campanha: {
        id: body.campanha,
      },
      estagio: {
        id: state?.boards?.[0]?.id,
      },
      tipo: {
        id: 1,
      },
      unidade: {
        id: unidade.id,
      },
    }

    setIsGlobalLoading(true);

    return leadService.create(novoBody).then((response) => {
      if (response?.status >= 200 && response?.status <= 299) {
        return refreshLeads();
      }

      throw new Error('Falha ao cadastrar o lead.');
    }).then((_) => {
      notification({ description: 'Lead cadastrado com sucesso!', type: 'success', message: 'Sucesso!' });
      onClose();
      setBody({ ...defaultValue })
    }).finally(() => {
      setIsGlobalLoading(false)
    })

  }

  const onClose1 = () => {
    setIsOpen(false)
  }

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Criar tarefa</h3>
          <div className="text-over-input">
            <small>Nome do lead</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder='Nome' bordered={false} onChange={(e) => setBody((body) => ({ ...body, dsNomeLead: e.target.value }))} />
          </div>
          <div className="text-over-input">
            <small>Email</small>
          </div>
          <div className="input-modal mb-20">
            <Input placeholder='Email' bordered={false} onChange={(e) => setBody((body) => ({ ...body, cdEmail: e.target.value }))} />
          </div>
          {!emailValido && body?.cdEmail?.length >= 0 && <small>Digite um e-mail válido</small>}


          <div className="flex flex-row">
            <div>
              <div className="text-over-input">
                <small>DDD</small>
              </div>
              <div className="flex flex-row">
                <div className="input-modal mb-20" style={{ maxWidth: 'unset', minWidth: 'unset' }}>
                  <div style={{ width: 100 }}>
                    <Input
                      type="number"
                      placeholder='DDD'
                      bordered={false}
                      maxLength={2}
                      onChange={(e) => setBody((body) => ({ ...body, cdDddCelular: e.target.value }))} />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-over-input">
                <small>Celular</small>
              </div>
              <div className="flex flex-row">
                <div className="input-modal mb-20" style={{ maxWidth: 'unset', minWidth: 'unset' }}>
                  <Input maxLength={9} placeholder='Celular' bordered={false} onChange={(e) => setBody((body) => ({ ...body, cdCelular: e.target.value }))} />
                </div>
              </div>
            </div>
          </div>

          <div className="text-over-input">
            <small>Selecione uma Origem</small>
          </div>
          <div className="input-modal mb-20">
            <Select
              style={{ width: '100%' }}
              bordered={false}
              defaultValue="todos"
              value={body?.origem ?? "todos"}
              onChange={(e) => setBody((body) => ({ ...body, origem: e }))}>
              <Option value="todos">Todos</Option>
              {state?.leadOrigens?.map?.((item) => <Option key={item?.id} value={item?.id}>{item?.dsLeadOrigem}</Option>)}
            </Select>
          </div>
          <div className="text-over-input">
            <small>Campanha</small>
          </div>
          <div className="input-modal mb-20">
            <Select
              style={{ width: '100%' }}
              bordered={false}
              defaultValue="todos"
              value={body?.campanha ?? "todos"}
              onChange={(e) => setBody((body) => ({ ...body, campanha: e }))}>
              <Option value="todos">Todos</Option>
              {state?.campanhas?.map?.((item) => <Option key={item?.id} value={item?.id}>{item?.dsLeadCampanha}</Option>)}
            </Select>
          </div>

          <div className="flex flex-row justify-end w-100">
            <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
            <Button onClick={onSalvarClick} className="button-primary">Salvar</Button>
          </div>
        </div>
        <ModalResposta
          open={isOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link=""
        />
      </div>
    </>
  )
}
