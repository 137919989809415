import { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import { ILogoutDTO, IMeDTO, ISendTextDTO, IStartSessionDTO } from "../models/happy-code-api.model";



export const useWhatsappLeadService = () => {
    const resourceURL = 'whatsapp/';

    const getWhatsappSession = (idUnidade: string): Promise<AxiosResponse<IMeDTO>> => {
        return http.get(`${resourceURL}me/${idUnidade}`);
    }

    const getWhatsappSessionStart = (idUnidade: string): Promise<AxiosResponse<IStartSessionDTO>> => {
        return http.post(`${resourceURL}start`, {
            name: idUnidade,
        })
    }

    const getQrCodeWhatsapp = (idUnidade: string) => {
        return http.get(`${resourceURL}auth-qr/${idUnidade}`, {
            responseType: 'blob',
        })
    }
 

    const sendMessageWhatsapp = (data: ISendTextDTO, idLead: number): Promise<AxiosResponse<ISendTextDTO>> => {
        return http.post(`${resourceURL}sendText`, {
            chatId: data.chatId,
            text: data.text,
            session: data.session,
            leadId: idLead
        })
    }

    const logoutSession = (name: string): Promise<AxiosResponse<ILogoutDTO>> => {
        return http.post(`${resourceURL}session/logout`, {
            name
        })
    }

    return {
        getWhatsappSession,
        getWhatsappSessionStart,
        getQrCodeWhatsapp,
        sendMessageWhatsapp,
        logoutSession
    }
}