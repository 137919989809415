import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import * as Unicons from '@iconscout/react-unicons';
import { Button } from "antd";
import { useState } from "react";
import { Lead } from "../../components/Lead";
import { ModalLeadNew } from "../../components/Modal/leadnew";
import { useLeadContext } from "../../context/lead.context.service";
import { ICrmeLeadEstagioDTO } from "../../models/happy-code-api.model";
import { PageableResponse } from "../../models/response.model";
import { useLeadService } from "../../services/lead.service";
import { getStatusLead } from "../../util/lead.util";
export const Funil = () => {

  //const [data, setData] = useState(ColumnsData);
  const { state, setState, refreshLeadsEstagioEspecifico } = useLeadContext();
  const [isOpenLead, setIsOpenLead] = useState(false);
  const leadService = useLeadService();

  // const [items, setItems] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [page, setPage] = useState(1);

  //const [styles, setStyles] = useState<any>();
  const onChangeModalLead = () => {
    if (isOpenLead === true) {
      setIsOpenLead(false)
    } else {
      setIsOpenLead(true)
    }
  }

  const onCloseLead = () => {
    setIsOpenLead(false)
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;


    if (source.droppableId !== destination.droppableId) {
      const sourceColIndex = state?.boards.findIndex(e => e.id == source.droppableId)
      const destinationColIndex = state?.boards.findIndex(e => e.id == destination.droppableId)
      const sourceCol = state?.boards[sourceColIndex];


      const destinationCol = state?.boards[destinationColIndex];
      const sourceTask = [...sourceCol.leads];
      const destinationTask = [...destinationCol.leads];

      const [removed] = sourceTask.splice(source.index, 1);
      destinationTask.splice(destination.index, 0, removed);

      state.boards[sourceColIndex].leads = sourceTask;
      state.boards[destinationColIndex].leads = destinationTask;
      setState(state);

      leadService.alterarStatus(result.draggableId as any, state.boards[destinationColIndex].cdLeadEstagio);

    }
  }

  const handleScroll = (e, estagio: PageableResponse<ICrmeLeadEstagioDTO>) => {
		// const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		const bottom =
			e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
		if (bottom) {
			if (estagio.last) return;
			refreshLeadsEstagioEspecifico(estagio);
		}
	};

  return (
		<div className="area-tasks flex flex-row justify-between align-start">
			<DragDropContext onDragEnd={onDragEnd}>
				{state?.boards?.map((section: any) => (
					<Droppable
						key={section.id.toString()}
						droppableId={section.id.toString()}
					>
						{(provided) => (
							<div className="area-column">
								<div className="header flex flex-row justify-between align-center">
									<h3>{section.dsLeadEstagio}</h3>
									<h3>{section.leads.length}</h3>
								</div>
								<div
									className="body"
									onScroll={(e) => handleScroll(e, section as any)}

									// onMouseOver={() => { setStyles((styles) => ({ ...styles, [section.cdLeadEstagio]: { overflow: 'scroll', transition: '0.2s' } })) }}
									// onMouseOut={() => { setStyles((styles) => ({ ...styles, [section.cdLeadEstagio]: { overflow: 'hidden', transition: '0.2s' } })) }}
									// style={styles?.[section?.cdLeadEstagio]}
								>
									<ul
										className=""
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{section.leads.length !== 0 ? (
											<>
												{section.leads.map((task, index) => (
													<Draggable
														key={task.id}
														draggableId={task.id.toString()}
														index={index}
													>
														{(provided, snapshot) => {
															const status = getStatusLead(task?.proximaTarefa);

															return (
																<Lead
																	provided={provided}
																	id={task.id}
																	dataCriacao={task.dataCriacao as any}
																	cliente={task.dsNomeLead}
																	status={status}
																	campanha={
																		task?.campanha?.dsLeadCampanha || '--'
																	}
																	dataInicio={task?.dataCriacao as any}
																	dataAtualizacao={task?.dataAtualizacao as any}
																	note={task.anotacoes?.length > 0}
																	proximaTarefa={task.proximaTarefa}
																	qualify={task.nrAvaliacaoLead}
																	dsUltimaAnotacao={task.dsUltimaAnotacao}
																	nrInteracao={task.nrInteracao}
																	dsAreaAtuacao={task?.dsAreaAtuacao}
																	dsModelo={task?.dsModelo}
																	// dhTarefa={task?.tarefas?.dhTarefa}
																/>
															);
														}}
													</Draggable>
												))}
												{provided.placeholder}
											</>
										) : (
											<>
												<div className="title-default">
													<h4>Sem resultados</h4>
													<p>Não há leads para mostrar ainda</p>
												</div>
												<Button
													onClick={onChangeModalLead}
													style={{ width: '100%' }}
													className="button-blue"
												>
													<Unicons.UilUserSquare
														color="var(--azul-happy)"
														size={16}
													/>
													<span className="ml-5">Novo Lead</span>
												</Button>
												<ModalLeadNew open={isOpenLead} onClose={onCloseLead} />
											</>
										)}
									</ul>
								</div>
							</div>
						)}
					</Droppable>
				))}
			</DragDropContext>
		</div>
	);
}
