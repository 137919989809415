import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Button, Form, InputNumber, Radio, Select, Space } from "antd";
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { IAreaAtuacaoCleanDTO, ICrmeLeadCampanhaCleanDTO, ICrmeLeadOrigemDTO, IModalidadeNegocioCleanDTO, IMunicipioCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAreaAtuacaoService } from '../../services/area-atuacao';
import { useLeadService } from '../../services/lead.service';
import { useModalidadeNegocioService } from '../../services/modalidade-negocio';
import { useMunicipioService } from '../../services/municipio';
import { useNotificationService } from '../../services/notification.service';
import { useVwLeadService } from '../../services/vwlead.service';
import { currencyFormatter, currencyParser } from '../../util/format';

export const ModeloNegocio = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const { leadSelecionado, setLeadSelecionado, state } = useLeadContext();
    const notification = useNotificationService();
    const leadService = useLeadService();
    const vwLeadService = useVwLeadService();
    const municipioService = useMunicipioService();
    const modalidadeNegocioService = useModalidadeNegocioService();
    const areaAtuacaoService = useAreaAtuacaoService();

    const [openModalAlterarModeloNegocio, setOpenModalAlterarModeloNegocio] = useState(false);
    const [modalidadeNegocioList, setModalidadeNegocioList] = useState<IModalidadeNegocioCleanDTO[]>();
    const [areaAtuacaoList, setAreaAtuacaoList] = useState<IAreaAtuacaoCleanDTO[]>();
    const [municipioList, setMunicipioList] = useState<IMunicipioCleanDTO[]>()
    const [form] = Form.useForm();


    const onAdicionarInteresse = () => {
        const body = { ...form.getFieldsValue(), endereco: undefined };

        setIsGlobalLoading(true);
        return leadService.patch(leadSelecionado?.id as any, body).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return vwLeadService.findCustomIndexById({ id: leadSelecionado.id as any }).then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        setOpenModalAlterarModeloNegocio(false);
                        setLeadSelecionado(response?.data);
                        notification({ description: 'Dados do modelo de negócio atualizados com sucesso!', type: 'success' })
                        return;
                    }

                    throw new Error();
                })
            }

            throw new Error();
        }).catch((e) => {
            notification({ description: 'Não foi possível alterar os dados do modelo de negócio.', type: 'error' })
        }).finally(() => {
            setIsGlobalLoading(false);
        });
    }

    const findModalidadeNegocio = () => {
        const predicate = new Predicate();
        modalidadeNegocioService.findClean(predicate)
            .then(({ data }) => {
                setModalidadeNegocioList(data.content);
            })
    }

    const findAreaAtuacao = () => {
        const predicate = new Predicate();
        areaAtuacaoService.findClean(predicate)
            .then(({ data }) => {
                setAreaAtuacaoList(data.content);
            })
    }

    const findMunicipio = () => {
        const predicate = new Predicate();
        municipioService.findCleanList(predicate)
            .then(({ data }) => {
                setMunicipioList(data);
            })
    }

    useEffect(() => {
        findModalidadeNegocio();
        findAreaAtuacao();
        findMunicipio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (leadSelecionado) {
            form.setFieldsValue({
                ...leadSelecionado,
            });
            // setIsGlobalLoading(true);
            // fetchEndereco().finally(() => setIsGlobalLoading(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadSelecionado]);

    return (
        <>
            <Form
                style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
                name="basic"
                // initialValues={{ remember: true }}
                form={form}
            >
                <div className="tarefa-panel" id="dados-lead">
                    <div className="flex justify-between flex-column gap-20 mb-30">
                        <div className="flex align-center gap-10">
                            <ReceiptLongIcon />
                            <h1 id="sem-margin">Dados da proposta</h1>
                        </div>

                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                <label htmlFor="campanha">Campanha</label>
                                <div className="box-white">
                                    <Form.Item
                                        name={['campanha', 'id']}
                                        fieldKey={['campanha', 'nome']}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'Selecione a campanha' }]}
                                    >
                                        <Select
                                            id='campanha'
                                            style={{ width: '100%' }}
                                            bordered={false}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                            }>
                                            {state.campanhas?.map?.((campanha: ICrmeLeadCampanhaCleanDTO) => (
                                                <Select.Option key={campanha.id} value={campanha.id} text={campanha.dsLeadCampanha}>{campanha.dsLeadCampanha}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                <label htmlFor="origem">Origem</label>
                                <div className="box-white">
                                    <Form.Item
                                        name={['origem', 'id']}
                                        fieldKey={['origem', 'nome']}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'Selecione a origem do lead' }]}
                                    >
                                        <Select
                                            id="origem"
                                            style={{ width: '100%' }}
                                            bordered={false}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                            }>
                                            {state.leadOrigens?.map?.((origem: ICrmeLeadOrigemDTO) => (
                                                <Select.Option key={origem.id} value={origem.id} text={origem.dsLeadOrigem}>{origem.dsLeadOrigem}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                <label htmlFor="vlCapitalInvestimento">Capital para investimento</label>
                                <div className="box-white">
                                    <Form.Item name={'vlCapitalInvestimento'} rules={[{ required: false, message: 'Informe capital para investimento' }]}>
                                        <InputNumber
                                            id="vlCapitalInvestimento"
                                            className="input"
                                            style={{ width: "100%" }}
                                            bordered={false}
                                            min={0}
                                            formatter={currencyFormatter}
                                            parser={currencyParser} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                <label htmlFor="nmCidadeInteresse">Cidade de interesse</label>
                                <div className="box-white">
                                    <Form.Item
                                        name={'nmCidadeInteresse'}
                                        fieldKey={'nmCidadeInteresse'}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'Selecione a cidade de interesse' }]}
                                    >
                                        <Select
                                            id="nmCidadeInteresse"
                                            style={{ width: '100%' }}
                                            bordered={false}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                            }>
                                            {municipioList && municipioList.map((municipio) => (
                                                <Select.Option key={municipio.id} value={municipio.nome} text={municipio.nome}>{municipio.nome}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div className="flex flex-row gap-10">
                            <div className="flex flex-row gap-10 w-50">
                                <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                    <label htmlFor="dsModeloNegocio">Modelo de negócio</label>
                                    <div className="box-white" style={{ height: "auto" }}>
                                        <Form.Item
                                            className="mb-0"
                                            name={'dsModeloNegocio'}
                                            rules={[{ required: false, message: 'Selecione um modelo de negócio' }]}>
                                            <Radio.Group id="dsModeloNegocio">
                                                <Space direction="vertical">
                                                    <p>Lendário Compartilhado</p>
                                                    <Radio value="Taxa de Franquia R$ 29 mil + R$ 9.9 mil na abertura">Taxa de Franquia R$ 29 mil + R$ 9.9 mil na abertura</Radio>
                                                    <p>Lendário Exclusivo</p>
                                                    <Radio value="Taxa de Franquia R$ 29 mil">Taxa de Franquia R$ 29 mil</Radio>
                                                    <p>Millennium Satélite e Planeta</p>
                                                    <Radio value="Taxa de Franquia R$ 59 mil">Taxa de Franquia R$ 59 mil</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row align-center gap-10 w-50">
                                <div className="w-100">
                                    <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1, marginBottom: 24 }}>
                                        <label htmlFor="dsModelo">Modelo</label>
                                        <div className="box-white">
                                            <Form.Item
                                                name={'dsModelo'}
                                                fieldKey={'dsModelo'}
                                                style={{ width: '100%', marginBottom: 24 }}
                                                rules={[{ required: false, message: 'Selecione a origem do lead' }]}
                                            >
                                                <Select
                                                    id="dsModelo"
                                                    style={{ width: '100%' }}
                                                    bordered={false}
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                                    }>
                                                    {modalidadeNegocioList && modalidadeNegocioList.map((modalidadeNegocio) => (
                                                        <Select.Option key={modalidadeNegocio.id} value={modalidadeNegocio.descricao} text={modalidadeNegocio.descricao}>{modalidadeNegocio.descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                                        <label htmlFor="dsAreaAtuacao">Área de atuação</label>
                                        <div className="box-white">
                                            <Form.Item
                                                name={'dsAreaAtuacao'}
                                                fieldKey={'dsAreaAtuacao'}
                                                style={{ width: '100%' }}
                                                rules={[{ required: false, message: 'Selecione a área de atuação' }]}
                                            >
                                                <Select
                                                    id="dsAreaAtuacao"
                                                    style={{ width: '100%' }}
                                                    bordered={false}
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                                    }>
                                                    {areaAtuacaoList && areaAtuacaoList.map((areaAtuacao) => (
                                                        <Select.Option key={areaAtuacao.id} value={areaAtuacao.descricao} text={areaAtuacao.descricao}>{areaAtuacao.descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill">
                                <label htmlFor="dsNegociacaoAcordada">Negociação acordada</label>
                                <div className="box-white" style={{ height: "auto" }}>
                                    <Form.Item
                                        className="mb-0"
                                        name={'dsNegociacaoAcordada'}
                                        fieldKey={'dsNegociacaoAcordada'}
                                        style={{ width: '100%' }}
                                        rules={[{ required: false, message: 'Insira a negociação acordada' }]}
                                    >
                                        <TextArea
                                            rows={3}
                                            id="dsNegociacaoAcordada"
                                            bordered={false}
                                            maxLength={2000}
                                        />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>

                        <div className="flex align-center gap-10 flex flex-fill" style={{ flex: 1 }}>
                            <label htmlFor="vlTamanhoDeal">Tamanho do Deal</label>
                            <div className="box-white">
                                <Form.Item name={'vlTamanhoDeal'} rules={[{ required: false, message: 'Informe tamanho do deal' }]}>
                                    <InputNumber
                                        id="vlTamanhoDeal"
                                        className="input"
                                        style={{ width: "100%" }}
                                        bordered={false}
                                        min={0}
                                        formatter={currencyFormatter}
                                        parser={currencyParser} />
                                </Form.Item>
                            </div>
                        </div>
                        
                        <div className="flex flex-row gap-10">
                            <div className="flex align-center gap-10 flex flex-fill">
                                <label htmlFor="dsResumo">Resumo</label>
                                <div className="box-white h-100">
                                    <Form.Item name={'dsResumo'} style={{ width: '100%', marginBottom: 0 }} rules={[{ required: false, message: 'Insira o resumo' }]}>
                                        <TextArea
                                            rows={3}
                                            id="dsResumo"
                                            bordered={false}
                                            maxLength={2000}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row justify-end w-100 mt-30">
                        <Button onClick={() => setOpenModalAlterarModeloNegocio(true)} className="button-primary">
                            Salvar proposta
                        </Button>
                    </div>
                </div>

                <ModalResposta
                    open={openModalAlterarModeloNegocio}
                    onClose={onAdicionarInteresse}
                    onCancel={() => setOpenModalAlterarModeloNegocio(false)}
                    status="Deseja cadastrar dados da proposta?"
                    text="Confirme os dados antes de cadastrar"
                    link=""

                />
            </Form>
        </>
    );
}
