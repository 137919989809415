import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { Button, Input, Select } from "antd";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmeLeadDTO } from '../../models/happy-code-api.model';
import { useLeadService } from "../../services/lead.service";
import { useNotificationService } from "../../services/notification.service";
import { useVwLeadService } from '../../services/vwlead.service';
import { getStatusLead } from '../../util/lead.util';
import { statusWord } from "../Lead";

interface Props {
    leadId: string | undefined;
}

export const HeaderLead = ({ leadId }: Props) => {

    const navigate = useNavigate();

    const leadService = useLeadService();
    const vwLeadService = useVwLeadService();
    const notification = useNotificationService();

    const { setLeadSelecionado, leadSelecionado } = useLeadContext();
    const { setIsGlobalLoading } = useGlobalContext();

    const [openModalMarcarPerda, setOpenModalMarcarPerda] = useState<boolean>();
    const [dsMotivoPerda, setDsMotivoPerda] = useState<string>();
    const [dsObservacoesPerda, setDsObservacoesPerda] = useState<string>();

    useEffect(() => {
        if (openModalMarcarPerda !== null && openModalMarcarPerda !== undefined && openModalMarcarPerda === false) {
            setDsObservacoesPerda(null);
            setDsMotivoPerda(null);
        }
    }, [openModalMarcarPerda]);

    const onAlterarStatusLead = (cdEstagio: string, dsMensagemSucesso: string) => {


        const isPerda = cdEstagio === 'DES';

        if (isPerda && (!dsMotivoPerda || dsMotivoPerda?.length <= 0 || dsMotivoPerda === 'selecione')) {
            notification({ description: 'Selecione o motivo da perda', type: 'error' });
            return;
        }
        setIsGlobalLoading(true);

        leadService.alterarStatus(leadId as any, cdEstagio).then((response) => {
            if (response.status >= 200 && response.status <= 299) {

                const body: ICrmeLeadDTO = {
                    id: leadId as any,
                    dsMotivoPerda: dsMotivoPerda,
                    dsObservacaoPerda: dsObservacoesPerda,
                    campanha: leadSelecionado?.campanha,
                } as ICrmeLeadDTO;

                return leadService.patch(leadId as any, body).then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        setOpenModalMarcarPerda(false);
                        return vwLeadService.findCustomIndexById({ id: leadId as any })
                            .then((response) => {
                                setLeadSelecionado({ ...response?.data });
                                notification({ description: dsMensagemSucesso, type: 'success' })
                            });
                    }

                    throw new Error();

                });
            }
            throw new Error();
        }).catch((e) => {
            notification({ description: 'Não foi possível transferir o Lead.', type: 'error' })
        }).finally(() => setIsGlobalLoading(false));
    }

    const onSelecionarMotivoPerdaLead = (e) => {
        setDsMotivoPerda(e);
    }

    const status = getStatusLead(leadSelecionado?.proximaTarefa);
    const statusFormatada = statusWord(status);

    return (
        <>
            <div className="header-lead flex justify-center" style={{ height: 150 }}>
                <div className="flex flex-row justify-start align-center w-50 gap-20">
                    <ArrowBackIcon onClick={() => navigate(-1)} className="icone-back" />
                    <div className={`box-tag-${status}`}>
                        <p>{statusFormatada}</p>
                    </div>
                    <h5>{leadSelecionado?.dsNomeLead}</h5>
                </div>
                <div className="flex flex-row w-50 align-center justify-end">
                    <Button onClick={() => onAlterarStatusLead('CTF', 'Lead transferido para etapa contrato fechado')} className="button-verde gap-5" icon={<ThumbUpIcon style={{ fontSize: 16 }} />}>
                        Marcar Venda
                    </Button>
                    <Button onClick={() => setOpenModalMarcarPerda(true)} className="button-red ml-10 gap-5" icon={<ThumbDownIcon style={{ fontSize: 16 }} />}>
                        Marcar Perda
                    </Button>
                    {/* <Button className="button-primary ml-10">Salvar Lead</Button> */}
                </div>
            </div>
            <Modal
                open={openModalMarcarPerda}
                onClose={() => setOpenModalMarcarPerda(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <Fade in={openModalMarcarPerda}>
                    <div className="modal-note" style={{ width: 650, maxWidth: 650 }}>
                        <div className="container-modal">
                            <ClearIcon className="button-close" onClick={() => setOpenModalMarcarPerda(false)} />
                            <h3>Registrar perda do Lead</h3>

                            <div className="select-box mr-5 flex row" style={{ alignItems: 'center', width: '87%' }}>
                                <div className="text-over-input" style={{ marginBottom: 15, paddingRight: 10, flex: 1, minWidth: '25%' }}>
                                    <small>Motivo da perda</small>
                                </div>
                                <div className="input-modal mb-10" style={{ minWidth: '89%' }}>
                                    <Select
                                        dropdownStyle={{ zIndex: 2000 }}
                                        style={{ width: '100%', }}
                                        bordered={false}
                                        defaultValue="selecione"
                                        onChange={onSelecionarMotivoPerdaLead}
                                        value={dsMotivoPerda}
                                    >
                                        <Select.Option value="selecione">Selecione</Select.Option>
                                        <Select.Option value="Sem retorno">Sem retorno</Select.Option>
                                        <Select.Option value="Número inválido/não existe">Número inválido/não existe</Select.Option>
                                        <Select.Option value="Duplicado">Duplicado</Select.Option>
                                        <Select.Option value="Fechou com outra empresa">Fechou com outra empresa</Select.Option>
                                        <Select.Option value="Indisponibilidade financeira">Indisponibilidade financeira</Select.Option>
                                        <Select.Option value="Interesse em ouro modelo de negócio">Interesse em ouro modelo de negócio</Select.Option>
                                        <Select.Option value="Stand by">Stand by</Select.Option>
                                        <Select.Option value="Praça indisponível">Praça indisponível</Select.Option>
                                        <Select.Option value="Praça sem potencial">Praça sem potencial</Select.Option>
                                    </Select>
                                </div>
                            </div>

                            <div className="select-box mr-5" style={{ alignItems: 'center', width: '100%' }}>
                                <div className="text-over-input" style={{ marginBottom: 15, paddingRight: 10 }}>
                                    <small>Observações (opcional)</small>
                                </div>
                                <Input.TextArea
                                    value={dsObservacoesPerda}
                                    name="" id=""
                                    rows={5}
                                    onChange={(e) => setDsObservacoesPerda(e.target.value)}
                                    placeholder='Digite as observações aqui...'
                                />

                                <p>{dsObservacoesPerda?.length || 0}/500</p>
                            </div>

                            <div className="flex row" style={{ justifyContent: 'flex-end' }}>
                                <Button className="button-blue" onClick={() => setOpenModalMarcarPerda(false)} >Cancelar</Button>
                                <Button className="button-primary" onClick={() => onAlterarStatusLead('DES', 'Lead transferido para etapa descartado')}>Registrar</Button>
                            </div>

                            {/* <Input.TextArea
                value={dsTextoAnotacao}
                name=""
                id=""
                rows={10}
                placeholder="Descreva sua anotação"
                onChange={(e) => setDsTextoAnotacao(e.target.value as any)} />
              <p>{dsTextoAnotacao?.length || 0}/500</p>
              <div className="flex flex-row justify-between aling-center gap-10">
                <Button className="button-primary" onClick={handleOpenModalTarefa}>Ver Tarefas</Button>
                <Button className="button-blue" onClick={handleCloseModalNewNote}>Salvar</Button>
              </div> */}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
